<template>
  <div>
    <div v-if="reporte!=null">
      <card>
        <h1 v-if="fecha_inicio!=null && fecha_fin!=null">
          <router-link
            :to="{ name: 'planta', params: { id: planta.id }}"
          >
            {{planta.nombre}}
          </router-link>
          -
          Reporte de: {{fecha_inicio.format('MMMM')}}
            ({{fecha_inicio.format('DD')}} a {{fecha_fin.format('DD')}})
        </h1>

        <div class="reporte-menu">
          <button class="btn" @click="setCurrentView('visualizador')">
            Visualizador
          </button>
          <button class="btn" @click="setCurrentView('resumen')">
            Resumen Mensual
          </button>
          <button class="btn" @click="setCurrentView('datos')">
            Datos
          </button>
          <button class="btn" @click="setCurrentView('disponibilidad')">
            Disponibilidad
          </button>
          <button class="btn" @click="setCurrentView('pr')">
            PR
          </button>
          <button class="btn" @click="setCurrentView('historico')">
            Histórico
          </button>
        </div>

        <div class="row mt-4">
          <div class="col-md-12">
            <base-input label="Seleccionar Día">
              <select 
                class="form-control" 
                id="selectDia"
                @change="seleccionarDiaGeneracion" 
                v-model="selectedDia"
              >
                <option v-for="dia in listadoDias" :key="dia" :value="dia">
                  {{dia}}
                </option>
              </select>
            </base-input>
          </div>
        </div>
      </card>

      <template v-if="current_view === 'visualizador'">
        <card class="text-center">
          <h2>Valores Esperados (en {{n_dias}} días)</h2>
          <div class="row">
            <div class="col-4 border rounded">
              <h3>Meses de Operación</h3>
              <h4>
                <b>
                  {{mesesOperacion | numeral('0,0')}}
                  [meses]
                </b>
              </h4>
            </div>
            <div class="col-2 border rounded">
              <h3>Degradación</h3>
              <h4>
                <b>
                  {{factorDegradacion*100 | numeral('0,0.00')}}
                  [%]
                </b>
              </h4>
            </div>
            <div class="col-2 border rounded">
              <h3>Generación</h3>
              <h4>
                <b>
                  {{pvsol_esperado_ndias.enerfv | numeral('0,0.00')}}
                  [kWh]
                </b>
              </h4>
            </div>
            <div class="col-2 border rounded">
              <h3>Irradiación</h3>
              <h4>
                <b>
                  {{pvsol_esperado_ndias.globinc | numeral('0,0.00')}}
                  [kWh/m²]
                </b>
              </h4>
            </div>
            <div class="col-2 border rounded">
              <h3>PR</h3>
              <h4>
                <b>
                  {{pvsol_esperado_ndias.pr | numeral('0,0.00')}}
                  %
                </b>
              </h4>
            </div>
          </div>
        </card>

        <card v-if="tiene_smart_meter" class="text-center">
          <h2>Valores de Autoconsumo</h2>
          <div class="row">
            <div
              class="col-6 border rounded" 
              :class="{'autoconsumo-deshabilitado': usar_boleta_autoconsumo}"
            >
              <h3>Inyección</h3>
              <h4>
                <b>
                  {{total_inyeccion | numeral('0,0.00')}}
                  [kWh]
                </b>
              </h4>
            </div>
            <div
              class="col-6 border rounded" 
              :class="{'autoconsumo-deshabilitado': usar_boleta_autoconsumo}"
            >
              <h3>Consumo Directo</h3>
              <h4>
                <b>
                  {{total_autoconsumo | numeral('0,0.00')}}
                  [kWh]
                </b>
              </h4>
            </div>
          </div>
          <h2>Valores de boleta</h2>
          <div class="row">
            <div class="col-4 border-rounded">
              <h3>Utilizar valores de boleta?</h3>
              <input
                type="checkbox"
                name="usar_boleta_autoconsumo"
                v-model="usar_boleta_autoconsumo"
              >
            </div>
            <div
              class="col-4 border-rounded"
              :class="{'autoconsumo-deshabilitado': !usar_boleta_autoconsumo}"
            >
              <h3>Inyección</h3>
              <base-input 
                label="Ingresar Inyección [kWh]"
                placeholder="Ingresar Inyección [kWh]"
                @change="calcularAutoconsumoBoleta" 
                v-model="boleta_inyeccion"
                type="number"
                :disabled="!usar_boleta_autoconsumo"
              >
            </base-input>
            </div>
            <div 
              class="col-4 border-rounded"
              :class="{'autoconsumo-deshabilitado': !usar_boleta_autoconsumo}"
            >
              <h3>Consumo Directo</h3>
              <h4>
                <b>
                  {{boleta_autoconsumo | numeral('0,0.00')}}
                  [kWh]
                </b>
              </h4>
            </div>
          </div>
        </card>


        <card>
          <h2>Valores Obtenidos</h2>
          <div class="row tablas-dobles">
            <div>
              <h3>Disponibilidad</h3>

              <div class="tablaGrid tablaGrid-full">  
                <v-grid
                  theme="compact"
                  :source="tablaTiempoDisponibilidad"
                  :columns="columnasTiempoDisponibilidad"
                  :columnTypes="columnTypes"
                  :pinnedBottomSource="tablaTiempoDisponibilidadResumen"
                  range="false"
                  row-size="25"
                  class="revo-full"
                >
                </v-grid>
              </div>

              <h3>Disponibilidad Real (MIT 10)</h3>

              <div class="tablaGrid tablaGrid-full">  
                <v-grid
                  theme="compact"
                  :source="tablaTiempoDisponibilidadReal"
                  :columns="columnasTiempoDisponibilidadReal"
                  :columnTypes="columnTypes"
                  :pinnedBottomSource="tablaTiempoDisponibilidadRealResumen"
                  range="false"
                  row-size="25"
                  class="revo-full"
                >
                </v-grid>
              </div>
            </div>
            
            <div>
              <h2>PR (Sin Filtrar)</h2>
              <div class="tablaGrid tablaGrid-full">
                <v-grid
                  theme="compact"
                  :source="tablaGeneralSinFiltrar"
                  :columns="columnasGeneralSinFiltrar"
                  :columnTypes="columnTypes"
                  :pinnedBottomSource="tablaGeneralSinFiltrarResumen"
                  range="false"
                  row-size="25"
                  class="revo-full"
                >
                </v-grid>
              </div>
            </div>

            <div>
              <h2>PR (Filtrado)</h2>
              <div class="tablaGrid tablaGrid-full">
                <v-grid
                  theme="compact"
                  :source="tablaExclusionesFiltrados"
                  :columns="columnasExclusionesFiltrados"
                  :columnTypes="columnTypes"
                  :pinnedBottomSource="tablaExclusionesFiltradosResumen"
                  range="false"
                  row-size="25"
                  class="revo-full"
                >
                </v-grid>
              </div>
            </div>
          </div>
        </card>

        <h2>Visualizador Diario</h2>

        <card>
          <base-input label="Seleccionar Día">
            <select 
              class="form-control" 
              id="selectVisualizador"
              @change="seleccionarDiaVisualizador()" 
              v-model="selectedVisualizador"
            >
              <option v-for="dia in listadoDias" :key="dia" :value="dia">
                {{dia}}
              </option>
            </select>
          </base-input>
        </card>

        <card>
          <h2>Datos Originales</h2>
          <div v-if="visualizadorNoFiltradoData" class="chart-area">
            <line-chart 
              style="height: 100%"
              chart-id="no-filtrado-line-chart"
              :chart-data="visualizadorNoFiltradoData"
              :extra-options="visualizadorNoFiltradoDataOptions"
            >
            </line-chart>
            <div class="movement-chart" v-show="selectedVisualizador!=0">
              <div>
                <button v-show="selectedVisualizador>listadoDias[0]" class="btn" @click="reducirDia">
                  <i class="fas fa-arrow-left"></i>
                </button>
              </div>
              <div class="movement-text">
                {{selectedVisualizador}}
              </div>
              <div>
                <button v-show="selectedVisualizador<listadoDias.at(-1)" class="btn" @click="aumentarDia">
                  <i class="fas fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </card>

        <card>
          <h2>Datos Filtrados</h2>
          <div v-if="visualizadorFiltradoData" class="chart-area">
            <line-chart 
              style="height: 100%"
              chart-id="filtrado-line-chart"
              :chart-data="visualizadorFiltradoData"
              :extra-options="visualizadorFiltradoDataOptions"
            >
            </line-chart>
          </div>
          <div class="movement-chart" v-show="selectedVisualizador!=0">
            <div>
              <button v-show="selectedVisualizador>listadoDias[0]" class="btn" @click="reducirDia">
                <i class="fas fa-arrow-left"></i>
              </button>
            </div>
            <div class="movement-text">
              {{selectedVisualizador}}
            </div>
            <div>
              <button v-show="selectedVisualizador<listadoDias.at(-1)" class="btn" @click="aumentarDia">
                <i class="fas fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </card>

        <card>
          <h2>Datos Por Inversor</h2>

          <div class="row">
            <div v-for="inversor in inversores_checkbox" :key="inversor.name" class="col-md-2">
              <div class="form-check">
                <label :for="inversor.name" class="form-check-label">
                  <input 
                    :id="inversor.name" 
                    type="checkbox" 
                    class="form-check-input" 
                    :value="inversor.name" 
                    v-model="selectedInversores"
                    @change="actualizarGraficosPorInversor"
                  >
                  <span class="form-check-sign"></span>
                  {{inversor.name}}
                </label>
              </div>
            </div>
          </div>

          <h2 class="mt-4">Originales</h2>

          <div v-if="visualizadorInversoresNoFiltradoData" class="chart-area">
            <custom-line-chart 
              style="height: 100%"
              chart-id="no-filtrado-inversores-line-chart"
              :chart-data="visualizadorInversoresNoFiltradoData"
              :extra-options="visualizadorInversoresNoFiltradoDataOptions"
            >
            </custom-line-chart>
            <div class="movement-chart" v-show="selectedVisualizador!=0">
              <div>
                <button v-show="selectedVisualizador>listadoDias[0]" class="btn" @click="reducirDia">
                  <i class="fas fa-arrow-left"></i>
                </button>
              </div>
              <div class="movement-text">
                {{selectedVisualizador}}
              </div>
              <div>
                <button v-show="selectedVisualizador<listadoDias.at(-1)" class="btn" @click="aumentarDia">
                  <i class="fas fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>

          <h2>Filtrados</h2>

          <div v-if="visualizadorInversoresFiltradoData" class="chart-area">
            <custom-line-chart 
              style="height: 100%"
              chart-id="filtrado-inversores-line-chart"
              :chart-data="visualizadorInversoresFiltradoData"
              :extra-options="visualizadorInversoresFiltradoDataOptions"
            >
            </custom-line-chart>
          </div>
          <div class="movement-chart" v-show="selectedVisualizador!=0">
            <div>
              <button v-show="selectedVisualizador>listadoDias[0]" class="btn" @click="reducirDia">
                <i class="fas fa-arrow-left"></i>
              </button>
            </div>
            <div class="movement-text">
              {{selectedVisualizador}}
            </div>
            <div>
              <button v-show="selectedVisualizador<listadoDias.at(-1)" class="btn" @click="aumentarDia">
                <i class="fas fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </card>
      </template>

      <template v-if="current_view === 'resumen'">
        <card>
          <div v-if="cambios_disponibilidad.length!=0 || cambios_pr.length!=0" class="row mt-4 text-center">
            <div class="col-md-6">
              <button class="btn btn-danger" @click="guardarReporte(true)">
                Guardar Reporte y Cambios
              </button>
            </div>
            <div class="col-md-6">
              <button class="btn btn-success" @click="guardarCambios">
                Guardar Cambios
              </button>
            </div>
          </div>
          <div v-else class="row mt-4 text-center">
            <div class="col-md-6">
              <button class="btn btn-danger" @click="guardarReporte">
                Guardar Reporte
              </button>
            </div>
          </div>
        </card>
        <card class="reporte-sub-card">
          <h2>1. Resumen del Mes</h2>

          <div class="row">
            <table class="table tablesorter table-hover">
              <tbody>
                <tr>
                  <td>Rendimiento específico:</td>
                  <td>{{rendimiento_especifico | numeral('0,0.00')}}</td>
                  <td>[kWh/ kWp]</td>
                </tr>
                <tr>
                  <td>Generación total:</td>
                  <td>{{total_generacion | numeral('0,0.00')}}</td>
                  <td>[kWh]</td>
                </tr>
                <tr>
                  <td>Generación simulada:</td>
                  <td>{{generacion_simulada | numeral('0,0.00')}}</td>
                  <td>[kWh]</td>
                </tr>
                <tr>
                  <td>Dif. Generación:</td>
                  <td>{{generacion_diferencia | numeral('0,0.00')}}</td>
                  <td>[%]</td>
                </tr>
                <tr>
                  <td>Irradiación total:</td>
                  <td>{{irradiacion_total | numeral('0,0.00')}}</td>
                  <td>[kWh/ m²]</td>
                </tr>
                <tr>
                  <td>Irradiación simulada:</td>
                  <td>{{irradiacion_simulada | numeral('0,0.00')}}</td>
                  <td>[kWh/ m²]</td>
                </tr>
                <tr>
                  <td>Dif. Irradiación:</td>
                  <td>{{irradiacion_diferencia | numeral('0,0.00')}}</td>
                  <td>[%]</td>
                </tr>
                <tr>
                  <td>Performance Ratio Real:</td>
                  <td>{{performance_ratio_medido | numeral('0,0.00')}}</td>
                  <td>[%]</td>
                </tr>
                <tr>
                  <td>Performance Ratio O&M:</td>
                  <td>{{performance_ratio | numeral('0,0.00')}}</td>
                  <td>[%]</td>
                </tr>
                <tr>
                  <td>Performance Ratio Simulado:</td>
                  <td>{{performance_ratio_simulado | numeral('0,0.00')}}</td>
                  <td>[%]</td>
                </tr>
                <tr>
                  <td>Dif. Performance Ratio O&M:</td>
                  <td>{{performance_ratio_diferencia | numeral('0,0.00')}}</td>
                  <td>[%]</td>
                </tr>
                <tr>
                  <td>Disponibilidad Real:</td>
                  <td>{{disponibilidad_real | numeral('0,0.00')}}</td>
                  <td>[%]</td>
                </tr>
                <tr>
                  <td>Disponibilidad O&M:</td>
                  <td>{{disponibilidad_om | numeral('0,0.00')}}</td>
                  <td>[%]</td>
                </tr>
                <tr>
                  <td>Disponibilidad Garantizada:</td>
                  <td>{{disponibilidad_garantizada | numeral('0,0.00')}}</td>
                  <td>[%]</td>
                </tr>
                <tr>
                  <td>Dif. Disponibilidad O&M:</td>
                  <td>{{disponibilidad_diferencia | numeral('0,0.00')}}</td>
                  <td>[%]</td>
                </tr>
                <tr>
                  <td>Cantidad evitada de CO<span class="sub-number">2</span></td>
                  <td>{{co2 | numeral('0,0.00')}}</td>
                  <td>[ton]</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="generacionChart.chartData" class="chart-area">
            <bar-chart 
              :style="grafico_style"
              chart-id="gen-pr-chart"
              ref="gen-pr-chart"
              :chart-data="generacionChart.chartData"
              :extra-options="generacionChart.extraOptions"
            >
            </bar-chart>

            <bar-chart 
              :style="grafico_style"
              chart-id="gen-pr-chart-en"
              ref="gen-pr-chart-en"
              :chart-data="generacionChartEn.chartData"
              :extra-options="generacionChartEn.extraOptions"
            >
            </bar-chart>
          </div>

          <div class="row">
            <table class="table tablesorter table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th>Fecha</th>
                  <th>Generación [kWh]</th>
                </tr>
              </thead>
              <tbody v-if="mayor_fecha">
                <tr>
                  <td>Día de mayor producción:</td>
                  <td>{{mayor_fecha.format('D - MMMM')}}</td>
                  <td>{{mayor_valor | numeral('0,0.00')}}</td>
                </tr>
                <tr>
                  <td>Día de menor producción:</td>
                  <td>{{menor_fecha.format('D - MMMM')}}</td>
                  <td>{{menor_valor | numeral('0,0.00')}}</td>
                </tr>
                <tr>
                  <td>Mediana</td>
                  <td>{{mediana_fecha.format('D - MMMM')}}</td>
                  <td>{{mediana_valor | numeral('0,0.00')}}</td>
                </tr>
                <tr>
                  <td>Promedio diario</td>
                  <td></td>
                  <td>{{avg_generacion | numeral('0,0.00')}}</td>
                </tr>
                <tr>
                  <td>Producción Total</td>
                  <td></td>
                  <td>{{total_generacion | numeral('0,0.00')}}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="graficoDiasData" class="chart-area">
            <line-chart 
              :style="grafico_style"
              chart-id="grafico-dias-line-chart"
              ref="grafico-dias-line-chart"
              :chart-data="graficoDiasData"
              :extra-options="graficoDiasOptions"
            >
            </line-chart>
            <line-chart 
              :style="grafico_style"
              chart-id="grafico-dias-line-chart-en"
              ref="grafico-dias-line-chart-en"
              :chart-data="graficoDiasDataEn"
              :extra-options="graficoDiasOptionsEn"
            >
            </line-chart>
          </div>
        </card>

        <hr>

        <card>
          <h2>2. Rendimiento por inversor</h2>

          <h3>Datos No Filtrados</h3>

          <table class="table">
            <thead>
              <tr>
                <th>Inversor</th>
                <th>Rend. Especifico kWh/kWp </th>
                <th>Producción</th>
                <th>Radiación POA</th>
                <th>Prod. Maxima Teórica</th>
                <th>PR Real</th>
              </tr>
            </thead>
            <tbody v-if="datosInversorSF">
              <tr
                v-for="(fila, idx) in datosInversorSF"
                :key="idx"
              >
                <td>{{fila.inversor}}</td>
                <td>{{fila.rendimiento | numeral('0,0.00')}}</td>
                <td>{{fila.generacion | numeral('0,0.00')}}</td>
                <td>{{fila.radiacion | numeral('0,0.00')}}</td>
                <td>{{fila.prod_max | numeral('0,0.00')}}</td>
                <td>{{fila.pr | numeral('0,0.00')}}</td>
              </tr>
            </tbody>
          </table>

          <h3>Datos Filtrados</h3>

          <table class="table">
            <thead>
              <tr>
                <th>Inversor</th>
                <th>Producción</th>
                <th>Radiación POA</th>
                <th>Prod. Maxima Teórica</th>
                <th>PR O&M</th>
              </tr>
            </thead>
            <tbody v-if="datosInversorF">
              <tr
                v-for="(fila, idx) in datosInversorF"
                :key="idx"
              >
                <td>{{fila.inversor}}</td>
                <td>{{fila.generacion | numeral('0,0.00')}}</td>
                <td>{{fila.radiacion | numeral('0,0.00')}}</td>
                <td>{{fila.prod_max | numeral('0,0.00')}}</td>
                <td>{{fila.pr | numeral('0,0.00')}}</td>
              </tr>
            </tbody>
          </table>
        </card>

        <hr>

        <card>
          <h2>3. Disponibilidad</h2>
          <ul>
            <li>Horas Totales de Luz: {{sum_disp | numeral('0,0.00')}} [h]</li>
            <li>Duración promedio del día: {{avg_disp | numeral('0,0.00')}} [h]</li>
          </ul>

          <div v-if="disponibilidadChart.chartData" class="chart-area">
            <bar-chart 
              :style="grafico_style"
              chart-id="disp-chart"
              ref="disp-chart"
              :chart-data="disponibilidadChart.chartData"
              :extra-options="disponibilidadChart.extraOptions"
            >
            </bar-chart>
            <bar-chart 
              :style="grafico_style"
              chart-id="disp-chart-en"
              ref="disp-chart-en"
              :chart-data="disponibilidadChartEn.chartData"
              :extra-options="disponibilidadChartEn.extraOptions"
            >
            </bar-chart>
          </div>
        </card>

        <hr>

        <card>
          <h2>5. Eventos de Indisponibilidad</h2>

          <table class="table">
            <thead>
              <tr>
                <th>N°</th>
                <th>Tipo</th>
                <th>Descripción</th>
                <th>Fecha Incidencia</th>
                <th>Horas de Indisponibilidad</th>
                <th></th>
                <th></th>
              </tr>  
            </thead>
            <thead>
              <tr v-for="dato in eventos_indisponibilidad" :key="dato.n">
                <td>{{dato.n_ot}}</td>
                <td>{{dato.evento}}</td>
                <td>{{dato.descripcion}}</td>
                <td>{{dato.fecha_incidencia}}</td>
                <td>{{dato.horas_indisponibilidad}}</td>
                <td>
                  
                </td>
                <td>
                  
                </td>
              </tr>
            </thead>
          </table>

        </card>
        
        <hr>

        <card>
          <h2>4. Referencia Histórica</h2>

          <table class="table">
            <thead>
              <tr>
                <th colspan="3" class="text-center">Irradiación</th>
                <th colspan="3" class="text-center">Producción de Energía</th>
                <th colspan="3" class="text-center">PR O&M</th>
                <th colspan="3" class="text-center">Disp. O&M</th>
              </tr>
              <tr>
                <th>Simulada</th>
                <th>Real</th>
                <th>Dif. %</th>

                <th>Simulada</th>
                <th>Real</th>
                <th>Dif. %</th>

                <th>Simulado</th>
                <th>O&M</th>
                <th>Dif. %</th>

                <th>Garantizado</th>
                <th>O&M</th>
                <th>Dif. %</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{datos_resumidos.irr_sim | numeral('0,0.00')}}</td>
                <td>{{datos_resumidos.irr_real | numeral('0,0.00')}}</td>
                <td>{{datos_resumidos.diff_irr | numeral('0,0.0')}}</td>
                
                <td>{{datos_resumidos.prod_sim | numeral('0,0.00')}}</td>
                <td>{{datos_resumidos.prod_real | numeral('0,0.00')}}</td>
                <td>{{datos_resumidos.diff_prod | numeral('0,0.0')}}</td>
                
                <td>{{datos_resumidos.pr_sim | numeral('0,0.0')}}</td>
                <td>{{datos_resumidos.pr | numeral('0,0.0')}}</td>
                <td>{{datos_resumidos.diff_pr | numeral('0,0.0')}}</td>
                
                <td>{{datos_resumidos.disp_sim | numeral('0,0.0')}}</td>
                <td>{{datos_resumidos.disp | numeral('0,0.0')}}</td>
                <td>{{datos_resumidos.diff_disp | numeral('0,0.0')}}</td>
              </tr>
            </tbody>
          </table>

          <div v-if="generacionHistoricaData" class="chart-area">
            <bar-chart 
              :style="grafico_style"
              chart-id="generacion-historica-bar-chart"
              ref="generacion-historica-bar-chart"
              :chart-data="generacionHistoricaData"
              :extra-options="generacionHistoricaDataOptions"
            >
            </bar-chart>
            <bar-chart 
              :style="grafico_style"
              chart-id="generacion-historica-bar-chart-en"
              ref="generacion-historica-bar-chart-en"
              :chart-data="generacionHistoricaDataEn"
              :extra-options="generacionHistoricaDataOptionsEn"
            >
            </bar-chart>
          </div>

        </card>
        
        <hr>

        <card>
          <h2>5. Mantenimiento Correctivo</h2>

          <!-- Modal Correctivo -->
          <modal 
            :show.sync="modals.modal_editar_correctivo"
            body-classes="p-0"
            modal-classes="modal-dialog-centered modal-sm"
          >
            <card 
              type="secondary"
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0 mb-0"
            >
              <template>
                <div class="text-center text-muted mb-4">
                  <small>Editar datos</small>
                </div>
                <form role="form">
                  <base-input 
                    label="N°"
                    placeholder="N°"
                    v-model="datos_edicion_correctivo.n"
                    type="number"
                  >
                  </base-input>
                  <base-input 
                    label="Descripcion"
                    placeholder="Descripcion"
                    v-model="datos_edicion_correctivo.descripcion"
                  >
                  </base-input>
                  <base-input label="Tipo">
                    <select class="form-control" id="correctivo_select" v-model="datos_edicion_correctivo.tipo">
                      <option value="Correctivo">Correctivo</option>
                      <option value="Preventivo">Preventivo</option>
                    </select>
                  </base-input>
                  <div class="col-md-12">
                    <label class="control-label">
                      Fecha de Incidencia
                    </label>
                    <br>
                    <date-picker type="datetime" class="datepicker" v-model="datos_edicion_correctivo.fecha_incidencia" valueType="format">
                    </date-picker>
                  </div>
                  <div class="col-md-12">
                    <label class="control-label">
                      Fecha de resolucion
                    </label>
                    <br>
                    <date-picker type="datetime" class="datepicker" v-model="datos_edicion_correctivo.fecha_resolucion" valueType="format">
                    </date-picker>
                  </div>
                  <base-input 
                    label="Horas de Indisponibilidad"
                    placeholder="Horas de Indisponibilidad"
                    v-model="datos_edicion_correctivo.horas_indisponibilidad"
                    type="number"
                  >
                  </base-input>
                  <base-input>
                    <label>Observaciones</label>
                    <textarea
                      rows="4"
                      cols="80"
                      class="form-control"
                      placeholder="Observaciones"
                      v-model="datos_edicion_correctivo.observaciones"
                    >
                    </textarea>
                  </base-input>
                </form>
              </template>  
            </card>
            <template slot="footer">
              <base-button type="primary" @click="editarDatosMantenimiento('correctivo')">
                Guardar Cambios
              </base-button>
              <base-button type="secondary" class="ml-auto" @click="modals.modal_editar_correctivo = false">
                Cancelar
              </base-button>
            </template>
          </modal>

          <h3>Agregar Datos</h3>

          <div class="row">
            <div class="col-md-2">
              <base-input 
                label="N°"
                placeholder="N°"
                v-model="correctivo_datos.n"
                type="number"
              >
              </base-input>
            </div>
            <div class="col-md-4">
              <base-input 
                label="Descripcion"
                placeholder="Descripcion"
                v-model="correctivo_datos.descripcion"
              >
              </base-input>
            </div>
            <div class="col-md-2">
              <base-input label="Tipo">
                <select class="form-control" id="correctivo_select" v-model="correctivo_datos.tipo">
                  <option value="Correctivo">Correctivo</option>
                  <option value="Preventivo">Preventivo</option>
                </select>
              </base-input>
            </div>
            <div class="col-md-2">
              <label class="control-label">
                Fecha de Incidencia
              </label>
              <br>
              <date-picker type="datetime" class="datepicker" v-model="correctivo_datos.fecha_incidencia" valueType="format">
              </date-picker>
            </div>
            <div class="col-md-2">
              <label class="control-label">
                Fecha de resolucion
              </label>
              <br>
              <date-picker type="datetime" class="datepicker" v-model="correctivo_datos.fecha_resolucion" valueType="format">
              </date-picker>
            </div>
            <div class="col-md-2">
              <base-input 
                label="Horas de Indisponibilidad"
                placeholder="Horas de Indisponibilidad"
                v-model="correctivo_datos.horas_indisponibilidad"
                type="number"
              >
              </base-input>
            </div>
            <div class="col-md-8">
              <base-input>
                <label>Observaciones</label>
                <textarea
                  rows="4"
                  cols="80"
                  class="form-control"
                  placeholder="Observaciones"
                  v-model="correctivo_datos.observaciones"
                >
                </textarea>
              </base-input>
            </div>
            <div class="col-md-2">
              <br>
              <base-button @click="agregarDatosMantenimiento('correctivo')" native-type="button" type="primary" round block size="lg">
                Agregar
              </base-button>
            </div>
          </div>
          <hr>

          <table class="table">
            <thead>
              <tr>
                <th>N°</th>
                <th>Tipo</th>
                <th>Descripción</th>
                <th>Fecha Incidencia</th>
                <th>Fecha Resolución</th>
                <th>Horas de Indisponibilidad</th>
                <th>Observaciones/Comentarios</th>
                <th></th>
                <th></th>
              </tr>  
            </thead>
            <thead>
              <tr v-for="dato in correctivo_lista" :key="dato.n">
                <td>{{dato.n}}</td>
                <td>{{dato.tipo}}</td>
                <td>{{dato.descripcion}}</td>
                <td>{{dato.fecha_incidencia}}</td>
                <td>{{dato.fecha_resolucion}}</td>
                <td>{{dato.horas_indisponibilidad}}</td>
                <td>{{dato.observaciones}}</td>
                <td>
                  <base-button @click="abrirEditarModalMantenimiento('correctivo', dato)" native-type="button" type="info" round block size="lg">
                    Editar
                  </base-button>
                </td>
                <td>
                  <base-button @click="eliminarDatosMantenimiento('correctivo', dato.n)" native-type="button" type="danger" round block size="lg">
                    Eliminar
                  </base-button>
                </td>
              </tr>
            </thead>
          </table>

        </card>

        <hr>

        <card>
          <h2>6. Mantenimiento Preventivo</h2>
          <h3>6.1 Ejecutado
            <span v-if="fecha_inicio">
              {{fecha_inicio.format('MMMM YYYY')}}
            </span>
          </h3>

          <!-- Modal Ejecutado -->
          <modal 
            :show.sync="modals.modal_editar_ejecutado"
            body-classes="p-0"
            modal-classes="modal-dialog-centered modal-sm"
          >
            <card 
              type="secondary"
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0 mb-0"
            >
              <template>
                <div class="text-center text-muted mb-4">
                  <small>Editar datos</small>
                </div>
                <form role="form">
                  <base-input 
                    label="N°"
                    placeholder="N°"
                    v-model="datos_edicion_ejecutado.n"
                    type="number"
                  >
                  </base-input>
                  <base-input 
                    label="Descripcion"
                    placeholder="Descripcion"
                    v-model="datos_edicion_ejecutado.descripcion"
                  >
                  </base-input>
                  <label class="control-label">
                    Fecha de Inicio
                  </label>
                  <br>
                  <date-picker class="datepicker" v-model="datos_edicion_ejecutado.fecha_inicio" valueType="format">
                  </date-picker>
                  <br>
                  <label class="control-label">
                    Fecha de termino
                  </label>
                  <br>
                  <date-picker class="datepicker" v-model="datos_edicion_ejecutado.fecha_termino" valueType="format">
                  </date-picker>
                  <base-input label="Estado">
                    <select class="form-control" id="preventivo_estado" v-model="datos_edicion_ejecutado.estado">
                      <option value="Abierto">Abierto</option>
                      <option value="Cerrado">Cerrado</option>
                    </select>
                  </base-input>
                  <base-input>
                    <label>Comentarios</label>
                    <textarea
                      rows="4"
                      cols="80"
                      class="form-control"
                      placeholder="Observaciones"
                      v-model="datos_edicion_ejecutado.comentarios"
                    >
                    </textarea>
                  </base-input>

                </form>
              </template>  
            </card>
            <template slot="footer">
              <base-button type="primary" @click="editarDatosMantenimiento('ejecutado')">
                Guardar Cambios
              </base-button>
              <base-button type="secondary" class="ml-auto" @click="modals.modal_editar_ejecutado = false">
                Cancelar
              </base-button>
            </template>
          </modal>

          <h4>Agregar Datos</h4>

          <div class="row">
            <div class="col-md-1">
              <base-input 
                label="N°"
                placeholder="N°"
                v-model="preventivo_ejecutado_datos.n"
                type="number"
              >
              </base-input>
            </div>
            <div class="col-md-3">
              <base-input 
                label="Descripcion"
                placeholder="Descripcion"
                v-model="preventivo_ejecutado_datos.descripcion"
              >
              </base-input>
            </div>
            <div class="col-md-2">
              <label class="control-label">
                Fecha de Inicio
              </label>
              <br>
              <date-picker class="datepicker" v-model="preventivo_ejecutado_datos.fecha_inicio" valueType="format">
              </date-picker>
            </div>
            <div class="col-md-2">
              <label class="control-label">
                Fecha de termino
              </label>
              <br>
              <date-picker class="datepicker" v-model="preventivo_ejecutado_datos.fecha_termino" valueType="format">
              </date-picker>
            </div>
            <div class="col-md-2">
              <base-input label="Estado">
                <select class="form-control" id="preventivo_estado" v-model="preventivo_ejecutado_datos.estado">
                  <option value="Abierto">Abierto</option>
                  <option value="Cerrado">Cerrado</option>
                </select>
              </base-input>
            </div>
            <div class="col-md-8">
              <base-input>
                <label>Comentarios</label>
                <textarea
                  rows="4"
                  cols="80"
                  class="form-control"
                  placeholder="Observaciones"
                  v-model="preventivo_ejecutado_datos.comentarios"
                >
                </textarea>
              </base-input>
            </div>
            <div class="col-md-4">
              <br>
              <base-button @click="agregarDatosMantenimiento('ejecutado')" native-type="button" type="primary" round block size="lg">
                Agregar
              </base-button>
            </div>
            
          </div>
          <hr>
          <h4>Datos guardados</h4>

          <table class="table">
            <thead>
              <tr>
                <th>N°</th>
                <th>Descripción</th>
                <th>Fecha Inicio</th>
                <th>Fecha Termino</th>
                <th>Estado</th>
                <th>Comentarios</th>
                <th></th>
                <th></th>
              </tr>  
            </thead>
            <thead>
              <tr v-for="dato in preventivo_ejecutado_lista" :key="dato.n">
                <td>{{dato.n}}</td>
                <td>{{dato.descripcion}}</td>
                <td class="td-fecha">{{dato.fecha_inicio}}</td>
                <td class="td-fecha">{{dato.fecha_termino}}</td>
                <td>{{dato.estado}}</td>
                <td>{{dato.comentarios}}</td>
                <td>
                  <base-button @click="abrirEditarModalMantenimiento('ejecutado', dato)" native-type="button" type="info" round block size="lg">
                    Editar
                  </base-button>
                </td>
                <td>
                  <base-button @click="eliminarDatosMantenimiento('ejecutado', dato.n)" native-type="button" type="danger" round block size="lg">
                    Eliminar
                  </base-button>
                </td>
              </tr>
            </thead>
          </table>

          <hr>

          <h3>6.2 Planificado 
            <span v-if="fecha_inicio">
              {{fecha_inicio.add(1, 'month').format('MMMM YYYY')}}
            </span>
          </h3>

          <!-- Modal Planificado -->
          <modal 
            :show.sync="modals.modal_editar_planificado"
            body-classes="p-0"
            modal-classes="modal-dialog-centered modal-sm"
          >
            <card 
              type="secondary"
              header-classes="bg-white pb-5"
              body-classes="px-lg-5 py-lg-5"
              class="border-0 mb-0"
            >
              <template>
                <div class="text-center text-muted mb-4">
                  <small>Editar datos</small>
                </div>
                <form role="form">
                  <base-input 
                    label="N°"
                    placeholder="N°"
                    v-model="datos_edicion_planificado.n"
                    type="number"
                  >
                  </base-input>
                  <base-input 
                    label="Descripcion"
                    placeholder="Descripcion"
                    v-model="datos_edicion_planificado.descripcion"
                  >
                  </base-input>
                  <label class="control-label">
                    Fecha de Inicio
                  </label>
                  <br>
                  <date-picker class="datepicker" v-model="datos_edicion_planificado.fecha_inicio" valueType="format">
                  </date-picker>
                  <br>
                  <label class="control-label">
                    Fecha de termino
                  </label>
                  <br>
                  <date-picker class="datepicker" v-model="datos_edicion_planificado.fecha_termino" valueType="format">
                  </date-picker>
                  <base-input label="Estado">
                    <select class="form-control" id="preventivo_estado" v-model="datos_edicion_planificado.estado">
                      <option value="Abierto">Abierto</option>
                      <option value="Cerrado">Cerrado</option>
                    </select>
                  </base-input>
                  <base-input>
                    <label>Comentarios</label>
                    <textarea
                      rows="4"
                      cols="80"
                      class="form-control"
                      placeholder="Observaciones"
                      v-model="datos_edicion_planificado.comentarios"
                    >
                    </textarea>
                  </base-input>

                </form>
              </template>  
            </card>
            <template slot="footer">
              <base-button type="primary" @click="editarDatosMantenimiento('planificado')">
                Guardar Cambios
              </base-button>
              <base-button type="secondary" class="ml-auto" @click="modals.modal_editar_planificado = false">
                Cancelar
              </base-button>
            </template>
          </modal>

          <h4>Agregar Datos</h4>
          <div class="row">
            <div class="col-md-2">
              <base-input 
                label="N°"
                placeholder="N°"
                v-model="preventivo_planificado_datos.n"
                type="number"
              >
              </base-input>
            </div>
            <div class="col-md-4">
              <base-input 
                label="Descripcion"
                placeholder="Descripcion"
                v-model="preventivo_planificado_datos.descripcion"
              >
              </base-input>
            </div>
            <div class="col-md-2">
              <label class="control-label">
                Fecha de Inicio
              </label>
              <br>
              <date-picker class="datepicker" v-model="preventivo_planificado_datos.fecha_inicio" valueType="format">
              </date-picker>
            </div>
            <div class="col-md-2">
              <label class="control-label">
                Fecha de termino
              </label>
              <br>
              <date-picker class="datepicker" v-model="preventivo_planificado_datos.fecha_termino" valueType="format">
              </date-picker>
            </div>
            <div class="col-md-2">
              <base-input label="Estado">
                <select class="form-control" id="planificado_estado" v-model="preventivo_planificado_datos.estado">
                  <option value="Abierto">Abierto</option>
                  <option value="Cerrado">Cerrado</option>
                </select>
              </base-input>
            </div>
            <div class="col-md-8">
              <base-input>
                <label>Comentarios</label>
                <textarea
                  rows="4"
                  cols="80"
                  class="form-control"
                  placeholder="Observaciones"
                  v-model="preventivo_planificado_datos.comentarios"
                >
                </textarea>
              </base-input>
            </div>
            <div class="col-md-4">
              <br>
              <base-button @click="agregarDatosMantenimiento('planificado')" native-type="button" type="primary" round block size="lg">
                Agregar
              </base-button>
            </div>
            
          </div>
          <hr>

          <h4>Datos guardados</h4>
          <table class="table">
            <thead>
              <tr>
                <th>N°</th>
                <th>Descripción</th>
                <th>Fecha Inicio</th>
                <th>Fecha Termino</th>
                <th>Estado</th>
                <th>Comentarios</th>
                <th></th>
                <th></th>
              </tr>  
            </thead>
            <thead>
              <tr v-for="dato in preventivo_planificado_lista" :key="dato.n">
                <td>{{dato.n}}</td>
                <td>{{dato.descripcion}}</td>
                <td class="td-fecha">{{dato.fecha_inicio}}</td>
                <td class="td-fecha">{{dato.fecha_termino}}</td>
                <td>{{dato.estado}}</td>
                <td>{{dato.comentarios}}</td>
                <td>
                  <base-button @click="abrirEditarModalMantenimiento('planificado', dato)" native-type="button" type="info" round block size="lg">
                    Editar
                  </base-button>
                </td>
                <td>
                  <base-button @click="eliminarDatosMantenimiento('planificado', dato.n)" native-type="button" type="danger" round block size="lg">
                    Eliminar
                  </base-button>
                </td>
              </tr>
            </thead>
          </table>
        </card>

        <hr>

        <card v-if="tiene_smart_meter && !usar_boleta_autoconsumo">
          <h2>Smart Data</h2>
          <div v-if="autoconsumoGraficoData" class="chart-area">
            <bar-chart 
              :style="grafico_style"
              chart-id="autoconsumo-bar-chart"
              ref="autoconsumo-bar-chart"
              :chart-data="autoconsumoGraficoData"
              :extra-options="autoconsumoGraficoDataOptions"
            >
            </bar-chart>
          </div>
        </card>

        <hr>

        <card>
          <div class="row">
            <h3>Generacion Mensual</h3>
            <div class="tablaGrid">
              <v-grid
                theme="compact"
                :source="dataMensual"
                :columns="columnsMensual"
                :columnTypes="columnTypes"
                resize=true
                readonly=true
              >
              </v-grid>
            </div>
            
          </div>

          <div class="row">
            <h3>Irradiacion Mensual (Inc)</h3>
            <div class="tablaGrid">
              <v-grid
                theme="compact"
                :source="irradiationMensualInc"
                :columns="columnasMensualIrradiacion"
                :columnTypes="columnTypes"
                resize=true
                readonly=true
              >
              </v-grid>
            </div>
          </div>
        </card>
      </template>

      <template v-if="current_view === 'datos'">
        <h2>Datos Recibidos</h2>
        
        <div v-if="dataPorDia.length == dataIrradiacionPorDia.length">
          <h4>Datos de Generación: {{dataPorDia.length}}</h4>
          <h4>Datos de Irradiación: {{dataIrradiacionPorDia.length}}</h4>
        </div>
        <div v-else> 
          <h4 class="text-danger">Datos de Generación: {{dataPorDia.length}}</h4>
          <h4 class="text-danger">Datos de Irradiación: {{dataIrradiacionPorDia.length}}</h4>
        </div>

        <div v-if="tiene_smart_meter">
          <h4>Datos de Autoconsumo: {{dataSmartPorDia.length}}</h4>
        </div>

        <div class="row">
          <h3>Generacion Por Día</h3>
          <div class="tablaGrid">
            <v-grid
              theme="compact"
              :source="dataPorDia"
              :columns="columns"
              :columnTypes="columnTypes"
              readonly=true
            >
            </v-grid>
          </div>
        </div>

        <div class="row">
          <h3>Irradiacion Por Día</h3>
          <div class="tablaGrid">
            <v-grid
              theme="compact"
              :source="dataIrradiacionPorDia"
              :columns="columnsIrradiacion"
              :columnTypes="columnTypes"
              readonly=true
            >
            </v-grid>
          </div>
        </div>

        <div class="row">
          <h3>Temperatura Por Día</h3>
          <div class="tablaGrid">
            <v-grid
              theme="compact"
              :source="dataTemperaturaPorDia"
              :columns="columnsTemperatura"
              :columnTypes="columnTypes"
              readonly=true
            >
            </v-grid>
          </div>
        </div>

        <div class="row">
          <h3>Estado Por Día</h3>
          <div class="tablaGrid">
            <v-grid
              theme="compact"
              :source="dataEstadoPorDia"
              :columns="columnasEstadoInversores"
              :columnTypes="columnTypes"
              readonly=true
            >
            </v-grid>
          </div>
        </div>
      </template>

      <template v-if="current_view === 'disponibilidad'">
        <h2>Disponibilidad</h2>

        <div class="row">
          <h3>Filtrado 1</h3>
          <div class="tablaGrid">
            <v-grid
              theme="compact"
              :source="dataDisponibilidadPorDia"
              :columns="columnasDisponibilidad"
              :columnTypes="columnTypes"
              v-on:afteredit="editTablaDisponibilidad"
              v-on:beforeedit="editSingleCell"
              v-on:beforerangeedit="editRangeCell"
              range="true"
              resize="true"
            >
            </v-grid>
          </div>
        </div>
        <div class="row">
          <h3>Disponibilidad: Operacion</h3>
          <div class="tablaGrid">
            <v-grid
              theme="compact"
              :source="tablaDisponibilidadOperacion"
              :columns="columnasDisponibilidadOperacion"
              :columnTypes="columnTypes"
              range="true"
            >
            </v-grid>
          </div>
        </div>

        <div class="row">
          <h3>Disponibilidad: Out</h3>
          <div class="tablaGrid">
            <v-grid
              theme="compact"
              :source="tablaDisponibilidadOut"
              :columns="columnasDisponibilidadOut"
              :columnTypes="columnTypes"
              range="true"
            >
            </v-grid>
          </div>
        </div>

        <div class="row">
          <h3>Disponibilidad: Exclusion</h3>
          <div class="tablaGrid">
            <v-grid
              theme="compact"
              :source="tablaDisponibilidadExclusion"
              :columns="columnasDisponibilidadExclusion"
              :columnTypes="columnTypes"
              range="true"
            >
            </v-grid>
          </div>
        </div>

        <div class="row">
          <h3>Disponibilidad O&M</h3>
          <div class="tablaGrid">
            <v-grid
              theme="compact"
              :source="tablaDisponibilidadOM"
              :columns="columnasDisponibilidadOM"
              :columnTypes="columnTypes"
              range="true"
            >
            </v-grid>
          </div>
        </div>
      </template>

      <template v-if="current_view === 'pr'">
        <h2>PR</h2>
        <div>
          <h3>Datos Generales</h3>
          <div class="tablaGrid">
            <v-grid
              theme="compact"
              :source="dataPRGeneralPorDia"
              :columns="columnasGeneralPR"
              :columnTypes="columnTypes"
              v-on:afteredit="afterEditTablaPR"
              v-on:beforeedit="editSingleCellPR"
              v-on:beforerangeedit="editRangeCellPR"
              range="true"
            >
            </v-grid>
          </div>
        </div>

        <div class="row">
          <h3>Generacion</h3>
          <div class="tablaGrid">
            <v-grid
              theme="compact"
              :source="dataPRGenPorDia"
              :columns="columnasGeneracionPR"
              :columnTypes="columnTypes"
              readonly=true
            >
            </v-grid>
          </div>
        </div>

        <div class="row">
          <h3>Radiacion</h3>
          <div class="tablaGrid">
            <v-grid
              theme="compact"
              :source="dataPRIrrPorDia"
              :columns="columnasIrradiacionPR"
              :columnTypes="columnTypes"
              range="true"
            >
            </v-grid>
          </div>
        </div>
      </template>

      <template v-if="current_view === 'historico'">
        <card>
          <h2>Valor del Dolar</h2>
          <div class="tablaGrid">
            <v-grid
              theme="compact"
              :source="tablaHistoricoDolar"
              :columns="columnasHistoricoDolar"
              :columnTypes="columnTypes"
              resize=true
              readonly=true
            >
            </v-grid>
          </div>
        </card>

        <card>
          <h2>Radiacion</h2>
          <div class="tablaGrid">
            <v-grid
              theme="compact"
              :source="tablaHistoricoIrr"
              :columns="columnasHistoricoIrr"
              :columnTypes="columnTypes"
              resize=true
              readonly=true
            >
            </v-grid>
          </div>
        </card>

        <card>
          <h2>Generacion</h2>
          <div class="tablaGrid">
            <v-grid
              theme="compact"
              :source="tablaHistoricoGen"
              :columns="columnasHistoricoGen"
              :columnTypes="columnTypes"
              resize=true
              readonly=true
              filter=true
            >
            </v-grid>
          </div>
        </card>

        <card>
          <h2>Performance Ratio</h2>
          <div class="tablaGrid">
            <v-grid
              theme="compact"
              :source="tablaHistoricoPR"
              :columns="columnasHistoricoPR"
              :columnTypes="columnTypes"
              resize=true
              readonly=true
            >
            </v-grid>
          </div>
        </card>

        <card>
          <h2>Disponibilidad</h2>
          <div class="tablaGrid">
            <v-grid
              theme="compact"
              :source="tablaHistoricoDisp"
              :columns="columnasHistoricoDisp"
              :columnTypes="columnTypes"
              resize=true
              readonly=true
            >
            </v-grid>
          </div>
        </card>
      </template>
    </div>

    <div v-else>
      Cargando datos...
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import _ from "lodash";
import VGrid from "@revolist/vue-datagrid";
import { mapState, mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
var customParseFormat = require('dayjs/plugin/customParseFormat')
var localeData = require('dayjs/plugin/localeData');
var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(customParseFormat)
dayjs.extend(utc);
dayjs.extend(localeData);
dayjs.extend(isBetween)
require('dayjs/locale/es');
dayjs.locale('es');


import vueNumeralFilterInstaller from 'vue-numeral-filter';
Vue.use(vueNumeralFilterInstaller, {locale: 'es'});

// var instance = axios.create({
//   baseURL: 'https://api.solarweb.com/swqapi/pvsystems/',
//   headers: {
//     'AccessKeyId': 'FKIABE31852E6BDE4C689E86F138C6098A72',
//     'AccessKeyValue': '0330850d-eefa-4661-ad76-a299bfcef97e',
//   }
// });
// delete instance.defaults.headers.common['Authorization'];


// var backend_db_instance = axios.create({
//   baseURL: process.env.VUE_APP_BACKEND_DB_URL,
// });


import NumberColumnType from '@revolist/revogrid-column-numeral';
import SelectTypePlugin from "@revolist/revogrid-column-select";

const dropdown = {
  labelKey: 'label',
  valueKey: 'value',
  source: [
    { value: 'operacion', label: 'Operación'},
    { value: 'out', label: 'Out - Planta'},
    { value: 'exclusion', label: 'Exclusión - Planta'}
  ],
};

const dropdown_inv = {
  labelKey: 'label',
  valueKey: 'value',
  source: [
    { value: 'bajo', label: 'Bajo MIT'},
    { value: 'operacion', label: 'Operacion - Inversor'},
    { value: 'out', label: 'Out - Inversor'},
    { value: 'exclusion', label: 'Exclusion - Inversor'}
  ],
};

const dropdown_pr = {
  labelKey: 'label',
  valueKey: 'value',
  source: [
    { value: 'ok', label: 'OK'},
    { value: 'incoherencia', label: 'Incoherencia'},
  ],
};

const dropdown_pr2 = {
  labelKey: 'label',
  valueKey: 'value',
  source: [
    { value: 'ok', label: 'OK'},
    { value: 'incoherencia', label: 'Incoherencia'},
    { value: 'disp', label: 'Filtrado Disp.'},
    { value: 'sin_g', label: 'Sin Generacion'},
    { value: 'sin_r', label: 'Sin Radiacion'},
    { value: 'power_limit', label: 'Power Limit'},
  ],
};

import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';
import CustomLineChart from '@/components/Charts/CustomLineChart';
import * as chartConfigs from '@/components/Charts/config';
import Modal from '@/components/Modal.vue';

import {MESES} from '@/utils/constants';

export default {
  name: 'reporte-pmgd-page',
  components: {
    VGrid,
    LineChart,
    BarChart,
    CustomLineChart,
    DatePicker,
    Modal
  },

  computed: {
    ...mapState({
      planta: state => state.report.planta,
      reporte: state => state.report.reporte,
      fecha_inicio: state => state.report.fecha_inicio,
      fecha_fin: state => state.report.fecha_fin,
      generationData: state => state.report.generationData,
      irradiationData: state => state.report.irradiationData,
      temperaturaData: state => state.report.temperaturaData,
      estadoInversoresData: state => state.report.estadoInversoresData,
      irradiationPmgdData: state => state.report.radiacionPmgdData,
      smartData: state => state.report.smartData,
      // irradiationInc: state => state.report.irradiationInc,
      // irradiationMensualInc: state => state.report.irradiationMensualInc,
    }),

    listadoDias(){
      if(this.generationData==null){
        return [];
      }

      let listado = this.generationData.reduce((acc, curr) => {
        let diaActual = curr['Fecha'].date();
        if(acc.indexOf(diaActual)<0){
          return [...acc, diaActual];
        }
        return acc;
      }, []);

      return listado;
    },
  },

  data(){
    return {
      usa_codigo_inversor: true,
      valor_mit: 0,
      valor_mit_real: 10,
      tabla_mit: [],
      tabla_mit_real: [],
      // grafico_style: "height:350px; width:650px",
      grafico_style: "height:650px; width:1300px;",
      modals: {
        modal_editar_correctivo: false,
        modal_editar_ejecutado: false,
        modal_editar_planificado: false,
      },
      loading: true,
      factor_comun: 1,
      tiene_smart_meter: false,
      usar_boleta_autoconsumo: false,
      boleta_inyeccion: 0,
      boleta_autoconsumo: 0,
      dataPorDia: [],
      dataIrradiacionPorDia: [],
      dataTemperaturaPorDia: [],
      dataEstadoPorDia: [],
      dataSmartPorDia: [],
      dataDisponibilidadPorDia: [],
      dataDisponibilidadRealPorDia: [],
      dataPRGeneral: [],
      dataPRGeneralPorDia: [],
      dataPRGenPorDia: [],
      dataPRIrrPorDia: [],
      min_idx: 0,
      max_idx: 0,
      pvsol_esperado: {},
      pvsol_esperado_ndias: {},
      n_dias: 1,
      // eficiencia por inversor
      eficiencia: 0,
      // suma de eficiencias
      eficiencia_sum: 0,
      // general
      generacionChart: {
        extraOptions: chartConfigs.genChartOptions,
        chartData: null,
      },
      generacionChartEn: {
        extraOptions: chartConfigs.genChartOptionsEn,
        chartData: null,
      },
      disponibilidadChart: {
        extraOptions: chartConfigs.dispChartOptions,
        chartData: null,
      },
      disponibilidadChartEn: {
        extraOptions: chartConfigs.dispChartOptionsEn,
        chartData: null,
      },
      current_view: 'visualizador',
      selectedDia: 0,
      selectedVisualizador: 0,
      visualizadorNoFiltradoData: null,
      visualizadorNoFiltradoDataOptions: chartConfigs.genNFChartOptions,
      visualizadorFiltradoData: null,
      visualizadorFiltradoDataOptions: chartConfigs.genFChartOptions,
      visualizadorInversoresFiltradoData: null,
      visualizadorInversoresFiltradoDataOptions: chartConfigs.genNFInversoresChartOptions,
      visualizadorInversoresNoFiltradoData: null,
      visualizadorInversoresNoFiltradoDataOptions: chartConfigs.genFInversoresChartOptions,
      generacionHistoricaData: null,
      generacionHistoricaDataOptions: chartConfigs.genHistoricaChartOptions,
      generacionHistoricaDataEn: null,
      generacionHistoricaDataOptionsEn: chartConfigs.genHistoricaChartOptionsEn,
      autoconsumoGraficoData: null,
      autoconsumoGraficoDataOptions: chartConfigs.autoconsumoChartOptions,
      columns: [],
      columnsIrradiacion: [],
      columnsSmart: [
        {
          prop: 'Fecha',
          name: 'Día/Hora',
          sortable: true,
          pin: 'colPinStart',
          autoSize: true,
          cellTemplate: (createElement, props) => {
            return createElement('span', {
            }, props.model[props.prop].format('D HH:mm'));
          }
        },
        {
          prop: 'consumo',
          name: 'Consumo',
          columnType: 'numeric2',
          autoSize: true,
        },
        {
          prop: 'inyeccion',
          name: 'Inyección',
          columnType: 'numeric2',
          autoSize: true,
        },
      ],
      columnas: [],
      columnTypes: {
        'int': new NumberColumnType('0,0'),
        'numeric': new NumberColumnType('0,0.0'),
        'numeric2': new NumberColumnType('0,0.00'),
        'numeric3': new NumberColumnType('0,0.000'),
        'numeric4': new NumberColumnType('0,0.0000'),
        'select': new SelectTypePlugin()
      },
      dataMensual: [],
      dataMensualIrr: [],
      columnsMensual: [],
      dataMensualIrradiacion: [],
      columnasMensualIrradiacion: [
        {
          prop: 'Fecha',
          name: 'Fecha',
          pin: 'colPinStart',
          autoSize: true,
          cellTemplate: (createElement, props) => {
            return createElement('span', {
              // style: {
              //   color: 'red'
              // },
            }, props.model[props.prop].format('D'));
          }
        },
        {
          prop: 'Global',
          name: 'Global',
          columnType: 'numeric',
          autoSize: true
        }
      ],
      tablaDisponibilidad: [],
      columnasDisponibilidad: [],
      tablaDisponibilidadReal: [],
      columnasDisponibilidad: [],
      tablaDisponibilidadOperacion:[],
      columnasDisponibilidadOperacion: [],
      tablaDisponibilidadOut:[],
      columnasDisponibilidadOut: [],
      tablaDisponibilidadExclusion:[],
      columnasDisponibilidadExclusion: [],
      tablaDisponibilidadOperacionReal:[],
      columnasDisponibilidadOperacionReal: [],
      tablaDisponibilidadOutReal:[],
      columnasDisponibilidadOutReal: [],
      tablaDisponibilidadExclusionReal:[],
      columnasDisponibilidadExclusionReal: [],
      tablaDisponibilidadOM:[],
      columnasDisponibilidadOM: [],
      tablaTiempoDisponibilidad:[],
      tablaTiempoDisponibilidadReal:[],
      tablaTiempoDisponibilidadResumen:[],
      tablaTiempoDisponibilidadRealResumen:[],
      columnasTiempoDisponibilidad:[
        {
          prop: 'Fecha',
          name: 'Dia',
          sortable: true,
          pin: 'colPinStart',
          cellTemplate: (createElement, props) => {
            if('Fecha' in props.model){
              return createElement('span', {}, props.model[props.prop].format('D'));
            }
            return createElement('span', {}, 'Total');
          }
        },
        {
          prop: 'operacion',
          name: 'Operacion',
          columnType: 'numeric2',
        },
        {
          prop: 'out',
          name: 'Out',
          columnType: 'numeric2',
        },
        {
          prop: 'exclusion',
          name: 'Exclusion',
          columnType: 'numeric2',
        },
        // {
        //   prop: 'real',
        //   name: 'Real',
        //   columnType: 'numeric2',
        // },
        {
          prop: 'om',
          name: 'O&M',
          columnType: 'numeric2',
        }
      ],
      columnasTiempoDisponibilidadReal:[
        {
          prop: 'Fecha',
          name: 'Dia',
          sortable: true,
          pin: 'colPinStart',
          cellTemplate: (createElement, props) => {
            if('Fecha' in props.model){
              return createElement('span', {}, props.model[props.prop].format('D'));
            }
            return createElement('span', {}, 'Total');
          }
        },
        {
          prop: 'operacion',
          name: 'Operacion',
          columnType: 'numeric2',
        },
        {
          prop: 'out',
          name: 'Out',
          columnType: 'numeric2',
        },
        {
          prop: 'exclusion',
          name: 'Exclusion',
          columnType: 'numeric2',
        },
        {
          prop: 'real',
          name: 'Real',
          columnType: 'numeric2',
        },
      ],
      // Visualizador diario
      inversores_checkbox: [],
      selectedInversores: null,
      // Tablas Relacionadas al PR
      tablaGeneracionPR: [],
      columnasGeneracionPR: [],
      columnasGeneralPR: [],
      tablaIrradiacionPR:[],
      columnasIrradiacionPR:[],
      tablaTotalFiltradoPR: [],
      columnasTotalFiltradoPR: [],
      tablaGeneralSinFiltrar: [],
      tablaGeneralSinFiltrarResumen: [],
      columnasGeneralSinFiltrar: [],
      tablaExclusionesFiltrados: [],
      tablaExclusionesFiltradosResumen: [],
      columnasExclusionesFiltrados: [],
      irradiationInc: [],
      irradiationMensualInc: [],
      irradiationMensualIncBottom: [{'Fecha': 'hola', 'Global': 100}],
      rendimiento_especifico: 0,
      generacion_simulada: 0,
      generacion_diferencia: 0,
      irradiacion_total: 0,
      irradiacion_simulada: 0,
      irradiacion_diferencia: 0,
      performance_ratio: 0,
      performance_ratio_medido: 0,
      performance_ratio_simulado: 0,
      performance_ratio_diferencia: 0,
      disponibilidad_om: 0,
      disponibilidad_real: 0,
      disponibilidad_garantizada: 0,
      disponibilidad_diferencia: 0,
      total_autoconsumo: 0,
      total_inyeccion: 0,
      co2: 0,
      mayor_valor: 0,
      mayor_fecha: null,
      graficoDiasData: null,
      graficoDiasOptions: chartConfigs.graficoDiasOptions,
      graficoDiasDataEn: null,
      graficoDiasOptionsEn: chartConfigs.graficoDiasOptionsEn,
      menor_valor: 999999,
      menor_fecha: null,
      mediana_valor: 0,
      mediana_fecha: null,
      total_generacion: 0,
      avg_generacion: 0,
      // Disponibilidad
      sum_disp: 0,
      avg_disp: 0,
      datosInversorSF: null,
      datosInversorF: null,
      // Historico
      tablaHistoricoDolar: [],
      columnasHistoricoDolar: [
        {
          prop: 'Fecha',
          name: 'Año - Mes',
          sortable: true,
          pin: 'colPinStart',
          autoSize: true,
          cellTemplate: (createElement, props) => {
            return createElement('span', {
            }, props.model[props.prop].format('YYYY-MM'));
          }
        },
        {
          prop: 'dolar',
          name: 'Valor Dolar CLP',
          columnType: 'numeric2',
          autoSize: true,
        },
      ],
      tablaHistoricoGen: [],
      columnasHistoricoGen: [
        {
          prop: 'Fecha',
          name: 'Año - Mes',
          sortable: true,
          pin: 'colPinStart',
          autoSize: true,
          cellTemplate: (createElement, props) => {
            return createElement('span', {
            }, props.model[props.prop].format('YYYY-MM'));
          }
        },
        {
          prop: 'prod_est',
          name: 'Generacion Estimada',
          columnType: 'int',
          autoSize: true,
        },
        {
          prop: 'prod_real',
          name: 'Generacion Real',
          columnType: 'int',
          autoSize: true,
        },
        {
          prop: 'prod_dif',
          name: 'Diferencia',
          columnType: 'int',
        },
      ],
      tablaHistoricoIrr: [],
      columnasHistoricoIrr: [
        {
          prop: 'Fecha',
          name: 'Año - Mes',
          sortable: true,
          pin: 'colPinStart',
          cellTemplate: (createElement, props) => {
            return createElement('span', {
            }, props.model[props.prop].format('YYYY-MM'));
          }
        },
        {
          prop: 'rad_est',
          name: 'Radiación Estimada',
          columnType: 'int',
        },
        {
          prop: 'rad_real',
          name: 'Radiación Real',
          columnType: 'int',
        },
        {
          prop: 'rad_dif',
          name: 'Diferencia',
          columnType: 'int',
        },
      ],
      tablaHistoricoPR: [],
      columnasHistoricoPR: [
        {
          prop: 'Fecha',
          name: 'Año - Mes',
          sortable: true,
          pin: 'colPinStart',
          cellTemplate: (createElement, props) => {
            return createElement('span', {
            }, props.model[props.prop].format('YYYY-MM'));
          }
        },
        {
          prop: 'prod_f',
          name: 'Producción (Filtrada)',
          columnType: 'numeric2',
        },
        {
          prop: 'prod_max',
          name: 'Prod. Max. Teorica',
          columnType: 'numeric2',
        },
        {
          prop: 'pr_est',
          name: 'PR Garantizado',
          columnType: 'numeric2',
        },
        {
          prop: 'pr',
          name: 'PR Real',
          columnType: 'numeric2',
        },
        {
          prop: 'pr_multa',
          name: 'Multa Referencial',
          columnType: 'numeric2',
        },
      ],
      tablaHistoricoDisp: [],
      columnasHistoricoDisp: [
        {
          prop: 'Fecha',
          name: 'Año - Mes',
          sortable: true,
          pin: 'colPinStart',
          cellTemplate: (createElement, props) => {
            return createElement('span', {
            }, props.model[props.prop].format('YYYY-MM'));
          }
        },
        {
          prop: 'operacion',
          name: 'Operacion',
          columnType: 'numeric2',
        },
        {
          prop: 'out',
          name: 'Out',
          columnType: 'numeric2',
        },
        {
          prop: 'exclusion',
          name: 'Exclusión',
          columnType: 'numeric2',
        },
        {
          prop: 'disponibilidad',
          name: 'Disp. O&M',
          columnType: 'numeric2',
        },
        {
          prop: 'disp_multa',
          name: 'Multa',
          columnType: 'numeric2',
        },
      ],
      // Eventos de indisponibilidad
      bitacoras: [],
      eventos_indisponibilidad: [],
      evento_indisponibilidad: {
        id: null,
        n_ot: null,
        evento: '',
        fecha_inicio: '',
        fecha_fin: '',
        horas_desconexion: '',
        descripcion: '',
      },
      // Mantemimiento
      correctivo_datos: {
        id: 0,
        n: null,
        tipo: 'Correctivo',
        descripcion: '',
        fecha_incidencia: '',
        fecha_resolucion: '',
        horas_indisponibilidad: 0,
        observaciones: '',
      },
      correctivo_lista: [],
      datos_edicion_correctivo: {},
      preventivo_ejecutado_datos: {
        id: 0,
        n: null,
        descripcion: '',
        fecha_inicio: '',
        fecha_termino: '',
        estado: 'Abierto',
        comentarios: '',
      },
      preventivo_ejecutado_lista: [],
      datos_edicion_ejecutado: {},
      preventivo_planificado_datos: {
        id: 0,
        n: null,
        descripcion: '',
        fecha_inicio: '',
        fecha_termino: '',
        estado: 'Abierto',
        comentarios: '',
      },
      preventivo_planificado_lista: [],
      datos_edicion_planificado: {},
      colores_graficos: {},
      datos_resumidos: {
        pr: 0,
        disp: 0,
        irr_sim: 0,
        irr_real: 0,
        prod_sim: 0,
        prod_real: 0,
        operacion: 0,
        out: 0,
        exclusion: 0,
        prod_max: 0,
        prod_f: 0,
      },
      // Cambios
      // cambios: {
      //   disponibilidad: [],
      //   pr: []
      // },
      cambios_disponibilidad: [],
      cambios_pr: [],
      // Factor de degradacion
      mesesOperacion: 1,
      factorDegradacion: 1,
      pvsol_mes_actual: null
    }
  },

  methods: {
    notifyVue(message, verticalAlign, horizontalAlign, type='info') {
      let type_notification = ["", "info", "success", "warning", "danger"]
      this.$notify({
        message,
        // component: NotificationTemplate,
        icon: "tim-icons icon-bell-55",
        horizontalAlign,
        verticalAlign,
        type,
        timeout: 5000
      });
    },

    calcularAutoconsumoBoleta(){
      this.boleta_autoconsumo = this.total_generacion - parseFloat(this.boleta_inyeccion);
    },

    // retorna falso si no son validos
    validarDatosMantenimiento(tipo, datos, edit=false){
      console.log(tipo);
      console.log(datos);
      let n = datos.n;

      if(tipo=='correctivo'){
        if(
          datos.n == null ||
          datos.descripcion == '' ||
          datos.fecha_incidencia == '' ||
          datos.fecha_resolucion == '' ||
          datos.horas_indisponibilidad == null
        ){
          this.notifyVue('Debes ingresar todos los datos' ,'top', 'center', 'warning');
          return false;
        }

        if(n<=0){
          this.notifyVue('N° debe ser mayor a 0' ,'top', 'center', 'warning');
          return false;
        }

        if(datos.horas_indisponibilidad<=0){
          this.notifyVue('Horas de indisponibilidad debe ser mayor a 0' ,'top', 'center', 'warning');
          return false;
        }

        let repetido = this.correctivo_lista.find(item => (item.n == n) && (item.id != datos.id));
        if(repetido){
          this.notifyVue('N° se encuentra repetido' ,'top', 'center', 'warning');
          return false;
        }
      }
      else if(tipo=='ejecutado'){
        if(
          datos.n == null ||
          datos.descripcion == '' ||
          datos.fecha_inicio == '' ||
          datos.fecha_termino == ''
        ){
          this.notifyVue('Debes ingresar todos los datos' ,'top', 'center', 'warning');
          return false;
        }

        if(n<=0){
          this.notifyVue('N° debe ser mayor a 0' ,'top', 'center', 'warning');
          return false;
        }

        let repetido = this.preventivo_ejecutado_lista.find(item => (item.n == n) && (item.id != datos.id));
        if(repetido){
          this.notifyVue('N° se encuentra repetido' ,'top', 'center', 'warning');
          return false;
        }
      }
      else if(tipo=='planificado'){
        if(
          datos.n == null ||
          datos.descripcion == '' ||
          datos.fecha_inicio == '' ||
          datos.fecha_termino == ''
        ){
          this.notifyVue('Debes ingresar todos los datos' ,'top', 'center', 'warning');
          return false;
        }

        if(n<=0){
          this.notifyVue('N° debe ser mayor a 0' ,'top', 'center', 'warning');
          return false;
        }

        let repetido = this.preventivo_planificado_lista.find(item => (item.n == n) && (item.id != datos.id));
        if(repetido){
          this.notifyVue('N° se encuentra repetido' ,'top', 'center', 'warning');
          return false;
        }
      }

      return true;
    },

    abrirEditarModalMantenimiento(tipo, datos) {
      if(tipo=='correctivo'){
        this.modals.modal_editar_correctivo = true;
        this.datos_edicion_correctivo = {...datos};
      }
      else if(tipo=='ejecutado'){
        this.modals.modal_editar_ejecutado = true;
        this.datos_edicion_ejecutado = {...datos};
      }
      else if(tipo=='planificado'){
        this.modals.modal_editar_planificado = true;
        this.datos_edicion_planificado = {...datos};
      }
    },

    editarDatosMantenimiento(tipo) {
      if(tipo=='correctivo'){
        let dato_reemplazar = {...this.datos_edicion_correctivo};
        
        // Validar datos
        if(!this.validarDatosMantenimiento(tipo, dato_reemplazar)){
          return;
        }

        let nueva_lista = this.correctivo_lista.map(item => {
          if(item.id == dato_reemplazar.id){
            return dato_reemplazar;
          }
          return item;
        })

        this.correctivo_lista = nueva_lista;
        this.modals.modal_editar_correctivo = false;
      }
      else if(tipo=='planificado'){
        let dato_reemplazar = {...this.datos_edicion_planificado};
        
        // Validar datos
        if(!this.validarDatosMantenimiento(tipo, dato_reemplazar)){
          return;
        }

        let nueva_lista = this.preventivo_planificado_lista.map(item => {
          if(item.id == dato_reemplazar.id){
            return dato_reemplazar;
          }
          return item;
        })

        this.preventivo_planificado_lista = nueva_lista;
        this.modals.modal_editar_planificado = false
      }
      else if(tipo=='ejecutado'){
        let dato_reemplazar = {...this.datos_edicion_ejecutado};
        
        // Validar datos
        if(!this.validarDatosMantenimiento(tipo, dato_reemplazar)){
          return;
        }

        let nueva_lista = this.preventivo_ejecutado_lista.map(item => {
          if(item.id == dato_reemplazar.id){
            return dato_reemplazar;
          }
          return item;
        })

        this.preventivo_ejecutado_lista = nueva_lista;
        this.modals.modal_editar_ejecutado = false
      } 
    },

    agregarDatosMantenimiento(tipo){

      if(tipo=='correctivo'){
        if(!this.validarDatosMantenimiento(tipo, this.correctivo_datos)){
          return;
        }
        // let n = this.correctivo_datos.n;
        // if(
        //   this.correctivo_datos.n == null ||
        //   this.correctivo_datos.descripcion == '' ||
        //   this.correctivo_datos.fecha_incidencia == '' ||
        //   this.correctivo_datos.fecha_resolucion == '' ||
        //   this.correctivo_datos.horas_indisponibilidad == null
        // ){
        //   this.notifyVue('Debes ingresar todos los datos' ,'top', 'center', 'warning');
        //   return;
        // }

        // if(n<=0){
        //   this.notifyVue('N° debe ser mayor a 0' ,'top', 'center', 'warning');
        //   return;
        // }

        // let repetido = this.correctivo_lista.find(item => item.n == n);
        // if(repetido){
        //   this.notifyVue('N° se encuentra repetido' ,'top', 'center', 'warning');
        //   return;
        // }

        if(this.correctivo_datos.observaciones == ''){
          this.correctivo_datos.observaciones = '--';
        }

        this.correctivo_lista = [
          ...this.correctivo_lista, 
          this.correctivo_datos
        ];
        
        this.correctivo_datos = {
          id: this.correctivo_datos.id + 1,
          n: null,
          tipo: 'Correctivo',
          descripcion: '',
          fecha_incidencia: '',
          fecha_resolucion: '',
          horas_indisponibilidad: null,
          observaciones: '',
        };
      }
      else if(tipo=='planificado'){
        if(!this.validarDatosMantenimiento(tipo, this.preventivo_planificado_datos)){
          return;
        }

        // let n = this.preventivo_planificado_datos.n;
        // if(
        //   this.preventivo_planificado_datos.n == null ||
        //   this.preventivo_planificado_datos.descripcion == '' ||
        //   this.preventivo_planificado_datos.fecha_inicio == '' ||
        //   this.preventivo_planificado_datos.fecha_termino == ''
        // ){
        //   this.notifyVue('Debes ingresar todos los datos' ,'top', 'center', 'warning');
        //   return;
        // }

        // if(n<=0){
        //   this.notifyVue('N° debe ser mayor a 0' ,'top', 'center', 'warning');
        //   return;
        // }

        // let repetido = this.preventivo_planificado_lista.find(item => item.n == n);
        // if(repetido){
        //   this.notifyVue('N° se encuentra repetido' ,'top', 'center', 'warning');
        //   return;
        // }

        if(this.preventivo_planificado_datos.comentarios == ''){
          this.preventivo_planificado_datos.comentarios = '--';
        }

        this.preventivo_planificado_lista = [
          ...this.preventivo_planificado_lista,
          this.preventivo_planificado_datos
        ];

        this.preventivo_planificado_datos = {
          id: this.preventivo_planificado_datos.id + 1,
          n: null,
          descripcion: '',
          fecha_inicio: '',
          fecha_termino: '',
          estado: 'Abierto',
          comentarios: '',
        };
      

      }
      else if(tipo=='ejecutado'){
        if(!this.validarDatosMantenimiento(tipo, this.preventivo_ejecutado_datos)){
          return;
        }

        // let n = this.preventivo_ejecutado_datos.n;
        // if(
        //   this.preventivo_ejecutado_datos.n == null ||
        //   this.preventivo_ejecutado_datos.descripcion == '' ||
        //   this.preventivo_ejecutado_datos.fecha_inicio == '' ||
        //   this.preventivo_ejecutado_datos.fecha_termino == ''
        // ){
        //   this.notifyVue('Debes ingresar todos los datos' ,'top', 'center', 'warning');
        //   return;
        // }

        // if(n<=0){
        //   this.notifyVue('N° debe ser mayor a 0' ,'top', 'center', 'warning');
        //   return;
        // }

        // let repetido = this.preventivo_ejecutado_lista.find(item => item.n == n);
        // if(repetido){
        //   this.notifyVue('N° se encuentra repetido' ,'top', 'center', 'warning');
        //   return;
        // }

        if(this.preventivo_ejecutado_datos.comentarios == ''){
          this.preventivo_ejecutado_datos.comentarios = '--';
        }

        this.preventivo_ejecutado_lista = [
          ...this.preventivo_ejecutado_lista,
          this.preventivo_ejecutado_datos
        ];

        this.preventivo_ejecutado_datos = {
          id: this.preventivo_ejecutado_datos.id + 1, 
          n: null,
          descripcion: '',
          fecha_inicio: '',
          fecha_termino: '',
          estado: 'Abierto',
          comentarios: '',
        };
      }
    },

    eliminarDatosMantenimiento(tipo, n){
      if(tipo=='correctivo'){
        this.correctivo_lista = this.correctivo_lista
        .filter(item => {
          return item.n != n;
        });
        
      }
      else if(tipo=='planificado'){
        this.preventivo_planificado_lista = this.preventivo_planificado_lista
        .filter(item => {
          return item.n != n;
        });

      }
      else if(tipo=='ejecutado'){
        this.preventivo_ejecutado_lista = this.preventivo_ejecutado_lista
        .filter(item => {
          return item.n != n;
        });
      }
    },

    setCurrentView(value){
      this.current_view = value;
    },

    reducirDia(){
      this.selectedVisualizador -= 1;
      this.seleccionarDiaVisualizador()
    },

    aumentarDia(){
      if(this.selectedVisualizador == this.listadoDias.at(-1)){
        return;
      }

      this.selectedVisualizador += 1;
      this.seleccionarDiaVisualizador()
    },

    seleccionarDiaVisualizador(){
      let dia = this.selectedVisualizador;
      let factor = this.factor_comun;

      console.log(`factor comun ${factor}`);
      let dias = this.generationData.filter(item => {
        if(item['Fecha'].date() == dia){
          return item;
        }
      });

      let labels = dias.map(item => {
        return item['Fecha'].format('HH:mm');
      })

      let dataNFGen = dias.map(item => {
        return item['Total']/(factor*1000);
      });

      // let dataNFIrr = this.irradiationData.filter(item => {
      //   if(item['Fecha'].date() == dia){
      //     return item;
      //   }
      // }).map(item => {
      //   return item['Global']/10000
      // })

      let dataNFIrr = this.irradiationInc.filter(item => item['Fecha'].date() == dia)
      .map(item => {
        // viene en kWh/m2
        return item['Global']*1000/factor;
      })


      let diasF = this.tablaGeneracionPR.filter(item => item['Fecha'].date() == dia);

      let dataFGen = diasF.map(item => {
        // item['Total'] se encuentra en Wh
        return item['Total']/(factor);
      }); 

      
      let dataFIrr = this.tablaIrradiacionPR.filter(item => item['Fecha'].date() == dia)
      .map(item => {
        // viene en kWh/m2
        return item['Total']*1000/factor;
      })

      this.visualizadorNoFiltradoData = {
        labels: labels,
        datasets: [
          {
            label: "Potencia",
            yAxisID: 'A',
            type: 'line',
            fill: false,
            borderColor: '#4472C4',
            borderWidth: 2,
            pointRadius: 0,
            data: dataNFGen,
          },
          {
            label: "Radiación",
            yAxisID: 'B',
            type: 'line',
            fill: false,
            borderColor: '#ED7D31',
            borderWidth: 2,
            pointRadius: 0,
            data: dataNFIrr,
          },
        ]
      },

      this.visualizadorFiltradoData = {
        labels: labels,
        datasets: [
          {
            label: "Generación",
            yAxisID: 'A',
            type: 'line',
            fill: false,
            borderColor: '#4472C4',
            borderWidth: 2,
            pointRadius: 0,
            data: dataFGen,
          },
          {
            label: "Radiación",
            yAxisID: 'B',
            type: 'line',
            fill: false,
            borderColor: '#ED7D31',
            borderWidth: 2,
            pointRadius: 0,
            data: dataFIrr,
          },
        ]
      },

      this.seleccionarDiaVisualizadorInvesoresSF();
      this.seleccionarDiaVisualizadorInvesores();
    },

    seleccionarDiaGeneracion(){
      if(this.selectedDia==0){
        return [];
      }

      let indexes = this.generationData.filter((item, idx) => {
        return item['Fecha'].date()==this.selectedDia;
      });

      let min_idx = indexes[0]['idx'];
      let max_idx = indexes[indexes.length-1]['idx'];
      this.min_idx = min_idx;
      this.max_idx = max_idx;

      this.dataPorDia = this.generationData.slice(min_idx, max_idx + 1);
      this.dataIrradiacionPorDia = this.irradiationPmgdData.slice(min_idx, max_idx + 1);
      this.dataTemperaturaPorDia = this.temperaturaData.slice(min_idx, max_idx + 1);
      
      if(this.planta.tipo == 'HUAWEI'){
        this.dataEstadoPorDia = this.estadoInversoresData.slice(min_idx, max_idx + 1);
      }
      
      this.dataDisponibilidadPorDia = this.tablaDisponibilidad.slice(min_idx, max_idx + 1);
      this.dataDisponibilidadRealPorDia = this.tablaDisponibilidad.slice(min_idx, max_idx + 1);
      this.dataPRGenPorDia = this.tablaGeneracionPR.slice(min_idx, max_idx + 1);
      this.dataPRIrrPorDia = this.tablaIrradiacionPR.slice(min_idx, max_idx + 1);
      this.dataPRGeneralPorDia = this.dataPRGeneral.slice(min_idx, max_idx + 1);

      if(this.tiene_smart_meter){
        this.dataSmartPorDia = this.smartData.slice(min_idx, max_idx + 1);
      }
    },

    perc2color(perc, min, max) {
      let base = (max - min);

      if (base == 0) { perc = 100; }
      else {
          perc = (perc - min) / base * 100; 
      }
      let r, g, b = 0;
      if (perc < 50) {
          r = 255;
          g = Math.round(5.1 * perc);
      }
      else {
          g = 255;
          r = Math.round(510 - 5.10 * perc);
      }
      let h = r * 0x10000 + g * 0x100 + b * 0x1;
      return '#' + ('000000' + h.toString(16)).slice(-6);
    },

    establecerColoresGraficos(){
      let colores = {};
      let inversores = this.planta.inversores;
      inversores.forEach((inversor, idx) => {
        colores[inversor.codigo] = this.perc2color(idx, 0, inversores.length);
      });
      
      this.colores_graficos = colores;
    },

    actualizarGraficosPorInversor(){
      this.seleccionarDiaVisualizadorInvesoresSF();
      this.seleccionarDiaVisualizadorInvesores();
    },

    seleccionarDiaVisualizadorInvesoresSF(){
      // factor = cantidad de muestras por hora
      let factor = this.factor_comun;
      if(this.selectedVisualizador == 0){
        return;
      }
      
      let dia = this.selectedVisualizador;
      let inversores = this.selectedInversores.sort();

      let diasF = this.generationData.filter(item => item['Fecha'].date() == dia);

      let labels = diasF.map(item => {
        return item['Fecha'].format('HH:mm');
      })

      let me = this;

      // Generar un nuevo dataset por cada inversor
      let datos = inversores.map((inversor) => {
        // Buscar la data del inversor del dia correspondiente
        let new_data = diasF.map(item => {
          // item[inversor] viene en Wh
          return item[inversor]/(factor*1000)
        });

        // Generar un nuevo dataset por cada inversor
        return {
          label: inversor,
          yAxisID: 'A',
          type: 'line',
          fill: false,
          borderColor: this.colores_graficos[inversor],
          borderWidth: 2,
          pointRadius: 0,
          data: new_data,
        };
      });


      let dataFIrr = this.irradiationInc.filter(item => item['Fecha'].date() == dia)
      .map(item => {
        return item['Global']*1000/factor;
      });

      this.visualizadorInversoresNoFiltradoData = {
        labels: labels,
        datasets: [
          ...datos,
          {
            label: "Radiación",
            yAxisID: 'B',
            type: 'line',
            fill: false,
            borderColor: '#ED7D31',
            borderWidth: 2,
            pointRadius: 0,
            data: dataFIrr,
          },
        ]
      };
    },

    seleccionarDiaVisualizadorInvesores(){
      let factor = this.factor_comun;
      if(this.selectedVisualizador == 0){
        return;
      }
      
      let dia = this.selectedVisualizador;
      let inversores = this.selectedInversores.sort();

      let diasF = this.tablaGeneracionPR.filter(item => item['Fecha'].date() == dia);

      let labels = diasF.map(item => {
        return item['Fecha'].format('HH:mm');
      })

      let me = this;

      let datos = inversores.map((inversor) => {
        let new_data = diasF.map(item => {
          // item[inversor] viene en Wh
          return item[inversor]/(factor*1000)
        });

        return {
          label: inversor,
          yAxisID: 'A',
          type: 'line',
          fill: false,
          borderColor: this.colores_graficos[inversor],
          borderWidth: 2,
          pointRadius: 0,
          data: new_data,
        };
      });

      let dataFIrr = this.tablaIrradiacionPR.filter(item => item['Fecha'].date() == dia)
      .map(item => {
        return item['Total']*1000/factor;
      });

      this.visualizadorInversoresFiltradoData = {
        labels: labels,
        datasets: [
          ...datos,
          {
            label: "Radiación",
            yAxisID: 'B',
            type: 'line',
            fill: false,
            borderColor: '#ED7D31',
            borderWidth: 2,
            pointRadius: 0,
            data: dataFIrr,
          },
        ]
      };
    },

    editRangeCell(event){
      let idxs = [];
      // event.preventDefault();

      for(let fila_cambiada in event.detail.data){
        let columnas_modificadas = Object.keys(event.detail.data[fila_cambiada]);
        idxs = idxs.concat(event.detail.models[fila_cambiada].idx)

        // Es edicion de columna de Data
        if(columnas_modificadas.indexOf('data')>=0){
          let val = event.detail.data[fila_cambiada]['data']
          
          for (let key in event.detail.models[fila_cambiada]) {
            if(key!='Fecha' && key!='idx' && key!='comentario'){
              event.detail.models[fila_cambiada][key] = val;
            }
          }
        }
        else if(columnas_modificadas.indexOf('comentario')>=0){
          return;
        }
        else {
          for (let key in event.detail.data[fila_cambiada]) {
            if(key!='Fecha' && key!='idx' && key!='data' && key!='comentario'){
              let val = event.detail.data[fila_cambiada][key];
              event.detail.models[fila_cambiada][key] = val;
            }
          }
          
        }
      }

      this.actualizarFilasPR(idxs);
      this.tablaDisponibilidadCambiada();
    },

    editSingleCell(event){
      // event.preventDefault();
      let col = event.detail.prop;
      let val = event.detail.val;
      // Es edicion de columna de Data
      if(col=='data'){
        for (let key in event.detail.model) {
          if(key!='Fecha' && key!='idx' && key!='comentario'){
            event.detail.model[key] = val;
          }
        }
      }
    },

    editTablaDisponibilidad(event){
      console.log('After edit');
      let idxs = [];

      // Si es cambio en rango
      if ('data' in event.detail){
        let idxs_range = [];
        for(let filas in event.detail.models){
          // console.log(event.detail.models[filas])
          idxs_range = idxs_range.concat(event.detail.models[filas].idx);
        }

        this.agregarCambioDisponibilidad(idxs_range)
        return;
      }
      // Si es cambio en una sola fila
      else {
        idxs = [event.detail.model.idx]
        
        this.agregarCambioDisponibilidad(idxs);
        if(event.detail.prop == 'comentario'){
          return;
        }

        idxs = [event.detail.model.idx]
      }

      // Llamar actualizacion de tablas de PR
      this.actualizarFilasPR(idxs);

      // Llamar actualizacion de tablas siguientes
      // de disponibilidad
      this.tablaDisponibilidadCambiada();
    },

    editTablaDisponibilidadReal(event){
      console.log('After edit');
      let idxs = [];

      // Si es cambio en rango
      if ('data' in event.detail){
        let idxs_range = [];
        for(let filas in event.detail.models){
          // console.log(event.detail.models[filas])
          idxs_range = idxs_range.concat(event.detail.models[filas].idx);
        }

        this.agregarCambioDisponibilidad(idxs_range)
        return;
      }
      // Si es cambio en una sola fila
      else {
        idxs = [event.detail.model.idx]
        
        this.agregarCambioDisponibilidad(idxs);
        if(event.detail.prop == 'comentario'){
          return;
        }

        idxs = [event.detail.model.idx]
      }

      // Llamar actualizacion de tablas de PR
      this.actualizarFilasPR(idxs);

      // Llamar actualizacion de tablas siguientes
      // de disponibilidad
      this.tablaDisponibilidadCambiada();
    },

    actualizarFilasPR(idxs){
      let me = this;
      let generationData = this.generationData;
      let irr = this.irradiationPmgdData;
      let disp = this.tablaDisponibilidad;
      let pr_table = this.dataPRGeneral;

      let eficiencia = this.planta.inversores.reduce((acc, curr) => {
        acc[curr.codigo] = curr.efic_area_total;
        return acc;
      }, {});

      let eficiencia_sum = this.planta.inversores.reduce((acc, curr) => {
        return acc + parseFloat(curr.efic_area_total);
      }, 0);

      // console.log('actualizar PR')
      idxs.map(idx => {
        // let idx = row.idx;
        let total = 0;
        let total_irr = 0;
        let fila = generationData[idx];
        let filaPRGeneral = me.dataPRGeneral[idx];
        let filaPR = me.tablaGeneracionPR[idx];
        let filaPRIrr = me.tablaIrradiacionPR[idx];
        let row_gen = disp[idx];


        for (let key in row_gen) {
          if(key!='Fecha' && key!='Total' && key!='Global' && key!='idx' && key!='data' && key!='comentario'){
            let val_irr = irr[idx][key];
            let val_gen = fila[key];
            
            if(disp[idx][key]=='operacion'){  
              if(val_gen>0 && val_irr>0){
                filaPRGeneral[key] = 'ok';
                filaPR[key] = val_gen;
                filaPRIrr[key] = val_irr;
                total += val_gen;
                total_irr += (eficiencia[key]*val_irr)
              }
              else if(val_gen>0 && val_irr<=0) {
                filaPRGeneral[key] = 'sin_g';
                filaPR[key] = null;
                filaPRIrr[key] = null;
              }
              else if(val_gen<=0 && val_irr>0) {
                filaPRGeneral[key] = 'sin_r';
                filaPR[key] = null;
                filaPRIrr[key] = null;
              }
              else {
                filaPRGeneral[key] = 'ok';
                filaPR[key] = null;
                filaPRIrr[key] = null;
              }
            }
            else{
              filaPRGeneral[key] = 'disp';
              filaPR[key] = null;
              filaPRIrr[key] = null;
            }
          }
        }
        filaPR['Total'] = (total/(1000));
        filaPRIrr['Total'] = (total_irr/(eficiencia_sum));
      });

      this.calcularTotalFiltrado()
      
    },

    editRangeCellPR(event){
      let disp = this.tablaDisponibilidad;
      let gen = this.generationData;
      let irr = this.irradiationPmgdData;
      let me = this;

      let eficiencia = this.planta.inversores.reduce((acc, curr) => {
        acc[curr.codigo] = curr.efic_area_total;
        return acc;
      }, {});

      let eficiencia_sum = this.planta.inversores.reduce((acc, curr) => {
        return acc + parseFloat(curr.efic_area_total);
      }, 0);


      for(let filas in event.detail.data){
        let columnas = Object.keys(event.detail.data[filas]);
        let idx = event.detail.models[filas].idx;
        let filaPR = me.tablaGeneracionPR[idx];
        let filaPRIrr = me.tablaIrradiacionPR[idx];
        let total_gen = 0;
        let total_irr = 0;

        // Es edicion de columna de Data
        if(columnas.indexOf('data')>=0){
          let val = event.detail.data[filas]['data']
          
          for (let key in event.detail.models[filas]) {
            if(key!='Fecha' && key!='idx' && key!='data' && key!='Total' && key!='comentario'){
              let val_irr = irr[idx][key];
              let val_gen = gen[idx][key];

              if(val=='ok'){
                if(disp[idx][key]=='operacion'){
                  if(val_gen>0 && val_irr>0){
                    event.detail.models[filas][key] = 'ok';
                    filaPR[key] = val_gen;
                    filaPRIrr[key] = val_irr;
                    total_gen += val_gen;
                    total_irr += (eficiencia[key]*val_irr)
                  }
                  else if(val_gen>0 && val_irr<=0) {
                    event.detail.models[filas][key] = 'sin_g';
                    filaPR[key] = null;
                    filaPRIrr[key] = null;
                  }
                  else if(val_gen<=0 && val_irr>0) {
                    event.detail.models[filas][key] = 'sin_r';
                    filaPR[key] = null;
                    filaPRIrr[key] = null;
                  }
                  else {
                    event.detail.models[filas][key] = 'ok';
                    filaPR[key] = null;
                    filaPRIrr[key] = null;
                  }
                }
                // event.detail.models[filas][key] = this.generationData[idx][key];
                // this.tablaIrradiacionPR[idx][key] = this.irradiationData[idx][key];

                // total_gen += this.generationData[idx][key];
                // total_irr += (eficiencia[key]*this.irradiationData[idx][key]);
              }
              else {
                event.detail.models[filas][key] = 'incoherencia';
                filaPR[key] = null;
                filaPRIrr[key] = null;
                // this.tablaIrradiacionPR[idx][key] = null;
              }
            }
          }

          me.tablaGeneracionPR[idx]['Total'] = total_gen/1000;
          me.tablaIrradiacionPR[idx]['Total'] = (total_irr/(eficiencia_sum));
        }
        else if(columnas.indexOf('comentario')>=0){}
        else {
          for (let key in event.detail.models[filas]) {
            if(key!='Fecha' && key!='idx' && key!='data' && key!='Total' && key!='comentario'){
              let val = event.detail.models[filas][key];
              if (key in event.detail.data[filas]){
                val = event.detail.data[filas][key];
              }

              let val_irr = irr[idx][key];
              let val_gen = gen[idx][key];

              if(val=='ok'){
                if(disp[idx][key]=='operacion'){
                  if(val_gen>0 && val_irr>0){
                    event.detail.models[filas][key] = 'ok';
                    filaPR[key] = val_gen;
                    filaPRIrr[key] = val_irr;
                    total_gen += val_gen;
                    total_irr += (eficiencia[key]*val_irr)
                  }
                  else if(val_gen>0 && val_irr<=0) {
                    event.detail.models[filas][key] = 'sin_g';
                    filaPR[key] = null;
                    filaPRIrr[key] = null;
                  }
                  else if(val_gen<=0 && val_irr>0) {
                    event.detail.models[filas][key] = 'sin_r';
                    filaPR[key] = null;
                    filaPRIrr[key] = null;
                  }
                  else {
                    event.detail.models[filas][key] = 'ok';
                    filaPR[key] = null;
                    filaPRIrr[key] = null;
                  }
                }
              }
              else {
                event.detail.models[filas][key] = 'incoherencia';
                filaPR[key] = null;
                filaPRIrr[key] = null;
              }
            }
          }

          me.tablaGeneracionPR[idx]['Total'] = total_gen/1000;
          me.tablaIrradiacionPR[idx]['Total'] = (total_irr/(eficiencia_sum))
        }
      }
    },

    editSingleCellPR(event){
      // event.preventDefault();
      console.log(event);
      let col = event.detail.prop;
      let val = event.detail.val;
      let idx = event.detail.model.idx;
      let total_gen = 0, total_irr = 0;

      let disp = this.tablaDisponibilidad;
      let gen = this.generationData;
      let irr = this.irradiationPmgdData;

      let filaPR = this.tablaGeneracionPR[idx];
      let filaPRIrr = this.tablaIrradiacionPR[idx];

      let eficiencia = this.planta.inversores.reduce((acc, curr) => {
        acc[curr.codigo] = parseFloat(curr.efic_area_total);
        return acc;
      }, {});

      let eficiencia_sum = this.planta.inversores.reduce((acc, curr) => {
        return acc + parseFloat(curr.efic_area_total);
      }, 0);

      // Es edicion de columna de Data
      if(col=='data'){
        for (let key in event.detail.model) {
          if(key!='Fecha' && key!='idx' && key!='data' && key!='Total' && key!='comentario'){
            let val_irr = irr[idx][key];
            let val_gen = gen[idx][key];

            if(val=='ok'){
              if(disp[idx][key]=='operacion'){
                if(val_gen>0 && val_irr>0){
                  event.detail.model[key] = 'ok';
                  filaPR[key] = val_gen;
                  filaPRIrr[key] = val_irr;
                  total_gen += val_gen;
                  total_irr += (eficiencia[key]*val_irr)
                }
                else if(val_gen>0 && val_irr<=0) {
                  event.detail.model[key] = 'sin_g';
                  filaPR[key] = null;
                  filaPRIrr[key] = null;
                }
                else if(val_gen<=0 && val_irr>0) {
                  event.detail.model[key] = 'sin_r';
                  filaPR[key] = null;
                  filaPRIrr[key] = null;
                }
                else {
                  event.detail.model[key] = 'ok';
                  filaPR[key] = null;
                  filaPRIrr[key] = null;
                }
              }
            }
            else {
              event.detail.model[key] = 'incoherencia';
              filaPR[key] = null;
              filaPRIrr[key] = null;
            }
          }
        }

        this.tablaGeneracionPR[idx]['Total'] = total_gen/1000;
        this.tablaIrradiacionPR[idx]['Total'] = (total_irr/(eficiencia_sum))
      }
      else if(col=='comentario'){
        // event.preventDefault();
        return;
      }
      else {
        for (let key in event.detail.model) {
          if(key!='Fecha' && key!='idx' && key!='data' && key!='Total' && key!='comentario'){
            let val2 = key==col ? val : event.detail.model[key];
            let val_irr = irr[idx][key];
            let val_gen = gen[idx][key];

            if(val2=='ok'){
              filaPR[key] = val_gen;
              filaPRIrr[key] = val_irr;
              total_gen += val_gen;
              total_irr += (eficiencia[key]*val_irr)
            }
            else {
              filaPR[key] = null;
              filaPRIrr[key] = null;
            }
          }
        }

        this.tablaGeneracionPR[idx]['Total'] = total_gen/1000;
        this.tablaIrradiacionPR[idx]['Total'] = (total_irr/(eficiencia_sum))
      }
    },

    afterEditTablaPR(event){
      console.log('PR: After edit');
      // console.log(event);
      // this.updateTablaPR();
      let idxs = [];

      // Si es cambio en rango
      if ('data' in event.detail){
        for(let filas in event.detail.models){
          idxs = idxs.concat(event.detail.models[filas].idx);
        }

        this.agregarCambioPR(idxs)
      }
      // Si es cambio en una sola fila
      else {
        idxs = [event.detail.model.idx]
        this.agregarCambioPR(idxs);
        if(event.detail.prop == 'comentario'){
          return;
        }
      }

      this.calcularTotalFiltrado()

      // Llamar actualizacion de tablas siguientes
      // this.tablaDisponibilidadCambiada();
    },

    async getFactorComun(){
      let payload = {
        planta_id: this.planta.id,
        sensor_id: null,
      }

      console.log('Pidiendo factor comun');
      try {
        let res = await this.$http({
          method: 'POST',
          url: 'api/factor',
          data: payload
        });

        this.factor_comun = res.data.factor;

      } catch (error) {
        this.notifyVue('Hubo un problema pedir factor común' ,'top', 'center', 'danger');
        console.log(error);
      }
    },

    async getBitacoras(){
      let payload = {
        fecha_inicio: this.reporte.fecha_inicio,
        fecha_fin: this.reporte.fecha_fin,
        es_dashboard: true
      }

      console.log('Pidiendo bitácoras');
      try {
        let res = await this.$http({
          method: 'POST',
          url: `api/plantas/${this.planta.id}/bitacoras`,
          data: payload
        });

        this.bitacoras = res.data;

      } catch (error) {
        this.notifyVue(
          'Hubo un problema obteniendo bitácoras', 
          'top', 'center', 'danger'
        );
        console.log(error);
      }
    },

    sincronizarBitacorasConListadoEventos(){
      console.log('Sincronizando bitacoras');
      const bitacoras = this.bitacoras;
      const eventos_indisponibilidad = this.eventos_indisponibilidad;
      // const listado_correctivo = this.correctivo_lista;

      // // Eliminar items que pertenecen a bitacoras

      // darle formato a las bitacoras para coincidir con listado
      const bitacoras_formateadas = bitacoras.map(bitacora => {
        return {
          id: bitacora.id,
          n_ot: bitacora.n_ot || '--',
          evento: bitacora.evento,
          descripcion: bitacora.descripcion,
          fecha_incidencia: bitacora.fecha_inicio,
          fecha_resolucion: bitacora.fecha_fin,
          horas_indisponibilidad: bitacora.horas_desconexion,
        }
      })

      // // Agregar bitacoras al inicio del listado

      // this.correctivo_lista = [
      //   ...bitacoras_formateadas,
      //   ...listado_correctivo
      // ];
      this.eventos_indisponibilidad = bitacoras_formateadas;


    },

    async getGenerationData(){
      let fecha_inicio = this.fecha_inicio.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
      let fecha_fin = this.fecha_fin.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
      
      let payload = {
        planta_id: this.planta.id,
        sensor_id: null,
        fecha_inicio,
        fecha_fin,
      }

      console.log('Pidiendo datos de generación');
      await this.$store.dispatch('report/getGenerationData', payload);
    },


    // async getIrradiationData(){
    //   let fecha_inicio = this.fecha_inicio.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
    //   let fecha_fin = this.fecha_fin.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
    //   let mes_actual = this.fecha_inicio.month()+1;
    //   let columnas = this.columnas;
    //   // TODO: corregir esto
    //   let sensor_id = this.reporte.sensor_seleccionado;
    //   let poa_sensor = this.planta.poa[sensor_id];

    //   let target_planta = this.planta.id;
      

    //   let poa = poa_sensor.filter(item => {
    //     if(item.mes == mes_actual){
    //       return item;
    //     }
    //   })[0];

    //   delete poa.mes;
      
    //   let payload = {
    //     fecha_inicio,
    //     fecha_fin,
    //     sensor_id,
    //     target_planta,
    //     poa,
    //   }

    //   console.log('Pidiendo datos de irradiacion');
    //   await this.$store.dispatch('report/getIrradiationData', payload);
    // },

    async getMit(){
      console.log('Pidiendo valor MIT');
      const factor_comun = this.factor_comun;
      const res = await Vue.prototype.$http({
        method: 'GET',
        url: `api/plantas/${this.planta_id}/mit`,
      });

      // this.valor_mit = res.data.mit * factor_comun;
      this.valor_mit = res.data.mit;
    },

    async getIrradiationPmgdData(){
      let fecha_inicio = this.fecha_inicio.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
      let fecha_fin = this.fecha_fin.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
      let mes_actual = this.fecha_inicio.month()+1;
      let columnas = this.columnas;
      // TODO: incluir POA de los sensores
      // let sensor_id = this.reporte.sensor_seleccionado;
      // let poa_sensor = this.planta.poa[sensor_id];

      const portafolio = this.planta.portafolio;
      let planta_id = this.planta.id;
      let periodo = this.planta.periodo;
      let pmgd_trasera = this.planta.pmgd_trasera
      

      let poa = this.planta.poa_pmgd.filter(item => {
        if(item.mes == mes_actual){
          return item;
        }
      })[0];

      let coef_temperatura = {}
      // let poa = {}

      this.planta.inversores.forEach(inversor => {
        coef_temperatura[inversor.codigo] = parseFloat(inversor.coef_temperatura);
        poa[inversor.codigo] = parseFloat(poa[inversor.codigo]);
      })

      // delete poa.mes;
      
      let payload = {
        fecha_inicio,
        fecha_fin,
        planta_id,
        poa,
        pmgd_trasera,
        coef_temperatura,
        periodo,
        portafolio
      }

      console.log('Pidiendo datos de irradiacion PMGD');
      await this.$store.dispatch('report/getIrradiationPmgdData', payload);
    },

    async getTemperaturaData(){
      let fecha_inicio = this.fecha_inicio.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
      let fecha_fin = this.fecha_fin.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
      
      let payload = {
        planta_id: this.planta.id,
        fecha_inicio,
        fecha_fin,
      }

      console.log('Pidiendo datos de temperatura');
      await this.$store.dispatch('report/getTemperaturaData', payload);
    },

    async getEstadoInversoresData(){
      let fecha_inicio = this.fecha_inicio.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
      let fecha_fin = this.fecha_fin.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
      
      let payload = {
        planta_id: this.planta.id,
        fecha_inicio,
        fecha_fin,
      }

      console.log('Pidiendo datos de estados');
      await this.$store.dispatch('report/getEstadoInversoresData', payload);
    },

    async getInitialData(){
      let p1 = this.getData();
      let p2 = this.getIrradiationData();

      await p1;
      await p2;

      console.log('terminado');
    },

    calcularIrrBajoMit(){
      console.log('Calcular irradiacion bajo MIT');
      let valor_mit = this.valor_mit;

      this.tabla_mit = this.irradiationPmgdData.map(fila => {
        if(fila['Global'] > valor_mit){
          return 'operacion';
        }
        return 'bajo';
      });
    },

    calcularIrrRealBajoMit(){
      console.log('Calcular irradiacion bajo MIT Real');
      let valor_mit = this.valor_mit_real;

      this.tabla_mit_real = this.irradiationPmgdData.map(fila => {
        if(fila['Global'] > valor_mit){
          return 'operacion';
        }
        return 'bajo';
      });
    },

    async calcularIrradiacionInc(){
      console.log('Calcular irradiacion Inc');
      let efic_total = this.planta.inversores.reduce((acc, curr) => {
        acc[curr.codigo] = parseFloat(curr.efic_area_total);
        return acc;
      }, {});

      let eficiencia_sum = this.planta.inversores.reduce((acc, curr) => {
        return acc + parseFloat(curr.efic_area_total);
      }, 0);

      let inversores = this.planta.inversores.map(inv => inv.codigo);

      try {
        let irr_inc = this.irradiationPmgdData.map(fila => {
          let global_temp = 0;
          inversores.forEach(inversor => {
            global_temp += fila[inversor]*efic_total[inversor]
          });

          return {
            Global: global_temp/eficiencia_sum,
            Fecha: fila.Fecha,
            idx: fila.idx
          }
        });

        console.log('Irradiacion Inc: calculada');
        this.irradiationInc = irr_inc;

        // Calculo de irradiacion inc con llamada a API
        // let res = await backend_db_instance({
        //   method: 'POST',
        //   url: 'api/irradiacion/inc',
        //   data: {
        //     data: this.irradiationData,
        //     eficiencia: efic_total,
        //   }
        // })

        // console.log('Irradiacion Inc: calculada');
        // this.irradiationInc = res.data;

      } catch (error) {
        console.log(error);
      }
    },

    async calcularResumenMensual(){
      console.log('Resumen Mensual: calculando');
      let resumen = {};
      let dict_inicial = {Total: 0};
      let inversores = this.planta.inversores.map(inversor => {
        dict_inicial[inversor.codigo] = 0;
        return inversor.codigo;
      })
      inversores.push('Total');

      try {
        this.generationData.forEach(item => {
          let fecha = dayjs(item['Fecha']).startOf('day').format();
          if(fecha in resumen){
            resumen[fecha].push(item)
          } else{
            resumen[fecha] = [item]
          }
        });
        let finales = [];

        Object.keys(resumen).forEach(dia => {
          // console.log(dia);
          // reducir arreglo
          let res_dia = resumen[dia].reduce((acc, curr)=> {
            inversores.forEach(key => {
              acc[key] += (curr[key]/1000);
            })
            return acc;
          }, {...dict_inicial, Fecha: dayjs(dia)})

          finales.push(res_dia)
        })

        console.log('Resumen Mensual: obtenido');
        this.dataMensual = finales;
      } catch (error) {
        console.log(error);
      }
    },

    async calcularResumenMensualIrr(){
      console.log('Resumen Mensual Irradiacion: calculando');
      let resumen = {};
      let dict_inicial = {Global: 0};
      let inversores = this.planta.inversores.map(inversor => {
        dict_inicial[inversor.codigo] = 0;
        return inversor.codigo;
      })
      inversores.push('Global');

      try {
        this.irradiationPmgdData.forEach(item => {
          let fecha = dayjs(item['Fecha']).startOf('day').format();
          if(fecha in resumen){
            resumen[fecha].push(item)
          } else{
            resumen[fecha] = [item]
          }
        });
        let finales = [];

        Object.keys(resumen).forEach(dia => {
          let res_dia = resumen[dia].reduce((acc, curr)=> {
            inversores.forEach(key => {
              acc[key] += (curr[key]/1000);
            })
            return acc;
          }, {...dict_inicial, Fecha: dayjs(dia)})

          finales.push(res_dia);
        })
        console.log('Resumen Mensual Irr: obtenido');
        this.dataMensualIrr = finales;
      } catch (error) {
        console.log(error);
      }
    },

    async calcularResumenMensualInc(){
      console.log('Resumen Mensual Inc: calculando');
      let resumen = {};

      try {
        this.irradiationInc.forEach(item => {
          let fecha = dayjs(item['Fecha']).startOf('day').format();
          if(fecha in resumen){
            resumen[fecha].push(item)
          } else{
            resumen[fecha] = [item]
          }
        });

        let finales = [];
        Object.keys(resumen).forEach(dia => {
          let res_dia = resumen[dia].reduce((acc, curr)=> {
            acc.Global += curr.Global
            return acc;
          }, {Global: 0, Fecha: dayjs(dia)})
          finales.push(res_dia)
        });

        this.irradiationMensualInc = finales;
      } catch (error) {
        console.log(error);
      }
    },

    crearTablaGeneralSinFiltrar(){
      let eficiencia = this.planta.inversores.reduce((acc, curr) => {
        return acc + parseFloat(curr.efic_area_total);
      }, 0);
      let p_peak = this.planta.p_peak;

      let irr = this.irradiationMensualInc;

      this.tablaGeneralSinFiltrar = this.dataMensual.map((item, idx)=> {
        const radiacion = irr[idx]['Global'];
        const gen = item['Total'];
      
        return {
          'fecha_string': item['Fecha'].date(),
          'Fecha': item['Fecha'],
          'Generacion': gen,
          'Radiacion': radiacion,
          'PR': radiacion ? 100*gen/(radiacion*eficiencia) : 0,
          'Rendimiento': gen/p_peak
        }
      });
    },

    crearTablaDisponibilidad(){
      // let irr_inc = this.irradiationInc;
      let tabla_mit = this.tabla_mit;
      let valor = 'bajo';

      let newData = this.generationData.map((fila, idx)=>{
        let newFila = {
          data: 'operacion',
          comentario: '',
          Fecha: fila['Fecha'],
          idx: fila['idx'],
        };

        // let valor = irr_inc[idx]['Global'] > 0 ? 'operacion' : 'bajo';
        valor = tabla_mit[idx];
        for (let key in fila) {
          if(key!='Fecha' && key!='idx'){
            const gen = fila[key];
            if(valor === 'operacion' && (gen === 0 || gen === null)){
              newFila[key] = 'exclusion'
            }
            else{
              newFila[key] = valor;
            }
          }
        }
        return newFila;
      })

      // Si tiene datos guardados
      let reporte = this.reporte;
      if(reporte.datos_dashboard!=null){
        let idxs = [];
        if(reporte.datos_dashboard.disponibilidad.length!=0){
          console.log('Aplicando cambios guardados Disponibilidad')
          let cambios_disponibilidad = reporte.datos_dashboard.disponibilidad;
          cambios_disponibilidad.forEach(fila => {
            fila['Fecha'] = dayjs(fila['Fecha']);
            newData[fila.idx] = {...fila};
            idxs.push(fila.idx);
          });

          this.agregarCambioDisponibilidad(idxs)
        }
      }

      this.tablaDisponibilidad = newData;
    },

    crearTablaDisponibilidadReal(){
      // let irr_inc = this.irradiationInc;
      let tabla_mit = this.tabla_mit_real;
      let valor = 'bajo';

      let newData = this.generationData.map((fila, idx)=>{
        let newFila = {
          data: 'operacion',
          comentario: '',
          Fecha: fila['Fecha'],
          idx: fila['idx'],
        };

        // let valor = irr_inc[idx]['Global'] > 0 ? 'operacion' : 'bajo';
        valor = tabla_mit[idx];
        for (let key in fila) {
          if(key!='Fecha' && key!='idx'){
            const gen = fila[key];
            if(valor === 'operacion' && (gen === 0 || gen === null)){
              newFila[key] = 'exclusion'
            }
            else{
              newFila[key] = valor;
            }
          }
        }
        return newFila;
      })

      // Si tiene datos guardados
      // let reporte = this.reporte;
      // if(reporte.datos_dashboard!=null){
      //   let idxs = [];
      //   if(reporte.datos_dashboard.disponibilidad.length!=0){
      //     console.log('Aplicando cambios guardados Disponibilidad')
      //     let cambios_disponibilidad = reporte.datos_dashboard.disponibilidad;
      //     cambios_disponibilidad.forEach(fila => {
      //       fila['Fecha'] = dayjs(fila['Fecha']);
      //       newData[fila.idx] = {...fila};
      //       idxs.push(fila.idx);
      //     });

      //     this.agregarCambioDisponibilidad(idxs)
      //   }
      // }

      this.tablaDisponibilidadReal = newData;
    },


    crearTablaPR(){
      console.log('PR: Crear Tablas');
      let revisar_estado = this.planta.tipo == 'HUAWEI';
      let estados = this.estadoInversoresData;
      let irr = this.irradiationPmgdData;
      let disp = this.tablaDisponibilidad;
      let reporte = this.reporte;
      
      let eficiencia = this.planta.inversores.reduce((acc, curr) => {
        acc[curr.codigo] = curr.efic_area_total;
        return acc;
      }, {});

      let eficiencia_sum = this.planta.inversores.reduce((acc, curr) => {
        return acc + parseFloat(curr.efic_area_total);
      }, 0);

      if(disp.length==0){
        return;
      }

      let generalData = this.generationData.map((fila, idx) => {
        let newFila = {
          data: 'ok',
          comentario: '',
          Fecha: fila['Fecha'],
          idx: fila['idx'],
        };

        for (let key in fila) {
          if(key!='Fecha' && key!='Total' && key!='Global' && key!='idx'){
            let val_irr = irr[idx][key];
            let val_gen = fila[key];

            let estado_inv = revisar_estado ? estados[idx][key] : '';

            if(estado_inv=='On-grid: Power limit'){
              newFila[key] = 'power_limit';
            }
            else{
              if(disp[idx][key]=='operacion'){  
                if(val_gen>0 && val_irr>0){
                  newFila[key] = 'ok';
                }
                else if(val_gen>0 && val_irr<=0) {
                  newFila[key] = 'sin_g';
                }
                else if(val_gen<=0 && val_irr>0) {
                  newFila[key] = 'sin_r';
                }
                else {
                  newFila[key] = 'ok';
                }
              }
              else{
                newFila[key] = 'disp';
              }
            }
          }
        }

        return newFila;
      });

      if(reporte.datos_dashboard!=null){
        let idxs = [];
        if(reporte.datos_dashboard.pr.length!=0){
          console.log('Aplicando cambios guardados PR')
          let cambios_pr = reporte.datos_dashboard.pr;
          cambios_pr.forEach(fila => {
            fila['Fecha'] = dayjs(fila['Fecha']);
            generalData[fila.idx] = {...fila};
            idxs.push(fila.idx);
          });

          this.agregarCambioPR(idxs)
        }
      }

      this.dataPRGeneral = generalData;

      let newData = this.generationData.map((fila, idx)=>{
        let total = 0;
        let newFila = {
          Fecha: fila['Fecha'],
          idx: fila['idx'],
        };

        for (let key in fila) {
          if(key!='Fecha' && key!='Total' && key!='Global' && key!='idx'){
            let val_gen = fila[key];
            if(generalData[idx][key]=='ok' && generalData[idx]['data']=='ok'){  
              newFila[key] = val_gen;
              total += val_gen
            }
            else{
              newFila[key] = null;
            }
          }
        }

        newFila['Total'] = (total/1000);
        return newFila;
      })

      this.tablaGeneracionPR = newData;

      let newDataIrr = this.generationData.map((fila, idx)=>{
        let total = 0;
        let newFila = {
          Fecha: fila['Fecha'],
          idx: fila['idx']
        };

        for (let key in fila) {
          if(key!='Fecha' && key!='Total' && key!='Global' && key!='idx'){
            let val_irr = irr[idx][key];
            if(generalData[idx][key]=='ok' && generalData[idx]['data']=='ok'){  
              newFila[key] = val_irr;
              total += (parseFloat(eficiencia[key])*val_irr)
            }
            else{
              newFila[key] = null;
            }
          }
        }
        newFila['Total'] = (total/(eficiencia_sum));
        return newFila;
      });
      
      this.tablaIrradiacionPR = newDataIrr;

      this.calcularTotalFiltrado();
    },

    // updateTablaPR(){
    //   console.log('PR: Actualizar Tablas');
    //   let irr = this.irradiationData;
    //   let disp = this.tablaDisponibilidad;
      
    //   let eficiencia = this.planta.inversores.reduce((acc, curr) => {
    //     acc[curr.codigo] = curr.efic_area_total;
    //     return acc;
    //   }, {});

    //   let eficiencia_sum = this.planta.inversores.reduce((acc, curr) => {
    //     return acc + curr.efic_area_total;
    //   }, 0);



    //   if(disp.length==0){
    //     return;
    //   }

    //   let newData = this.generationData.map((fila, idx)=>{
    //     let total = 0;
    //     let newFila = {
    //       data: 'ok',
    //       Fecha: fila['Fecha'],
    //       idx: fila['idx'],
    //     };

    //     for (let key in fila) {
    //       if(key!='Fecha' && key!='Total' && key!='Global' && key!='idx'){
    //         let val_irr = irr[idx][key];
    //         let val_gen = fila[key];
    //         if(disp[idx][key]=='operacion'){  
    //           if(val_gen>0 && val_irr>0){
    //             newFila[key] = val_gen;
    //             total += val_gen
    //           }
    //           else {
    //             newFila[key] = 0;
    //           }
    //         }
    //         else{
    //           newFila[key] = null;
    //         }
    //       }
    //     }

    //     newFila['Total'] = (total/(1000));
    //     return newFila;
    //   });


    //   this.tablaGeneracionPR = newData;

    //   let newDataIrr = this.generationData.map((fila, idx)=>{
    //     let total = 0;
    //     let newFila = {
    //       Fecha: fila['Fecha'],
    //       idx: fila['idx']
    //     };

    //     for (let key in fila) {
    //       if(key!='Fecha' && key!='Total' && key!='Global' && key!='idx'){
    //         let val_irr = irr[idx][key];
    //         let val_gen = fila[key];
    //         if(disp[idx][key]=='operacion' && newData[idx]['data']=='ok'){  
    //           if(val_gen>0 && val_irr>0){
    //             newFila[key] = val_irr;
    //             total += (eficiencia[key]*val_irr)
    //           }
    //           else {
    //             newFila[key] = 0;
    //           }
    //         }
    //         else{
    //           newFila[key] = null;
    //         }
    //       }
    //     }

    //     newFila['Total'] = (total/(eficiencia_sum));
    //     return newFila;
    //   });
      
    //   this.tablaIrradiacionPR = newDataIrr;

    //   this.calcularTotalFiltrado();
    // },

    crearGraficoPR(){
      let pr_mensual = this.tablaExclusionesFiltradosResumen.length > 0 ? this.tablaExclusionesFiltradosResumen[0]['PR'] : 0;
      
      let prData = this.tablaExclusionesFiltrados.map((item) => {
        if(item['PR']==0){
          return pr_mensual;
        }
        return item['PR'];
      });

      let genData = this.tablaGeneralSinFiltrar.map((item) => {
        return item['Generacion'];
      });

      let labels = this.tablaExclusionesFiltrados.map((item, idx) => {
        return item['Fecha'].date();
      });

      // return;

      let newChart = this.generacionChart;
      newChart.chartData = {
        labels,
        datasets: [
          {
            label: 'Performance Ratio',
            type: 'line',
            yAxisID: 'B',
            fill: false,
            borderColor: '#ED7D31',
            borderWidth: 2,
            pointRadius: 3,
            pointBackgroundColor: '#ED7D31',
            // borderDash: [],
            // borderDashOffset: 0.0,
            data: prData,
          },
          {
            label: 'Generación Fotovoltaica (kWh)',
            fill: true,
            yAxisID: 'A',
            borderColor: '#4472C4',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: genData,
            backgroundColor: "#4472C4",
          },
        ]
      }
      this.generacionChart = newChart;

      this.generacionChartEn.chartData = {
        labels,
        datasets: [
          {
            label: 'Performance Ratio',
            type: 'line',
            yAxisID: 'B',
            fill: false,
            borderColor: '#ED7D31',
            borderWidth: 2,
            pointRadius: 3,
            pointBackgroundColor: '#ED7D31',
            data: prData,
          },
          {
            label: 'Energy Yield (kWh)',
            fill: true,
            yAxisID: 'A',
            borderColor: '#4472C4',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: genData,
            backgroundColor: "#4472C4",
          },
        ]
      }
    },

    calcularTotalFiltrado(){
      console.log('Recalcular datos PR');
      let generacion = this.tablaGeneracionPR.map(item =>{
        return {
          'Fecha': item.Fecha.format('YYYY-MM-DD HH:mm'),
          'Total': item.Total,
        }
      });

      let irradiacion = this.tablaIrradiacionPR.map(item =>{
        return {
          'Fecha': item.Fecha.format('YYYY-MM-DD HH:mm'),
          'Total': item.Total,
        }
      });

      let eficiencia = this.planta.inversores.reduce((acc, curr) => {
        return acc + parseFloat(curr.efic_area_total);
      }, 0);

      // this.notifyVue('Calculando PR (Filtrado)' ,'top', 'center', 'info');

      this.$http({
        method: 'POST',
        url: 'api/pr/resumen',
        data: {
          generacion,
          irradiacion,
          eficiencia,
        }
      })
      .then(res => {
        let data = res.data.map((item) => {
          item['Fecha'] = dayjs(item['Fecha']);
          return item
        })

        // this.notifyVue('PR calculado' ,'top', 'center', 'info');
        this.tablaExclusionesFiltrados = data;
        this.calcularDatosResumen();
        this.crearGraficoPR();
        this.calcularDatosPorInversor();
        this.calcularDatosPorInversorFiltrado();
        this.calcularDatosHistoricos();
      })
      .catch(error => {
        this.notifyVue('Error al calcular PR' ,'top', 'center', 'danger');
        console.log(error);
      })
    },

    disponibilidad_resumen(data, p_peak, tipo, sum_p_peak){
      let resumen = {};
      let factor = this.factor_comun;
      let dict_inicial = {Total: 0};
      let inversores = this.planta.inversores.map(inversor => {
        dict_inicial[inversor.codigo] = 0;
        return inversor.codigo;
      })

      data.forEach(item => {
        let fecha = item['Fecha'].startOf('day').format();
        if(fecha in resumen){
          resumen[fecha].push(item)
        } else{
          resumen[fecha] = [item]
        }
      });

      let finales = [];
      Object.keys(resumen).forEach(dia => {
        let res_dia = resumen[dia].reduce((acc, curr)=> {
          inversores.forEach(key => {
            if(curr[key]==tipo){
              acc[key] += factor;
              acc['Total'] += factor*p_peak[key];
            }
          })
          return acc;
        }, {...dict_inicial, Fecha: dayjs(dia)})

        finales.push(res_dia)
      })

      return finales.map(item => {
        item.Total = item.Total/sum_p_peak;
        return item;
      })
    },

    async calcularDisponibles(){
      console.log('Disponibles: calculando');

      let p_peak = this.planta.inversores.reduce((acc, curr) => {
        acc[curr.codigo] = curr.p_peak;
        return acc;
      }, {});

      let sum_p_peak = this.planta.inversores.reduce((acc, curr) => {
        return acc + parseFloat(curr.p_peak);
      }, 0);

      this.tablaDisponibilidadOperacion = this.disponibilidad_resumen(this.tablaDisponibilidad, p_peak, 'operacion', sum_p_peak);
      this.tablaDisponibilidadOut = this.disponibilidad_resumen(this.tablaDisponibilidad, p_peak, 'out', sum_p_peak);
      this.tablaDisponibilidadExclusion = this.disponibilidad_resumen(this.tablaDisponibilidad, p_peak, 'exclusion', sum_p_peak);

      this.calcularDisponibilidadOM();
      this.calcularDisponiBilidadTotal();
    },


    async calcularDisponiblesReales(){
      console.log('Disponibles Reales: calculando');

      let p_peak = this.planta.inversores.reduce((acc, curr) => {
        acc[curr.codigo] = curr.p_peak;
        return acc;
      }, {});

      let sum_p_peak = this.planta.inversores.reduce((acc, curr) => {
        return acc + parseFloat(curr.p_peak);
      }, 0);

      this.tablaDisponibilidadOperacionReal = this.disponibilidad_resumen(this.tablaDisponibilidadReal, p_peak, 'operacion', sum_p_peak);
      this.tablaDisponibilidadOutReal = this.disponibilidad_resumen(this.tablaDisponibilidadReal, p_peak, 'out', sum_p_peak);
      this.tablaDisponibilidadExclusionReal = this.disponibilidad_resumen(this.tablaDisponibilidadReal, p_peak, 'exclusion', sum_p_peak);
      
      // this.calcularDisponibilidadOM();
      this.calcularDisponibilidadReal();
      this.calcularDisponiBilidadTotalReal();
    },


    calcularDisponibilidadOM(){
      // this.notifyVue('Calculando Disponibilidad O&M' ,'top', 'center', 'info');
      this.$http({
        method: 'POST',
        url: 'api/disponibilidad/om',
        data: {
          operacion: this.tablaDisponibilidadOperacion,
          out: this.tablaDisponibilidadOut,
          exclusion: this.tablaDisponibilidadExclusion,
        }
      })
      .then(res => {
        let data = res.data.map(item => {
          item['Fecha'] = dayjs(item['Fecha']);
          return item
        })
        this.tablaDisponibilidadOM = data;
        // this.notifyVue('Disponibilidad O&M calculada' ,'top', 'center', 'success');
      })
      .catch(error => {
        this.notifyVue('Error al calcular Disponibilidad O&M' ,'top', 'center', 'danger');
        console.log(error);
      })
    },


    calcularDisponibilidadReal(){
      // this.notifyVue('Calculando Disponibilidad Real' ,'top', 'center', 'info');
      this.$http({
        method: 'POST',
        url: 'api/disponibilidad/real',
        data: {
          operacion: this.tablaDisponibilidadOperacionReal,
          out: this.tablaDisponibilidadOutReal,
          exclusion: this.tablaDisponibilidadExclusionReal,
        }
      })
      .then(res => {
        let data = res.data.map(item => {
          item['Fecha'] = dayjs(item['Fecha']);
          return item
        })
        this.tablaDisponibilidadReal = data;
        // this.notifyVue('Disponibilidad O&M calculada' ,'top', 'center', 'success');
      })
      .catch(error => {
        this.notifyVue('Error al calcular Disponibilidad Real' ,'top', 'center', 'danger');
        console.log(error);
      })
    },

    calcularDisponiBilidadTotal(){
      let operacion = this.tablaDisponibilidadOperacion;
      let out = this.tablaDisponibilidadOut;
      let exclusion = this.tablaDisponibilidadExclusion;

      let sum_disp = 0;

      this.tablaTiempoDisponibilidad = operacion.map((fila, idx) => {
        let total = fila['Total'] + out[idx]['Total'] + exclusion[idx]['Total'];
        sum_disp += total;
        // sum_disp += fila['Total'];
        return {
          Fecha: fila['Fecha'],
          operacion: fila['Total'],
          out: out[idx]['Total'],
          exclusion: exclusion[idx]['Total'],
          real: 100*fila['Total']/total,
          om: total > 0 ? 100*(fila['Total'] + exclusion[idx]['Total'])/total : 100
        }
      })

      let operacionDataset = this.tablaTiempoDisponibilidad.map((item) => {
        return item['operacion']
      });

      let dispDataset2 = this.tablaTiempoDisponibilidad.map((item) => {
        return item['om']
      });

      let dispRealDataset = this.tablaTiempoDisponibilidadReal.map((item) => {
        return item['real']
      });

      let dispLabels = this.tablaTiempoDisponibilidad.map((item, idx) => {
        return item['Fecha'].date();
      });

      this.disponibilidadChart = {
        extraOptions: chartConfigs.dispChartOptions,
        chartData: {
          labels: dispLabels,
          datasets: [
            {
              label: 'Disponibilidad O&M',
              type: 'line',
              fill: false,
              yAxisID: 'B',
              borderColor: '#ED7D31',
              borderWidth: 2,
              pointRadius: 3,
              pointBackgroundColor: '#ED7D31',
              // borderDash: [],
              // borderDashOffset: 0.0,
              data: dispDataset2,
            },
            {
              label: 'Disponibilidad Real',
              type: 'line',
              fill: false,
              yAxisID: 'B',
              borderColor: 'green',
              borderWidth: 4,
              pointRadius: 5,
              pointBackgroundColor: 'green',
              data: dispRealDataset,
            },
            {
              label: 'Horas de Operación',
              fill: true,
              yAxisID: 'A',
              borderColor: '#4472C4',
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: operacionDataset,
              backgroundColor: "#4472C4",
            }
          ]
        }
      };


      // this.sum_disp = dispDataset.reduce((a,b) => a+b, 0);
      this.sum_disp = sum_disp;
      this.avg_disp = sum_disp/operacionDataset.length;
    },

    calcularDisponiBilidadTotalReal(){
      let operacion = this.tablaDisponibilidadOperacionReal;
      let out = this.tablaDisponibilidadOutReal;
      let exclusion = this.tablaDisponibilidadExclusionReal;

      let sum_disp = 0;

      console.log('Calculando tabla de tiempos Reales')
      this.tablaTiempoDisponibilidadReal = operacion.map((fila, idx) => {
        let total = fila['Total'] + out[idx]['Total'] + exclusion[idx]['Total'];
        sum_disp += total;
        // sum_disp += fila['Total'];
        return {
          Fecha: fila['Fecha'],
          operacion: fila['Total'],
          out: out[idx]['Total'],
          exclusion: exclusion[idx]['Total'],
          real: 100*fila['Total']/total,
          om: 100*(fila['Total'] + exclusion[idx]['Total'])/total
        }
      })

      let operacionDataset = this.tablaTiempoDisponibilidadReal.map((item) => {
        return item['operacion']
      });

      let dispDataset2 = this.tablaTiempoDisponibilidad.map((item) => {
        return item['om']
      });

      let dispRealDataset = this.tablaTiempoDisponibilidadReal.map((item) => {
        return item['real']
      });

      let dispLabels = this.tablaTiempoDisponibilidad.map((item, idx) => {
        return item['Fecha'].date();
      });

      this.disponibilidadChart = {
        extraOptions: chartConfigs.dispChartOptions,
        chartData: {
          labels: dispLabels,
          datasets: [
            {
              label: 'Disponibilidad O&M',
              type: 'line',
              fill: false,
              yAxisID: 'B',
              borderColor: '#ED7D31',
              borderWidth: 2,
              pointRadius: 3,
              pointBackgroundColor: '#ED7D31',
              // borderDash: [],
              // borderDashOffset: 0.0,
              data: dispDataset2,
            },
            {
              label: 'Disponibilidad Real',
              type: 'line',
              fill: false,
              yAxisID: 'B',
              borderColor: 'green',
              borderWidth: 4,
              pointRadius: 5,
              pointBackgroundColor: 'green',
              data: dispRealDataset,
            },
            {
              label: 'Horas de Operación',
              fill: true,
              yAxisID: 'A',
              borderColor: '#4472C4',
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: operacionDataset,
              backgroundColor: "#4472C4",
            }
          ]
        }
      };


      this.disponibilidadChartEn = {
        extraOptions: chartConfigs.dispChartOptionsEn,
        chartData: {
          labels: dispLabels,
          datasets: [
            {
              label: 'O&M Availability',
              type: 'line',
              fill: false,
              yAxisID: 'B',
              borderColor: '#ED7D31',
              borderWidth: 2,
              pointRadius: 3,
              pointBackgroundColor: '#ED7D31',
              // borderDash: [],
              // borderDashOffset: 0.0,
              data: dispDataset2,
            },
            {
              label: 'Real Availability',
              type: 'line',
              fill: false,
              yAxisID: 'B',
              borderColor: 'green',
              borderWidth: 4,
              pointRadius: 5,
              pointBackgroundColor: 'green',
              data: dispRealDataset,
            },
            {
              label: 'Time above MIT',
              fill: true,
              yAxisID: 'A',
              borderColor: '#4472C4',
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: operacionDataset,
              backgroundColor: "#4472C4",
            }
          ]
        }
      };


      // this.sum_disp = sum_disp;
      // this.avg_disp = sum_disp/operacionDataset.length;
    },

    async tablaDisponibilidadCambiada(){
      // Generar Tabla de PR
      // this.crearTablaPR();
      // this.crearGraficoPR();
      
      // Generar grafico por dia
      
      // Calcular Disponibilidad
      await this.calcularDisponibles();
      await this.calcularResumenMensualInc();
      
      // Calcular PR
      // this.crearTablaGeneralSinFiltrar();
      // this.calcularDatosResumen();
      // this.calcularResumenDias();
    },

    calcularDatosResumen(){
      let ano_atras = this.fecha_inicio.subtract(1, 'year');
      let mes_actual = this.fecha_inicio;

      const factor_mes = this.n_dias/mes_actual.daysInMonth();

      let p_peak = this.planta.p_peak;
      let pvsol_mes_actual = this.pvsol_mes_actual;

      let eficiencia_sum = this.planta.inversores.reduce((acc, curr) => {
        return acc + parseFloat(curr.efic_area_total);
      }, 0);

      let suma = this.tablaGeneralSinFiltrar.reduce((acc, curr)=> {
        return {
          'Generacion': curr['Generacion'] + acc['Generacion'],
          'Radiacion': curr['Radiacion'] + acc['Radiacion'],
        }
      }, {
        'Generacion': 0,
        'Radiacion': 0,
      })


      let suma_filtrado = this.tablaExclusionesFiltrados.reduce((acc, curr)=> {
        return {
          'Generacion': curr['Generacion'] + acc['Generacion'],
          'Radiacion': curr['Radiacion'] + acc['Radiacion'],
        }
      }, {
        'Generacion': 0,
        'Radiacion': 0,
      })
      
      let suma_disp = this.tablaTiempoDisponibilidad.reduce((acc, curr)=> {
        return {
          'operacion': curr['operacion'] + acc['operacion'],
          'out': curr['out'] + acc['out'],
          'exclusion': curr['exclusion'] + acc['exclusion'],
        }
      }, {
        'out': 0,
        'operacion': 0,
        'exclusion': 0,
      });

      let suma_disp_real = this.tablaTiempoDisponibilidadReal.reduce((acc, curr)=> {
        return {
          'operacion': curr['operacion'] + acc['operacion'],
          'out': curr['out'] + acc['out'],
          'exclusion': curr['exclusion'] + acc['exclusion'],
        }
      }, {
        'out': 0,
        'operacion': 0,
        'exclusion': 0,
      })

      this.rendimiento_especifico = suma['Generacion'] / p_peak;
      this.irradiacion_total = suma['Radiacion'];
      this.performance_ratio = 100*suma_filtrado['Generacion']/ (suma_filtrado['Radiacion']*eficiencia_sum);
      this.performance_ratio_medido = 100*suma['Generacion']/ (suma['Radiacion']*eficiencia_sum);

      let horas_totales = suma_disp['operacion']+suma_disp['exclusion']+suma_disp['out'];
      let horas_totales_real = suma_disp_real['operacion']+suma_disp_real['exclusion']+suma_disp_real['out'];
      this.disponibilidad_om = 100*(suma_disp['operacion']+suma_disp['exclusion'])/(suma_disp['operacion']+suma_disp['exclusion']+suma_disp['out']);
      this.disponibilidad_real = 100*suma_disp_real['operacion']/(horas_totales_real);
      
      this.co2 = suma['Generacion']*0.36/1000

      suma_disp['om'] = this.disponibilidad_om;
      suma_disp_real['real'] = this.disponibilidad_real;

      suma['PR'] = this.performance_ratio_medido;
      suma_filtrado['PR'] = this.performance_ratio;

      // simulados vs diferencias
      this.generacion_simulada = pvsol_mes_actual.enerfv*factor_mes;
      this.generacion_diferencia = 100*(this.total_generacion - this.generacion_simulada)/this.generacion_simulada

      this.irradiacion_simulada = pvsol_mes_actual.globinc*factor_mes;
      this.irradiacion_diferencia = 100*(this.irradiacion_total - this.irradiacion_simulada)/this.irradiacion_simulada

      this.disponibilidad_garantizada = this.planta['disp_garantizado'];
      this.disponibilidad_diferencia = this.disponibilidad_om - this.disponibilidad_garantizada;

      this.performance_ratio_simulado = pvsol_mes_actual.pr;
      this.performance_ratio_diferencia = this.performance_ratio - this.performance_ratio_simulado;

      this.tablaTiempoDisponibilidadResumen = [suma_disp];
      this.tablaTiempoDisponibilidadRealResumen = [suma_disp_real];
      this.tablaGeneralSinFiltrarResumen = [suma];
      this.tablaExclusionesFiltradosResumen = [suma_filtrado];
    },

    obtenerDatosGraficosDias(mayor, menor, mediana){
      let dataset_mayor = [];
      let dataset_menor = [];
      let dataset_mediana = [];
      let labels = [];

      let mes_ano = this.generationData[0]['Fecha'].format('MMMM YYYY');
      let mes_ano_en = this.generationData[0]['Fecha'].locale('en').format('MMMM YYYY');
      let freq = this.factor_comun;

      this.generationData.map(item => {
        let actual = item['Fecha'].date();
        const hora = item['Fecha'].hour();
        if(actual == mayor){
          if(hora >= 6 && hora <= 20){
            dataset_mayor.push(item['Total']/(1000*freq));
            labels.push(item['Fecha'].format('YYYY-MM-DD HH:mm'));
          } 
        }
        else if(actual == menor){
          // dataset_menor.push(item['Total']/(1000*freq));

          if(hora >= 6 && hora <= 20){
            dataset_menor.push(item['Total']/(1000*freq));
          } 
        }
        else if(actual == mediana){
          // dataset_mediana.push(item['Total']/(1000*freq));

          if(hora >= 6 && hora <= 20){
            dataset_mediana.push(item['Total']/(1000*freq));
          } 
        }
      });


      let mayor_string = `Día de Mayor Producción (${mayor} ${mes_ano})`;
      let menor_string = `Día de Menor Producción (${menor} ${mes_ano})`
      let mediana_string = `Mediana (${mediana} ${mes_ano})`;

      let mayor_string_en = `Day of highest production (${mayor} ${mes_ano_en})`;
      let menor_string_en = `Day of lowest production (${menor} ${mes_ano_en})`;
      let mediana_string_en = `Median (${mediana} ${mes_ano_en})`;

      this.graficoDiasData = {
        labels: labels,
        datasets: [
          {
            label: mayor_string,
            yAxisID: 'A',
            type: 'line',
            // fill: true,
            fill: false,
            borderColor: '#4472C4',
            borderWidth: 2,
            pointRadius: 0,
            data: dataset_mayor,
          },
          {
            label: menor_string,
            yAxisID: 'A',
            type: 'line',
            fill: false,
            borderColor: '#ED7D31',
            borderWidth: 2,
            pointRadius: 0,
            data: dataset_menor,
          },
          {
            label: mediana_string,
            yAxisID: 'A',
            type: 'line',
            fill: false,
            borderColor: 'grey',
            borderWidth: 2,
            pointRadius: 0,
            data: dataset_mediana,
          },
        ]
      };

      this.graficoDiasDataEn = {
        labels: labels,
        datasets: [
          {
            label: mayor_string_en,
            yAxisID: 'A',
            type: 'line',
            // fill: true,
            fill: false,
            borderColor: '#4472C4',
            borderWidth: 2,
            pointRadius: 0,
            data: dataset_mayor,
          },
          {
            label: menor_string_en,
            yAxisID: 'A',
            type: 'line',
            fill: false,
            borderColor: '#ED7D31',
            borderWidth: 2,
            pointRadius: 0,
            data: dataset_menor,
          },
          {
            label: mediana_string_en,
            yAxisID: 'A',
            type: 'line',
            fill: false,
            borderColor: 'grey',
            borderWidth: 2,
            pointRadius: 0,
            data: dataset_mediana,
          },
        ]
      };
    },

    calcularResumenSmart(){
      let totales = this.smartData.reduce((acc, curr)=> {
        return {
          consumo: curr.consumo + acc.consumo,
          inyeccion: curr.inyeccion + acc.inyeccion,
        }
      }, {consumo: 0, inyeccion: 0});

      this.total_autoconsumo = totales.consumo/1000;
      this.total_inyeccion = totales.inyeccion/1000;
    },

    calcularResumenDias(){
      let n = this.tablaGeneralSinFiltrar.length;
      let n_mediana_idx = Math.floor(n/2);

      let tabla_temp = [...this.tablaGeneralSinFiltrar];

      let ordenado = tabla_temp.sort((a,b) => (a['Generacion'] > b['Generacion']) ? 1 : -1 )

      let total = this.tablaGeneralSinFiltrar.reduce((acc, curr)=> {
        return acc + curr['Generacion'];
      }, 0);

      this.mayor_valor = ordenado[n-1]['Generacion'];
      this.mayor_fecha = ordenado[n-1]['Fecha'];
      this.menor_valor = ordenado[0]['Generacion'];
      this.menor_fecha = ordenado[0]['Fecha'];
      this.mediana_valor = ordenado[n_mediana_idx]['Generacion'],
      this.mediana_fecha = ordenado[n_mediana_idx]['Fecha'],
      this.total_generacion = total;
      this.avg_generacion = total/n;

      let mayor = this.mayor_fecha.date();
      let menor = this.menor_fecha.date();
      let mediana = this.mediana_fecha.date();

      this.obtenerDatosGraficosDias(mayor, menor, mediana)
    },

    calcularDatosPorInversor(){
      let datos_inversores = this.planta.inversores.reduce((acc, curr) => {
        acc[curr.codigo] = {
          generacion: 0,
          rendimiento: 0,
          radiacion: 0,
          prod_max: 0,
          pr: 0,
          p_peak: curr.p_peak,
          inversor: curr.codigo,
          eficiencia: curr.efic_area_total,
        };
        return acc;
      }, {});

      let data_mensual = this.dataMensual.reduce((acc, curr) => {
        for(let key in acc){
          if(key!='Fecha'){
            acc[key]['generacion'] = acc[key]['generacion'] + curr[key];
            acc[key]['rendimiento'] = acc[key]['rendimiento'] + (1000*curr[key]/acc[key]['p_peak']);
          }
        }
        return acc;
      }, datos_inversores);

      let data_mensual2 = this.dataMensualIrr.reduce((acc, curr) => {
        for(let key in acc){
          if(key!='Fecha'){
            acc[key]['radiacion'] = acc[key]['radiacion'] + (1000*curr[key]);
          }
        }
        return acc;
      }, datos_inversores);

      this.datosInversorSF = Object.values(data_mensual2).map(item => {
        item.prod_max = item.eficiencia * item.radiacion;
        item.pr = 100*item.generacion / item.prod_max;
        return item;
      })
    },

    calcularDatosPorInversorFiltrado(){
      const usa_codigo_inversor = this.usa_codigo_inversor;
      let datos_inversores = this.planta.inversores.reduce((acc, curr) => {
        acc[curr.codigo] = {
          generacion: 0,
          rendimiento: 0,
          radiacion: 0,
          prod_max: 0,
          pr: 0,
          p_peak: curr.p_peak,
          inversor: curr.codigo,
          eficiencia: curr.efic_area_total,
        };
        
        if(!usa_codigo_inversor){
          acc[curr.codigo].nombre = curr.deviceName
        }

        return acc;
      }, {});

      let data_mensual = this.tablaGeneracionPR.reduce((acc, curr) => {
        for(let key in acc){
          if(key!='Fecha'){
            acc[key]['generacion'] = acc[key]['generacion'] + curr[key];
          }
        }
        return acc;
      }, datos_inversores);

      let data_mensual2 = this.tablaIrradiacionPR.reduce((acc, curr) => {
        for(let key in acc){
          if(key!='Fecha'){
            acc[key]['radiacion'] = acc[key]['radiacion'] + curr[key];
          }
        }
        return acc;
      }, data_mensual);

      this.datosInversorF = Object.values(data_mensual2).map(item => {
        item.generacion = item.generacion/1000;
        item.prod_max = item.eficiencia * item.radiacion;
        item.pr = 100*item.generacion / item.prod_max;
        return item;
      });
    },

    calcularDatosHistoricos(){
      let ano_atras = this.fecha_inicio.subtract(1, 'year');
      let mes_actual = this.fecha_inicio;

      const factor_mes = this.n_dias/mes_actual.daysInMonth();

      let pvsol = this.planta.pvsol_mensual;
      let pvsol_mes_actual = this.pvsol_mes_actual;
      // let config = this.planta.config;
      let coef_pr = this.planta['coef_pr_garantizado'];
      let disp_g = this.planta['disp_garantizado'];
      let contrato = this.planta['porc_contrato']/100;
      let factorDegradacion = this.factorDegradacion;

      // reemplazar datos del mes actual con datos calculados
      let irr_actual = this.irradiacion_total;
      let gen_actual = this.total_generacion;
      let pr_actual = this.performance_ratio;
      let pr_real = this.performance_ratio_medido;
      let disp_actual = this.disponibilidad_om;
      let dolar = this.reporte.dolar;

      let fecha_inicio = dayjs(this.planta.fecha_inicio);

      let totales_filtrados = this.datosInversorF.reduce((acc, curr) => {
        acc.prod_f += curr.generacion;
        acc.prod_max += curr.prod_max;
        return acc
      }, {prod_f: 0, prod_max: 0});

      // Buscar mes del reporte actual
      // y agregar mes si es que no existe
      if(!this.planta.historicos.some(item => item.ano == mes_actual.year() && item.mes == mes_actual.month()+1)){
        console.log('Agregar mes');
        let nuevo_mes = {
          Fecha: mes_actual,
          ano: mes_actual.year(),
          disponibilidad: 0,
          dolar: 0,
          exclusion: 0,
          mes: mes_actual.month()+1,
          operacion: 0,
          out: 0,
          pr: 0,
          pr_est: 0,
          pr_multa: 0,
          prod_dif: 0,
          prod_est: pvsol_mes_actual.enerfv*factor_mes, //pvsol
          prod_f: 0,
          prod_max: 0,
          prod_max_om: 0,
          prod_real: 0,
          rad_dif: 0,
          rad_est: pvsol_mes_actual.globinc*factor_mes, // pvsol
          rad_real: 0,
          degradacion: factorDegradacion*100
        };
        // array_historicos.push(nuevo_mes)
        this.planta.historicos.unshift(nuevo_mes);
      }
      
      // filtrar solamente datos desde el inicio del año/operacion hasta el mes actual
      let array_historicos = this.planta.historicos.filter(item => {
        // Si la planta comienza la operacion antes del año del reporte
        if(fecha_inicio.year()<mes_actual.year()){
          return (item.mes <= mes_actual.month()+1) && (item.ano ==mes_actual.year());
        }
        else if(fecha_inicio.year()==mes_actual.year()){
          return (item.mes <= mes_actual.month()+1) && (item.mes >= fecha_inicio.month()+1) && (item.ano == mes_actual.year());
        }
        else{
          return false;
        }


        // Si la planta comienza la operacion en el año del reporte

        // if((item.ano >= fecha_inicio.year()) && (item.ano == mes_actual.year())){
        //   return (item.mes <= mes_actual.month()+1) && (item.mes >= fecha_inicio.month()+1)
        // }
        
        // return false;
      });

      array_historicos.map(item => {
        // Si es el mes del reporte que se está haciendo
        if(item.ano == mes_actual.year() && item.mes == mes_actual.month()+1){
          item['dolar'] = dolar || 0;
          item['prod_real'] = gen_actual;
          item['rad_real'] = irr_actual;
          item['pr'] = pr_actual;
          item['disponibilidad'] = disp_actual;
          // item['prod_max'] = totales_filtrados.prod_max;
          item['prod_max'] = 100*gen_actual/pr_real;
          item['prod_f'] = totales_filtrados.prod_f;
          item['prod_max_om'] = totales_filtrados.prod_max;
          item['operacion'] = this.tablaTiempoDisponibilidadResumen[0]['operacion'];
          item['out'] = this.tablaTiempoDisponibilidadResumen[0]['out'];
          item['exclusion'] = this.tablaTiempoDisponibilidadResumen[0]['exclusion'];
          item['degradacion'] = 100*factorDegradacion;
          return item;
        }
        item['prod_max_om'] = 100*item['prod_f']/item['pr'];
        return item;
      })


      // datos hasta el mes actual
      let datos_historicos_actual = array_historicos.filter(item => {
        let fecha_temp = dayjs([item.ano, item.mes, 1]);

        if(fecha_temp.isBetween(ano_atras, mes_actual, null, '[]')){
          item['Fecha'] = fecha_temp;
          item['prod_dif'] = item['prod_est'] - item['prod_real']
          item['rad_dif'] = item['rad_est'] - item['rad_real']
          
          let pvsol_mes = pvsol.filter(item => item.mes == (fecha_temp.month()+1))[0]

          item['pr_est_multa'] = pvsol_mes['pr']*(coef_pr/100)
          item['pr_est'] = pvsol_mes['pr']

          let valor_multa_pr = (item['pr_est_multa'] - item['pr'])*item['prod_est']*item['dolar']*contrato;
          let valor_multa_disp = (disp_g - item['disponibilidad'])*item['prod_est']*item['dolar']*contrato;

          if(valor_multa_pr<0){
            item['pr_multa'] = 0;
          }
          else {
            item['pr_multa'] = valor_multa_pr;
          }

          if(valor_multa_disp<0){
            item['pr_multa'] = 0;
          }
          else {
            item['pr_multa'] = valor_multa_pr;
          }

          return true;
        }        
      });

      let datos_resumidos = {
        pr: 0,
        disp: 0,
        irr_sim: 0,
        irr_real: 0,
        prod_sim: 0,
        prod_real: 0,
        operacion: 0,
        out: 0,
        exclusion: 0,
        prod_max: 0,
        prod_max_om: 0,
        prod_f: 0,
        pr_sim: 0,
        n: 0,
      };

      let datos_finales = datos_historicos_actual.reduce((acc, curr) => {
        acc['prod_real'] += curr['prod_real'];
        acc['prod_sim'] += curr['prod_est'];
        acc['prod_max'] += curr['prod_max'];
        acc['prod_max_om'] += curr['prod_max_om'];
        acc['prod_f'] += curr['prod_f'];

        acc['irr_real'] += curr['rad_real'];
        acc['irr_sim'] += curr['rad_est'];

        acc['operacion'] += curr['operacion'];
        acc['out'] += curr['out'];
        acc['exclusion'] += curr['exclusion'];

        acc['pr_sim'] += curr['pr_est'];
        acc['n'] += 1;

        return acc;
      }, datos_resumidos)

      datos_finales['disp'] = 100*((datos_finales['operacion']+datos_finales['exclusion'])/(datos_finales['operacion'] + datos_finales['exclusion'] + datos_finales['out']));
      datos_finales['disp_sim'] = disp_g;
      datos_finales['diff_disp'] = datos_finales.disp - datos_finales.disp_sim;

      datos_finales['pr'] = 100*datos_finales['prod_f'] / datos_finales['prod_max_om'];
      datos_finales.pr_sim = datos_finales.pr_sim/datos_finales.n
      datos_finales['diff_pr'] = datos_finales.pr - datos_finales.pr_sim;

      datos_finales['diff_irr'] = 100*(datos_finales.irr_real - datos_finales.irr_sim)/datos_finales.irr_sim;
      datos_finales['diff_prod'] = 100*(datos_finales.prod_real - datos_finales.prod_sim)/datos_finales.prod_sim;

      this.datos_resumidos = datos_finales;

      this.generarGraficoHistoricoGeneracion(datos_historicos_actual);
      this.tablaHistoricoDolar = datos_historicos_actual;
      this.tablaHistoricoGen = datos_historicos_actual;
      this.tablaHistoricoIrr = datos_historicos_actual;
      this.tablaHistoricoPR = datos_historicos_actual;
      this.tablaHistoricoDisp = datos_historicos_actual;
    },

    generarGraficoHistoricoGeneracion(datos){
      let dataEsperada = [];
      let dataReal = [];
      let labels = [];
      const labels_en = [];
      let meses = MESES.es;
      const meses_en = MESES.en;

      datos = datos.sort((a,b) => (a.mes > b.mes) ? 1 : ((b.mes > a.mes) ? -1 : 0))

      datos.map(item => {
        dataEsperada = dataEsperada.concat(item.prod_est);
        dataReal = dataReal.concat(item.prod_real)
        let mes = meses[item.mes-1];
        const mes_en = meses_en[item.mes-1];
        labels = labels.concat(mes.charAt(0).toUpperCase() + mes.slice(1));
        labels_en.push(mes_en.charAt(0).toUpperCase() + mes_en.slice(1));
      })
      // let dataEsperada = [40000, 30000, 50000];
      // let dataReal = [41000, 32000, 48000];
      // let labels = ['Enero', 'Febrero', 'Marzo'];

      this.generacionHistoricaData = {
        labels: labels,
        datasets: [
          {
            label: "Generación Real (kWh)",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: '#4472C4',
            borderWidth: 2,
            pointRadius: 0,
            data: dataReal,
            backgroundColor: "#4472C4",
          },
          {
            label: "Generación Estimada (kWh)",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: '#ED7D31',
            borderWidth: 2,
            pointRadius: 0,
            data: dataEsperada,
            backgroundColor: "#ED7D31",
          },
        ]
      };

      this.generacionHistoricaDataEn = {
        labels: labels_en,
        datasets: [
          {
            label: "Real Energy Yield (kWh)",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: '#4472C4',
            borderWidth: 2,
            pointRadius: 0,
            data: dataReal,
            backgroundColor: "#4472C4",
          },
          {
            label: "Estimated Energy Yield (kWh)",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: '#ED7D31',
            borderWidth: 2,
            pointRadius: 0,
            data: dataEsperada,
            backgroundColor: "#ED7D31",
          },
        ]
      };
    },

    generarGraficoSmartData(){
      let resumen = {};
      let finales = [];
      let dict_inicial = {consumo: 0, inyeccion: 0};

      // agrupar los datos por dia
      this.smartData.forEach(item => {
        let fecha = dayjs(item['Fecha']).startOf('day').format();
        if(fecha in resumen){
          resumen[fecha].push(item)
        } else{
          resumen[fecha] = [item]
        }
      });

      Object.keys(resumen).forEach(dia => {
        let res_dia = resumen[dia].reduce((acc, curr)=> {
          acc.consumo = acc.consumo + curr.consumo;
          acc.inyeccion = acc.inyeccion + curr.inyeccion;

          return acc
        }, {...dict_inicial, Fecha: dayjs(dia)})

        res_dia.consumo = res_dia.consumo/1000;
        res_dia.inyeccion = res_dia.inyeccion/1000;

        finales.push(res_dia)
      })

      

      let consumoData = finales.map((item) => {
        return item['consumo'];
      });

      let inyeccionData = finales.map((item) => {
        return item['inyeccion'];
      });

      let labels = finales.map((item) => {
        return item['Fecha'].date();
      });


      this.autoconsumoGraficoData = {
        labels: labels,
        datasets: [
          {
            label: "Consumo Directo (kWh)",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: '#4472C4',
            borderWidth: 2,
            pointRadius: 0,
            data: consumoData,
            stack: 'GEN',
            backgroundColor: "#4472C4",
          },
          {
            label: "Inyección (kWh)",
            yAxisID: 'A',
            type: 'bar',
            fill: true,
            borderColor: '#ED7D31',
            borderWidth: 2,
            pointRadius: 0,
            data: inyeccionData,
            stack: 'GEN',
            backgroundColor: "#ED7D31",
          },
        ]
      }
    },

    crearColumnasTablas(){
      this.columns = [
        { prop: 'Fecha',
          name: 'Dia/Hora',
          sortable: true,
          pin: 'colPinStart',
          autoSize: true,
          cellTemplate: (createElement, props) => {
            return createElement('span', {
              // style: {
              //   color: 'red'
              // },
            }, props.model[props.prop].format('D HH:mm'));
          }
        },
        ...this.planta.inversores.map((inv) => {
          return {
            prop: inv.codigo,
            name: inv.codigo+' [Wh]',
            columnType: 'numeric',
            autoSize: true,

          }
        }),
        {
          prop: 'Total',
          name: 'Total [Wh]',
          sortable: true,
          columnType: 'numeric',
          autoSize: true,
          // pin: 'colPinEnd' 
        },
        {
          prop: '',
          name: ''
        }
      ];

      this.columnsMensual = [
        { prop: 'Fecha',
          name: 'Dia/Hora',
          sortable: true,
          pin: 'colPinStart',
          autoSize: true,
          cellTemplate: (createElement, props) => {
            return createElement('span', {
              // style: {
              //   color: 'red'
              // },
            }, props.model[props.prop].format('D'));
          }
        },
        ...this.planta.inversores.map((inv) => {
          return {
            prop: inv.codigo,
            name: inv.codigo,
            columnType: 'numeric',
            autoSize: true,

          }
        }),
        {
          prop: 'Total',
          name: 'Total',
          sortable: true,
          columnType: 'numeric',
          autoSize: true,
          // pin: 'colPinEnd' 
        },
      ];

      this.columnsIrradiacion = [
        { prop: 'Fecha',
          name: 'Dia/Hora',
          sortable: true,
          pin: 'colPinStart',
          cellTemplate: (createElement, props) => {
            return createElement('span', {
              // style: {
              //   color: 'red'
              // },
            }, props.model[props.prop].format('D HH:mm'));
          }
        },
        ...this.planta.inversores.map((inv) => {
          return {
            prop: inv.codigo,
            name: inv.codigo+' [kWh/m2]',
            columnType: 'numeric4',
          }
        }),
        {
          prop: 'Global',
          name: 'Global [W/m2]',
          columnType: 'numeric4',
          sortable: true
        },
        {
          prop: '',
          name: ''
        }
      ];

      this.columnsTemperatura = [
        { prop: 'Fecha',
          name: 'Dia/Hora',
          sortable: true,
          pin: 'colPinStart',
          cellTemplate: (createElement, props) => {
            return createElement('span', {
              // style: {
              //   color: 'red'
              // },
            }, props.model[props.prop].format('D HH:mm'));
          }
        },
        // ...this.planta.inversores.map((inv) => {
        //   return {
        //     prop: inv.codigo,
        //     name: inv.codigo+' [kWh/m2]',
        //     columnType: 'numeric4',
        //   }
        // }),
        {
          prop: 'temperatura',
          name: 'Temperatura [°C]',
          columnType: 'numeric4',
          sortable: true
        },
        {
          prop: '',
          name: ''
        }
      ];

      this.columnasDisponibilidad = [
        {
          prop: 'Fecha',
          name: 'Dia/Hora',
          sortable: true,
          pin: 'colPinStart',
          cellTemplate: (createElement, props) => {
            return createElement('span', {}, props.model[props.prop].format('D HH:mm'));
          }
        },
        {
          ...dropdown,
          prop: 'data',
          name: 'Data',
          columnType: 'select'
        },
        {
          prop: 'comentario',
          name: 'Comentario',
        },
        ...this.planta.inversores.map((inv) => {
          return {
            ...dropdown_inv,
            prop: inv.codigo,
            name: inv.codigo,
            columnType: 'select'
          }
        }),
        {
          prop: '',
          name: ''
        }
      ];

      this.columnasEstadoInversores = [
        {
          prop: 'Fecha',
          name: 'Dia/Hora',
          sortable: true,
          pin: 'colPinStart',
          cellTemplate: (createElement, props) => {
            return createElement('span', {}, props.model[props.prop].format('D HH:mm'));
          }
        },
        ...this.planta.inversores.map((inv) => {
          return {
            prop: inv.codigo,
            name: inv.codigo,
            columnType: 'text'
          }
        }),
        {
          prop: '',
          name: ''
        }
      ];

      this.columnasGeneralPR = [
        {
          prop: 'Fecha',
          name: 'Dia/Hora',
          sortable: true,
          pin: 'colPinStart',
          cellTemplate: (createElement, props) => {
            return createElement('span', {}, props.model[props.prop].format('D HH:mm'));
          }
        },
        {
          ...dropdown_pr,
          prop: 'data',
          name: 'Data',
          columnType: 'select'
        },
        {
          prop: 'comentario',
          name: 'Comentario'
        },
        ...this.planta.inversores.map((inv) => {
          return {
            ...dropdown_pr2,
            prop: inv.codigo,
            name: inv.codigo,
            columnType: 'select'
          }
        }),
        {
          prop: '',
          name: ''
        }
      ];

      this.columnasGeneracionPR = [
        {
          prop: 'Fecha',
          name: 'Dia/Hora',
          sortable: true,
          pin: 'colPinStart',
          cellTemplate: (createElement, props) => {
            return createElement('span', {}, props.model[props.prop].format('D HH:mm'));
          }
        },
        ...this.planta.inversores.map((inv) => {
          return {
            prop: inv.codigo,
            name: inv.codigo,
            // columnType: 'numeric'
          }
        }),
        {
          prop: 'Total',
          name: 'Total',
          columnType: 'numeric'
        }
      ];

      this.columnasIrradiacionPR = [
        {
          prop: 'Fecha',
          name: 'Dia/Hora',
          sortable: true,
          pin: 'colPinStart',
          cellTemplate: (createElement, props) => {
            return createElement('span', {}, props.model[props.prop].format('D HH:mm'));
          }
        },
        ...this.planta.inversores.map((inv) => {
          return {
            prop: inv.codigo,
            name: inv.codigo,
            columnType: 'numeric4'
          }
        }),
        {
          prop: 'Total',
          name: 'Total',
          columnType: 'numeric4'
        }
      ];

      this.columnasExclusionesFiltrados = [
        // {
        //   prop: 'Fecha',
        //   name: 'Dia',
        //   sortable: false,
        //   pin: 'colPinStart',
        //   cellTemplate: (createElement, props) => {
        //     return createElement('span', {}, props.model[props.prop].format('D'));
        //   }
        // },
        {
          prop: 'Generacion',
          name: 'Energía FV',
          columnType: 'numeric'
        },
        {
          prop: 'Radiacion',
          name: 'Radiacion_Inc',
          columnType: 'numeric'
        },
        {
          prop: 'PR',
          name: 'O&M PR',
          columnType: 'numeric2'
        }
      ]

      this.columnasGeneralSinFiltrar = [
        // {
        //   prop: 'Fecha',
        //   name: 'Dia',
        //   sortable: false,
        //   pin: 'colPinStart',
        //   cellTemplate: (createElement, props) => {
        //     return createElement('span', {}, props.model[props.prop].format('D'));
        //   }
        // },
        {
          prop: 'Generacion',
          name: 'Energía FV',
          columnType: 'numeric'
        },
        {
          prop: 'Radiacion',
          name: 'Radiacion_Inc',
          columnType: 'numeric'
        },
        {
          prop: 'PR',
          name: 'PR Real',
          columnType: 'numeric2'
        },
        // {
        //   prop: 'Rendimiento',
        //   name: 'Rendimiento kWh/kWp',
        //   columnType: 'numeric'
        // }
      ]

      let columnasDisponibilidadTemp = [
        { prop: 'Fecha',
          name: 'Dia',
          sortable: true,
          pin: 'colPinStart',
          cellTemplate: (createElement, props) => {
            return createElement('span', {
              // style: {
              //   color: 'red'
              // },
            }, props.model[props.prop].format('D'));
          }
        },
        ...this.planta.inversores.map((inv) => {
          return {
            prop: inv.codigo,
            name: inv.codigo,
            columnType: 'numeric',
          }
        }),
        {
          prop: 'Total',
          name: 'Total',
          columnType: 'numeric',
        }
      ];
      
      this.columnasDisponibilidadOperacion = columnasDisponibilidadTemp;
      this.columnasDisponibilidadOut = columnasDisponibilidadTemp;
      this.columnasDisponibilidadExclusion = columnasDisponibilidadTemp;

      this.columnasDisponibilidadOM = [
        {
          prop: 'Fecha',
          name: 'Dia',
          sortable: true,
          pin: 'colPinStart',
          cellTemplate: (createElement, props) => {
            return createElement('span', {}, props.model[props.prop].format('D'));
          }
        },
        ...this.planta.inversores.map((inv) => {
          return {
            prop: inv.codigo,
            name: inv.codigo,
            columnType: 'numeric',
          }
        }),
        {
          prop: 'Total',
          name: 'Total',
          columnType: 'numeric',
        }
      ];
    },

    agregarCambioDisponibilidad(idxs){
      this.cambios_disponibilidad = [...new Set(this.cambios_disponibilidad.concat(idxs))]
    },

    agregarCambioPR(idxs){
      this.cambios_pr = [...new Set(this.cambios_pr.concat(idxs))]
    },

    async guardarCambios(){
      let reporte = {...this.reporte}
      let disp = this.tablaDisponibilidad;
      let pr = this.dataPRGeneral;
      let cambios_disp = this.cambios_disponibilidad.map(idx => {
        return disp[idx];
      })

      let cambios_pr = this.cambios_pr.map(idx => {
        return pr[idx];
      })


      let payload = {
        disponibilidad: cambios_disp,
        pr: cambios_pr,
        mantenimiento: {
          correctivo: this.correctivo_lista,
          preventivo_ejecutado: this.preventivo_ejecutado_lista,
          preventivo_planificado: this.preventivo_planificado_lista,
        }
      }
      reporte.datos_dashboard = payload;

      try {
        await this.$http({
          method: 'PUT',
          url: 'api/plantas/'+this.planta.id+'/reportes/'+ this.reporte.id+'/dashboard',
          data: reporte
        });

        this.notifyVue('Cambios del Dashboard guardados' ,'top', 'center', 'success');

      } catch (error) {
        this.notifyVue('Hubo un problema al guardar los datos' ,'top', 'center', 'danger');
        console.log(error);
      }
    },

    async guardarReporte(guardar_cambios = False){
      if(guardar_cambios){
        this.notifyVue('Guardando Cambios' ,'top', 'center', 'info');
        await this.guardarCambios();
      }

      let quality = 1;
      let reporte = {...this.reporte};

      let fecha_creacion = dayjs();

      let grafico_gen_pr = this.$refs['gen-pr-chart'].$refs['canvas'].toDataURL('image/png', quality);
      let grafico_gen_pr_en = this.$refs['gen-pr-chart-en'].$refs['canvas'].toDataURL('image/png', quality);
      
      let grafico_dias = this.$refs['grafico-dias-line-chart'].$refs['canvas'].toDataURL('image/png', quality);
      let grafico_dias_en = this.$refs['grafico-dias-line-chart-en'].$refs['canvas'].toDataURL('image/png', quality);
      
      let grafico_disp = this.$refs['disp-chart'].$refs['canvas'].toDataURL('image/png', quality);
      let grafico_disp_en = this.$refs['disp-chart-en'].$refs['canvas'].toDataURL('image/png', quality);
      
      let grafico_historico = this.$refs['generacion-historica-bar-chart'].$refs['canvas'].toDataURL('image/png', quality);
      let grafico_historico_en = this.$refs['generacion-historica-bar-chart-en'].$refs['canvas'].toDataURL('image/png', quality);
      

      let n_inversores = this.planta.inversores.length;
      let obj_inv = {};
      let obj_inv_real = {};
      this.planta.inversores.forEach(inversor => obj_inv[inversor.codigo] = 0);
      this.planta.inversores.forEach(inversor => obj_inv_real[inversor.codigo] = 0);
      let tabla_rendimiento = [...this.datosInversorF]

      let n_dias = 0;

      this.tablaDisponibilidadReal.forEach(fila => {
        n_dias += 1;
        Object.keys(obj_inv_real).forEach(inv => {
          obj_inv_real[inv] += fila[inv];
        })
      })

      this.tablaDisponibilidadOM.forEach(fila => {
        Object.keys(obj_inv).forEach(inv => {
          obj_inv[inv] += fila[inv];
        })
      });


      Object.keys(obj_inv).forEach(inv => {
        obj_inv[inv] = obj_inv[inv]/n_dias;
      });

      Object.keys(obj_inv_real).forEach(inv => {
        obj_inv_real[inv] = obj_inv_real[inv]/n_dias;
      })

      this.datosInversorSF.forEach((fila, idx) => {
        tabla_rendimiento[idx]['generacion'] = fila.generacion;
        tabla_rendimiento[idx]['rendimiento'] = fila.rendimiento;
        // Disp O&M
        tabla_rendimiento[idx]['disponibilidad'] = obj_inv[fila.inversor];

        tabla_rendimiento[idx]['disponibilidad_real'] = obj_inv_real[fila.inversor];
        tabla_rendimiento[idx]['pr_real'] = fila.pr;
      })

      // let tabla_rendimiento_total = this.datosInversorF.reduce((acc, curr) => {
      //   return {
      //     generacion: acc.generacion + curr.generacion,
      //     // rendimiento: acc.rendimiento + curr.rendimiento,
      //     // pr: acc.pr + curr.pr,
      //     // disponibilidad: acc.disponibilidad + curr.disponibilidad,
      //   }
      // }, {generacion: 0, rendimiento: 0, pr: 0, disponibilidad: 0});

      let tabla_rendimiento_total = {
        'generacion': this.total_generacion,
        'rendimiento': this.rendimiento_especifico,
        'pr_real': this.performance_ratio_medido,
        'pr': this.performance_ratio,
        'disponibilidad_real': this.disponibilidad_real,
        'disponibilidad': this.disponibilidad_om,
      }
      
      // tabla_rendimiento_total.rendimiento = tabla_rendimiento_total.rendimiento/n_inversores;
      // tabla_rendimiento_total.pr = tabla_rendimiento_total.pr/n_inversores;
      // tabla_rendimiento_total.disponibilidad = tabla_rendimiento_total.disponibilidad/n_inversores;

      let historicos_resumidos = _.cloneDeep(this.datos_resumidos);
      delete historicos_resumidos['prod_max_om'];

      let payload = {
        fecha_reporte: this.fecha_inicio,
        fecha_creacion,
        tecnico: {
          rend_especifico: this.rendimiento_especifico,
          irradiacion_total: this.irradiacion_total,
          pr: this.performance_ratio,
          disponibilidad_om: this.disponibilidad_om,
          disponibilidad_real: this.disponibilidad_real,
          co2: this.co2,
          mayor_produccion_fecha: this.mayor_fecha,
          mayor_produccion_valor: this.mayor_valor,
          menor_produccion_fecha: this.menor_fecha,
          menor_produccion_valor: this.menor_valor,
          mediana_fecha: this.mediana_fecha,
          mediana_valor: this.mediana_valor,
          promedio_diario: this.avg_generacion,
          produccion_total: this.total_generacion,
          grafico_pr: grafico_gen_pr,
          grafico_pr_en: grafico_gen_pr_en,
          grafico_dias: grafico_dias,
          grafico_dias_en,
          // simulados y diferencias
          generacion_total: this.total_generacion,
          generacion_simulada: this.generacion_simulada,
          generacion_diferencia: this.generacion_diferencia,
          irradiacion_simulada: this.irradiacion_simulada,
          irradiacion_diferencia: this.irradiacion_diferencia,
          performance_ratio_simulado: this.performance_ratio_simulado,
          performance_ratio_diferencia: this.performance_ratio_diferencia,
          disponibilidad_garantizada: this.disponibilidad_garantizada,
          disponibilidad_diferencia: this.disponibilidad_diferencia,
          incluye_simulados: true
        },
        rendimiento: {
          tabla_rendimiento,
          tabla_rendimiento_total,
        },
        disponibilidad: {
          horas_totales: this.sum_disp,
          duracion_promedio: this.avg_disp,
          grafico_disp: grafico_disp,
          grafico_disp_en,
        },
        historico: {
          ...historicos_resumidos,
          grafico_historico,
          grafico_historico_en,
        },
        eventos_indisponibilidad: this.eventos_indisponibilidad,
        mantenimiento_correctivo: this.correctivo_lista,
        mantenimiento_ejecutado: this.preventivo_ejecutado_lista,
        mantenimiento_planificado: this.preventivo_planificado_lista,
        generacion: {
          tabla_generacion: this.dataMensual
        },
      };

      if(this.tiene_smart_meter){
        let datos_autoconsumo = {
          usar_boleta_autoconsumo: this.usar_boleta_autoconsumo,
        }

        if(this.usar_boleta_autoconsumo){
          datos_autoconsumo.consumo = this.boleta_autoconsumo;
          datos_autoconsumo.inyeccion = this.boleta_inyeccion;
          datos_autoconsumo.produccion = this.produccion_total;
        }
        else{
          datos_autoconsumo.consumo = this.total_autoconsumo;
          datos_autoconsumo.inyeccion = this.total_inyeccion;
          datos_autoconsumo.produccion = this.produccion_total;

          let grafico_autoconsumo = this.$refs['autoconsumo-bar-chart'].$refs['canvas'].toDataURL('image/png', quality);
          datos_autoconsumo.grafico_autoconsumo = grafico_autoconsumo
        }

        payload.smart_data = datos_autoconsumo;
      }

      reporte.datos_reporte = payload;

      // Guardar datos historicos
      let datos_historicos = _.cloneDeep(this.planta.historicos);
      let resumen_disponibilidad = this.tablaTiempoDisponibilidadResumen;
      
      let mes_actual = this.fecha_inicio;
      datos_historicos = datos_historicos.map(item => {
        if('prod_max_om' in item){
          delete item['prod_max_om'];
        };

        if(item.pr == null || isNaN(item.pr)){
          item.pr = 0;
        }

        if(item.pr_multa == null || isNaN(item.pr_multa)){
          item.pr_multa = 0;
        }

        if(item.ano == mes_actual.year() && item.mes == mes_actual.month()+1){
          item['out'] = resumen_disponibilidad[0]['out'];
          item['operacion'] = resumen_disponibilidad[0]['operacion'];
          item['exclusion'] = resumen_disponibilidad[0]['exclusion'];
          delete item['Fecha'];
          return item;
        }
        return item;
      });


      try {
        await this.$http({
          method: 'POST',
          url: 'api/plantas/'+this.planta.id+'/historicos',
          data: {
            historicos: datos_historicos
          }
        });

        this.notifyVue('Datos históricos actualizados' ,'top', 'center', 'success');

      } catch (error) {
        this.notifyVue('Hubo un problema al guardar los datos históricos' ,'top', 'center', 'danger');
        console.log(error);
      }

      //TODO: Guardar datos del dashboard

      // Guardar datos para el reporte
      try {
        await this.$http({
          method: 'PUT',
          url: 'api/plantas/'+this.planta.id+'/reportes/'+ this.reporte.id+'/pdf',
          data: reporte
        });

        this.notifyVue('Datos de Reporte guardados' ,'top', 'center', 'success');

      } catch (error) {
        this.notifyVue('Hubo un problema al guardar los datos' ,'top', 'center', 'danger');
        console.log(error);
      }
    },

    // Obtiene la degradacion a aplicar a los valores de PVSOL segun
    // la fecha de inicio de la planta y el actual
    degradarPvsol(factor_ano1, factor_lineal, mesesOperacion){
      // Si el año actual es mayor que el inicial
      if(mesesOperacion < 0){
        return 1;
      }
      
      // Si el año actual es igual al inicial
      if(mesesOperacion <= 12){
        console.log('PvSol Original');
        return (100-factor_ano1)/100;
      }
      else {
        console.log('PvSol Lineal');
        return ((1-(factor_ano1/100))*(1-(mesesOperacion-12)*(factor_lineal/1200)));
      }
    }
  },

  async mounted() {
    console.log('Mounted: iniciado');
    this.planta_id = this.$route.params.id;
    this.reporte_id = this.$route.params.id_reporte;

    try {
      let p0 = this.$store.dispatch('report/getPlantById', this.$route.params.id);
      await p0;

      this.usa_codigo_inversor = this.planta.usa_codigo_inversor;

      // Obtener factor comun
      this.notifyVue('Obteniendo factor común' ,'top', 'center', 'info');
      await this.getFactorComun();

      let p_mit = this.getMit();
      await p_mit;

      if(this.planta.historicos == null){
        this.planta.historicos = [];
      }

      // Comprobar datos necesarios de la planta
      if(this.planta.poa_pmgd==null){
        this.notifyVue('No existen datos POA ingresados' ,'top', 'center', 'danger');
        // return
      }

      this.planta.inversores.forEach(inversor => {
        this.planta.poa_pmgd[inversor.codigo] = parseFloat(this.planta.poa_pmgd[inversor.codigo]);
      })

      if(this.planta.pvsol_mensual == null || this.planta.pvsol_mensual.length==0){
        this.notifyVue('No hay datos de PVSOL ingresados' ,'top', 'center', 'danger');
        return
      }


      let get_reporte =  this.$store.dispatch('report/getReportById',{
        id_planta: this.planta_id,
        id_reporte:this.reporte_id
      });
      await get_reporte;

      // if(this.reporte.sensor_seleccionado==null){
      //   this.notifyVue('No se ha seleccionado un sensor de irrradiación' ,'top', 'center', 'danger');
      //   return;
      // }

      this.$store.dispatch('report/getFechasLimite');

      this.inversores_checkbox = this.planta.inversores.map(inv => {
        return {
          name: inv.codigo
        };
      });

      let mes_reporte = this.fecha_inicio.month() + 1;
      let dias_en_mes = this.fecha_inicio.daysInMonth();
      this.n_dias = this.fecha_fin.diff(this.fecha_inicio, 'day') + 1;

      let pvsol_actual = this.planta.pvsol_mensual.find(item => item.mes==mes_reporte);
      
      if(pvsol_actual==null){
        this.notifyVue('No hay datos PVSOL para el mes actual' ,'top', 'center', 'danger');
        return;
      }

      this.pvsol_mes_actual = {...pvsol_actual};

      let fecha_inicio_planta = dayjs(this.planta.fecha_inicio).date(1);
      this.mesesOperacion = this.fecha_inicio.diff(fecha_inicio_planta, 'month') + 1;


      // Obtener factor de degradacion
      console.log('Factor de Degradacion');
      let factor = this.degradarPvsol(
        this.planta.degradacion_primer_ano,
        this.planta.degradacion_lineal,
        this.mesesOperacion
      );
      this.factorDegradacion = factor;

      this.pvsol_mes_actual.enerfv = factor*pvsol_actual.enerfv;
      
      this.pvsol_esperado = pvsol_actual;
      this.pvsol_esperado_ndias = {
        enerfv: factor*this.n_dias*pvsol_actual.enerfv/dias_en_mes,
        globinc: this.n_dias*pvsol_actual.globinc/dias_en_mes,
        pr: pvsol_actual.pr,
      };
      
      this.planta.inversores.forEach(inv => {
        let eficiencia = parseFloat(inv.efic_area_total) * factor;
        inv.efic_area_total = eficiencia;
      });

      this.selectedInversores = this.planta.inversores.map(inv => inv.codigo);
      this.columnas = ['Fecha', ...this.planta.inversores.map((inv) => inv.codigo)]
      this.crearColumnasTablas();
      this.establecerColoresGraficos();


      // Pedir datos de generacion e irradiacion
      this.notifyVue('Obteniendo datos de generación y temperatura' ,'top', 'center', 'info');
      let p1 = this.getGenerationData();
      let p2 = await this.getTemperaturaData();
      
      await p1;
      await p2;

      if(this.planta.tipo == 'HUAWEI'){
        let pEstado = await this.getEstadoInversoresData();
        // await pEstado;
      }

      if(this.generationData.length == 0){
        this.notifyVue('Error: No hay datos de generación' ,'top', 'center', 'danger');
        return;
      }

      if(this.temperaturaData.length == 0){
        this.notifyVue('Error: No hay datos de temperatura' ,'top', 'center', 'danger');
        return;
      }

      this.notifyVue('Obteniendo datos de radiación' ,'top', 'center', 'info');
      await this.getIrradiationPmgdData();


      if(this.irradiationPmgdData.length == 0){
        this.notifyVue('Error: No hay datos de irradiación' ,'top', 'center', 'danger');
        return;
      }

      if(this.generationData.length != this.irradiationPmgdData.length){
        this.notifyVue('Advertencia: La cantidad de datos de generación e irradiación no coinciden' ,'top', 'center', 'danger');
      }

      this.notifyVue('Datos obtenidos' ,'top', 'center', 'success');

      this.calcularIrrBajoMit();
      this.calcularIrrRealBajoMit();

      // Calcular irradiacion INC
      let p3 = this.calcularResumenMensual();
      let p4 = this.calcularIrradiacionInc();
      let p5 = this.calcularResumenMensualIrr()

      await p3;
      await p4;
      await p5;

      // Generar Tabla de Disponibilidad
      // TODO: agregar datos guardados
      this.crearTablaDisponibilidad();
      this.crearTablaDisponibilidadReal();
      // Generar Tabla de PR
      this.crearTablaPR();
      this.crearGraficoPR();
      this.generarGraficoSmartData();
      
      // Generar grafico por dia
      
      // Calcular Disponibilidad
      await this.calcularDisponibles();
      await this.calcularDisponiblesReales();
      await this.calcularResumenMensualInc();
      
      // // Calcular PR
      this.crearTablaGeneralSinFiltrar();
      // // Calcular Datos historicos
      
      // // Calcular resumen mensual
      this.calcularDatosResumen();
      this.calcularResumenDias();
      this.calcularResumenSmart();
      this.calcularAutoconsumoBoleta();
      this.calcularDatosPorInversor();
      this.calcularDatosPorInversorFiltrado();
      this.calcularDatosHistoricos();

      // Cargar datos mantenimiento guardados
      if(this.reporte.datos_dashboard){
        if('mantenimiento' in this.reporte.datos_dashboard){
          if('correctivo' in this.reporte.datos_dashboard.mantenimiento){
            this.correctivo_lista = this.reporte.datos_dashboard.mantenimiento.correctivo;
          }

          if('preventivo_ejecutado' in this.reporte.datos_dashboard.mantenimiento){
            this.preventivo_ejecutado_lista = this.reporte.datos_dashboard.mantenimiento.preventivo_ejecutado;
          }
          
          if('preventivo_planificado' in this.reporte.datos_dashboard.mantenimiento){
            this.preventivo_planificado_lista = this.reporte.datos_dashboard.mantenimiento.preventivo_planificado;
          }
        }
      }

      await this.getBitacoras();
      this.sincronizarBitacorasConListadoEventos();

      console.log('Mounted: terminado');
      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  },

  destroyed() {
    this.$store.dispatch('report/resetDefaultState')
  }
}
</script>

<style lang="scss" scoped>
$graph-orange: #ED7D31;
$graph-blue: #4472C4;

revo-grid {
  height: 100%;
}


.revo-full {
  font-size: 12px;
}

.tablaGrid{
  border: solid 1px rgb(37, 24, 24);
  /* border-radius: 10px; */
  /* margin-bottom: 20px; */
  width: 100%;
  height: 60vh;
  margin: 3vh 0;
  z-index: 0;

  &-full{
    height: 92vh;
  }
}

.tablas-dobles {
  display: flex;
  justify-content: space-around;
}

.footer-wrapper {
  font-weight: bold;
}

.reporte-menu {
  display: flex;
  justify-content: space-around;
}

.chart-area {
  margin: 30px 0;
  border: solid 1px #60864c;
  padding: 30px;
  max-height: 700px;
  overflow-y: scroll;
}

.table {
  border: solid 1px #333;
}

.movement-chart {
  margin-top: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  > .movement-text {
    font-size: 20px;
    font-weight: bold;
  }
}

.autoconsumo-deshabilitado {
  background-color: #e3e3e3;
}
</style>