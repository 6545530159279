<template>
  <div v-if="reporte">
    <!-- Portada -->
    <reporte-cover
      :p_nom="planta.p_nom"
      :fecha_creacion="reporte.fecha_creacion"
      :fecha_reporte="reporte.fecha_reporte"
      :portafolio="planta.portafolio"
      :nombre_visible="planta.nombre_visible"
      :mail="planta.mail"
      :grupo_portafolio="planta.grupo_portafolio"
      :localI18n="localI18n"
    />

    <!-- Resumen Tecnico Pt1 -->
    <div v-if="reporte.tecnico.incluye_simulados" class="page">
      <div class="subpage">
        <reporte-header :codigo="codigo" :portafolio="planta.portafolio" />

        <div class="row section-title">
          <h3>{{seccion.tecnico}}. {{ localI18n.t('reporte.tecnico.titulo') }} 
          </h3>
        </div>

        <div class="row section-table">
          <table class="highlight centered report-borderless-table">
            <caption>{{ localI18n.t('reporte.tabla') }} {{seccion.tecnico}}.1 - {{ localI18n.t('reporte.tecnico.resumen_del_mes') }}</caption>
            <thead>
              <tr>
                <th></th>
                <th>{{ localI18n.t('reporte.medido') }}</th>
                <th>{{ localI18n.t('reporte.simulado') }}</th>
                <th>{{ localI18n.t('reporte.diferencia') }} %</th>
                <th>{{ localI18n.t('reporte.unidad') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ localI18n.t('reporte.tecnico.rendimiento_especifico') }}</td>
                <td>{{formatNumber(reporte.tecnico.rend_especifico, '0,0.0', localI18n.locale)}}</td>
                <td>--</td>
                <td>--</td>
                <td>[kWh/ kWp]</td>
              </tr>
              <tr>
                <td>{{ localI18n.t('reporte.tecnico.generacion_total') }}</td>
                <td>{{formatNumber(reporte.tecnico.generacion_total ,'0,0', localI18n.locale)}}</td>
                <td>{{formatNumber(reporte.tecnico.generacion_simulada ,'0,0', localI18n.locale)}}</td>
                <td>{{formatNumber(reporte.tecnico.generacion_diferencia ,'0.0', localI18n.locale)}}</td>
                <td>[kWh]</td>
              </tr>
              <tr>
                <td>{{ localI18n.t('reporte.tecnico.irradiacion_total') }}</td>
                <td>{{formatNumber(reporte.tecnico.irradiacion_total ,'0,0.0', localI18n.locale)}}</td>
                <td>{{formatNumber(reporte.tecnico.irradiacion_simulada ,'0,0.0', localI18n.locale)}}</td>
                <td>{{formatNumber(reporte.tecnico.irradiacion_diferencia ,'0.0', localI18n.locale)}}</td>
                <td>[kWh/ m²]</td>
              </tr>
              <tr>
                <td>{{ localI18n.t('reporte.tecnico.performance_ratio_om') }}</td>
                <td>{{formatNumber(reporte.tecnico.pr ,'0,0.0', localI18n.locale)}}</td>
                <td>{{formatNumber(reporte.tecnico.performance_ratio_simulado ,'0,0.0', localI18n.locale)}}</td>
                <td>{{formatNumber(reporte.tecnico.performance_ratio_diferencia ,'0,0.0', localI18n.locale)}}</td>
                <td>[%]</td>
              </tr>
              <tr>
                <td>{{ localI18n.t('reporte.tecnico.disponibilidad_om') }}</td>
                <td>{{formatNumber(reporte.tecnico.disponibilidad_om ,'0,0.0', localI18n.locale)}}</td>
                <td>{{formatNumber(reporte.tecnico.disponibilidad_garantizada ,'0,0.0', localI18n.locale)}}</td>
                <td>{{formatNumber(reporte.tecnico.disponibilidad_diferencia ,'0,0.0', localI18n.locale)}}</td>
                <td>[%]</td>
              </tr>
              <tr>
                <td>{{ localI18n.t('reporte.tecnico.disponibilidad_real') }}</td>
                <td>{{formatNumber(reporte.tecnico.disponibilidad_real ,'0,0.0', localI18n.locale)}}</td>
                <td>--</td>
                <td>--</td>
                <td>[%]</td>
              </tr>
              <tr>
                <td>{{ localI18n.t('reporte.tecnico.cantidad_co2') }}<span v-if="false" class="sub-number">2</span></td>
                <td>{{formatNumber(reporte.tecnico.co2 ,'0,0.0', localI18n.locale)}}</td>
                <td>--</td>
                <td>--</td>
                <td>[ton]</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row section-graph">
          <img :src="grafico_pr" alt="">
          <span>{{ localI18n.t('reporte.figura') }} {{seccion.tecnico}}.1 - {{ localI18n.t('reporte.tecnico.grafico_diario') }}</span>
        </div>

        <!-- Footer -->
        <reporte-footer
          :localI18n="localI18n"
          :fecha_reporte="reporte.fecha_reporte"
          :nombre_visible="planta.nombre_visible"
          :portafolio="planta.portafolio"
          :sigla="planta.sigla"
        />
      </div>
    </div>
    <div v-else class="page">
      <div class="subpage">
        <reporte-header :codigo="codigo" :portafolio="planta.portafolio" />

        <div class="row section-title">
          <h3>{{seccion.tecnico}}. {{ localI18n.t('reporte.tecnico.titulo') }} 
          </h3>
        </div>

        <div class="row section-table">
          <table class="highlight centered report-borderless-table">
            <caption>{{ localI18n.t('reporte.tabla') }} {{seccion.tecnico}}.1 - {{ localI18n.t('reporte.tecnico.resumen_del_mes') }}</caption>
            <tbody>
              <tr>
                <td>{{ localI18n.t('reporte.tecnico.rendimiento_especifico') }}</td>
                <td>{{formatNumber(reporte.tecnico.rend_especifico ,'0,0.00', localI18n.locale)}}</td>
                <td>[kWh/ kWp]</td>
              </tr>
              <tr>
                <td>{{ localI18n.t('reporte.tecnico.irradiacion_total') }}</td>
                <td>{{formatNumber(reporte.tecnico.irradiacion_total ,'0,0.00', localI18n.locale)}}</td>
                <td>[kWh/ m²]</td>
              </tr>
              <tr>
                <td>{{ localI18n.t('reporte.tecnico.performance_ratio_om') }}</td>
                <td>{{formatNumber(reporte.tecnico.pr ,'0,0.00', localI18n.locale)}}</td>
                <td>[%]</td>
              </tr>
              <tr>
                <td>{{ localI18n.t('reporte.tecnico.disponibilidad_om') }}</td>
                <td>{{formatNumber(reporte.tecnico.disponibilidad_om ,'0,0.00', localI18n.locale)}}</td>
                <td>[%]</td>
              </tr>
              <tr>
                <td>{{ localI18n.t('reporte.tecnico.disponibilidad_real') }}</td>
                <td>{{formatNumber(reporte.tecnico.disponibilidad_real ,'0,0.00', localI18n.locale)}}</td>
                <td>[%]</td>
              </tr>
              <tr>
                <td>{{ localI18n.t('reporte.tecnico.cantidad_co2') }}<span v-if="false" class="sub-number">2</span></td>
                <td>{{formatNumber(reporte.tecnico.co2 ,'0,0.00', localI18n.locale)}}</td>
                <td>[ton]</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row section-graph">
          <img :src="grafico_pr" alt="">
          <span>{{ localI18n.t('reporte.figura') }} {{seccion.tecnico}}.1 - {{ localI18n.t('reporte.tecnico.grafico_diario') }}</span>
        </div>

        <!-- Footer -->
        <reporte-footer
          :localI18n="localI18n"
          :fecha_reporte="reporte.fecha_reporte"
          :nombre_visible="planta.nombre_visible"
          :portafolio="planta.portafolio"
          :sigla="planta.sigla"
        />
      </div>
    </div>

    <!-- Resumen Tecnico Pt2 -->
    <div class="page">
      <div class="subpage">
        <reporte-header :codigo="codigo" :portafolio="planta.portafolio" />

        <div class="row section-table">
          <table class="highlight centered report-borderless-table-days">
            <caption>{{ localI18n.t('reporte.tabla') }} {{seccion.tecnico}}.2 - {{ localI18n.t('reporte.tecnico.resumen_dias') }}</caption>
            <thead>
              <tr>
                <th></th>
                <th>{{ localI18n.t('reporte.fecha') }}</th>
                <th>{{ localI18n.t('reporte.generacion') }} [kWh]</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ localI18n.t('reporte.tecnico.dia_mayor_produccion') }}</td>
                <td>{{formatDate(reporte.tecnico.mayor_produccion_fecha, 'D - MMMM', localI18n)}}</td>
                <td>{{formatNumber(reporte.tecnico.mayor_produccion_valor ,'0,0', localI18n.locale)}}</td>
              </tr>
              <tr>
                <td>{{ localI18n.t('reporte.tecnico.dia_menor_produccion') }}</td>
                <td>{{formatDate(reporte.tecnico.menor_produccion_fecha, 'D - MMMM', localI18n)}}</td>
                <td>{{formatNumber(reporte.tecnico.menor_produccion_valor ,'0,0', localI18n.locale)}}</td>
              </tr>
              <tr>
                <td>{{ localI18n.t('reporte.tecnico.mediana') }}</td>
                <td>{{formatDate(reporte.tecnico.mediana_fecha, 'D - MMMM', localI18n)}}</td>
                <td>{{formatNumber(reporte.tecnico.mediana_valor ,'0,0', localI18n.locale)}}</td>
              </tr>
              <tr>
                <td>{{ localI18n.t('reporte.tecnico.promedio_diario') }}</td>
                <td></td>
                <td>{{formatNumber(reporte.tecnico.promedio_diario ,'0,0', localI18n.locale)}}</td>
              </tr>
              <tr>
                <td>{{ localI18n.t('reporte.tecnico.produccion_total') }}</td>
                <td></td>
                <td>{{formatNumber(reporte.tecnico.produccion_total ,'0,0', localI18n.locale)}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      
        <div class="row section-graph">
          <img :src="grafico_dias" alt="">
          <span>{{ localI18n.t('reporte.figura') }} {{seccion.tecnico}}.2 - {{ localI18n.t('reporte.tecnico.curva_potencia') }}</span>
        </div>
        <!-- Footer -->
        <reporte-footer
          :localI18n="localI18n"
          :fecha_reporte="reporte.fecha_reporte"
          :nombre_visible="planta.nombre_visible"
          :portafolio="planta.portafolio"
          :sigla="planta.sigla"
        />
      </div>
    </div>

    <!-- Rendimiento -->
    <div 
      v-for="(tabla_rendimiento, idx) in tablas_rendimiento"
      :key="`rendimiento_${idx}`"
      class="page"
    >
      <div class="subpage">
        <reporte-header :codigo="codigo" :portafolio="planta.portafolio" />
    
        <div
          v-if="idx === 0" 
          class="row section-title"
        >
          <h3 v-if="planta.id === 300">{{seccion.rendimiento}}. {{ localI18n.t('reporte.rendimiento.titulo_chercan') }}</h3>
          <h3 v-else>{{seccion.rendimiento}}. {{ localI18n.t('reporte.rendimiento.titulo') }}</h3>
        </div>

        <div class="row section-table">
          <table class="highlight centered  text-center report-borderless-table-days">
            <caption>{{ localI18n.t('reporte.tabla') }} {{seccion.rendimiento}}.1 - {{ localI18n.t('reporte.rendimiento.resultados_por_inversor') }}</caption>
            <thead>
              <tr>
                <th></th>
                <th>{{ localI18n.t('reporte.produccion') }}</th>
                <th>{{ localI18n.t('reporte.rend') }}</th>
                <th
                  v-if="tienePrReal(reporte.rendimiento.tabla_rendimiento_total)"
                >
                  {{ localI18n.t('reporte.pr_real') }}
                </th>
                <th>{{ localI18n.t('reporte.pr_om') }}</th>
                <th
                  v-if="tieneDisponibilidadReal(reporte.rendimiento.tabla_rendimiento_total)"
                >
                  {{ localI18n.t('reporte.disp_real') }}
                </th>
                <th>{{ localI18n.t('reporte.disp_om') }}</th>
              </tr>
              <tr>
                <th></th>
                <th>[kWh]</th>
                <th>[kWh/kWp]</th>
                <th
                  v-if="tienePrReal(reporte.rendimiento.tabla_rendimiento_total)"
                >
                  [%]
                </th>
                <th>[%]</th>
                <th
                  v-if="tieneDisponibilidadReal(reporte.rendimiento.tabla_rendimiento_total)"
                >
                  [%]
                </th>
                <th>[%]</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="fila in tabla_rendimiento" :key="fila.inversor">
                <td v-if="fila.nombre">{{fila.nombre}}</td>
                <td v-else>{{fila.inversor}}</td>

                <td>{{formatNumber(fila.generacion ,'0,0', localI18n.locale)}}</td>
                <td>{{formatNumber(fila.rendimiento ,'0,0.00', localI18n.locale)}}</td>
                <td 
                  v-if="tienePrReal(reporte.rendimiento.tabla_rendimiento_total)"
                >
                  {{formatNumber(fila.pr_real ,'0,0.00', localI18n.locale)}}
                </td>
                <td>{{formatNumber(fila.pr ,'0,0.00', localI18n.locale)}}</td>
                <td
                  v-if="tieneDisponibilidadReal(reporte.rendimiento.tabla_rendimiento_total)"
                >
                  {{formatNumber(fila.disponibilidad_real ,'0,0.00', localI18n.locale)}}
                </td>
                <td>{{formatNumber(fila.disponibilidad ,'0,0.00', localI18n.locale)}}</td>
              </tr>
            </tbody>

            <template v-if="idx === tablas_rendimiento.length - 1">
              <tfoot>
                <tr>
                  <td>Total</td>
                  <td>{{formatNumber(reporte.rendimiento.tabla_rendimiento_total.generacion ,'0,0', localI18n.locale)}}</td>
                  <td>{{formatNumber(reporte.rendimiento.tabla_rendimiento_total.rendimiento ,'0,0.00', localI18n.locale)}}</td>
                  <td v-if="tienePrReal(reporte.rendimiento.tabla_rendimiento_total)">{{formatNumber(reporte.rendimiento.tabla_rendimiento_total.pr_real ,'0,0.00', localI18n.locale)}}</td>
                  <td>{{formatNumber(reporte.rendimiento.tabla_rendimiento_total.pr ,'0,0.00', localI18n.locale)}}</td>
                  <td v-if="tieneDisponibilidadReal(reporte.rendimiento.tabla_rendimiento_total)">{{formatNumber(reporte.rendimiento.tabla_rendimiento_total.disponibilidad_real ,'0,0.00', localI18n.locale)}}</td>
                  <td>{{formatNumber(reporte.rendimiento.tabla_rendimiento_total.disponibilidad ,'0,0.00', localI18n.locale)}}</td>
                </tr>
              </tfoot>
            </template>
          </table>
        </div>

        <!-- Footer -->
        <reporte-footer
          :localI18n="localI18n"
          :fecha_reporte="reporte.fecha_reporte"
          :nombre_visible="planta.nombre_visible"
          :portafolio="planta.portafolio"
          :sigla="planta.sigla"
        />
      </div>
    </div>


    <!-- Disponibilidad -->
    <div class="page">
      <div class="subpage">
        <reporte-header :codigo="codigo" :portafolio="planta.portafolio" />

        <div class="row section-title">
          <h3>{{seccion.disponibilidad}}. {{ localI18n.t('reporte.disponibilidad.titulo') }}</h3>
        </div>

        <div class="row section-list">
          <ul class="ul-avlt">
            <li>
              <span>{{ localI18n.t('reporte.disponibilidad.horas_totales') }}:</span>
              <span>{{formatNumber(reporte.disponibilidad.horas_totales ,'0,0.00', localI18n.locale)}} [h]</span>
            </li>
            <li>
              <span>{{ localI18n.t('reporte.disponibilidad.duracion_promedio') }}:</span>
              <span>{{formatNumber(reporte.disponibilidad.duracion_promedio ,'0,0.00', localI18n.locale)}} [h]</span>
            </li>
          </ul>
        </div>

        <div class="row section-graph">
          <img :src="grafico_disp" alt="">
          <span>
            {{ localI18n.t('reporte.figura') }} {{seccion.disponibilidad}}.1 -{{ localI18n.t('reporte.disponibilidad.disponibilidad_diaria') }}
          </span>
        </div>

        <!-- Footer -->
        <reporte-footer
          :localI18n="localI18n"
          :fecha_reporte="reporte.fecha_reporte"
          :nombre_visible="planta.nombre_visible"
          :portafolio="planta.portafolio"
          :sigla="planta.sigla"
        />
      </div>
    </div>

    <!-- Eventos de Indisponibilidad -->
    <div class="page">
      <div class="subpage">
        <reporte-header :codigo="codigo" :portafolio="planta.portafolio" />

        <div class="row section-title">
          <h3>{{seccion.indisponibilidad}}. {{ localI18n.t('reporte.indisponibilidad.titulo') }}</h3>
        </div>

        <div class="row section-table text-center">
          <table class="report-bordered-table">
            <thead>
              <tr>
                <th>N°</th>
                <th>{{ localI18n.t('reporte.indisponibilidad.tipo') }}</th>
                <th>{{ localI18n.t('reporte.indisponibilidad.descripcion') }}</th>
                <th>{{ localI18n.t('reporte.indisponibilidad.fecha') }}</th>
                <th>{{ localI18n.t('reporte.indisponibilidad.horas') }}</th>
              </tr>
            </thead>
            <tbody v-if="'eventos_indisponibilidad' in reporte && reporte.eventos_indisponibilidad.length > 0">
              <tr v-for="(dato, idx) in reporte.eventos_indisponibilidad" :key="`correctivo_${idx}`">
                <td>{{ dato.n_ot }}</td>
                <td>{{ dato.evento }}</td>
                <td>{{ dato.descripcion }}</td>
                <td class="td-fecha">{{ dato.fecha_incidencia }}</td>
                <td>{{formatNumber(dato.horas_indisponibilidad ,'0,0.00', localI18n.locale) }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Footer -->
        <reporte-footer
          :localI18n="localI18n"
          :fecha_reporte="reporte.fecha_reporte"
          :nombre_visible="planta.nombre_visible"
          :portafolio="planta.portafolio"
          :sigla="planta.sigla"
        />
      </div>
    </div>

    <!-- Autoconsumo -->
    <div class="page" v-if="'smart_data' in reporte">
      <div class="subpage">
        <reporte-header :codigo="codigo" :portafolio="planta.portafolio" />

        <div class="row section-title">
          <h3>{{seccion.autoconsumo}}. {{ localI18n.t('reporte.autoconsumo.titulo') }}</h3>
        </div>

        <div class="row section-table center">
          <table class="highlight centered text-center report-bordered-historic-table">
            <caption>{{ localI18n.t('reporte.tabla') }} {{seccion.autoconsumo}}.1 - {{ localI18n.t('reporte.autoconsumo.resumen_autoconsumo') }}</caption>
            <thead>
              <tr>
                <th>{{ localI18n.t('reporte.produccion') }}</th>
                <th>{{ localI18n.t('reporte.autoconsumo.consumido_directamente') }}</th>
                <th>{{ localI18n.t('reporte.autoconsumo.inyectado_a_la_red') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{formatNumber(reporte.tecnico.produccion_total ,'0,0.00', localI18n.locale)}}</td>
                <td>{{formatNumber(reporte.smart_data.consumo ,'0,0.00', localI18n.locale)}}</td>
                <td>{{formatNumber(reporte.smart_data.inyeccion ,'0,0.00', localI18n.locale)}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row section-graph" v-if="!reporte.smart_data.usar_boleta_autoconsumo">
          <img :src="grafico_autoconsumo" alt="Autoconsumo e Inyección Mensual">
          <span>{{ localI18n.t('reporte.figura') }} {{seccion.autoconsumo}}.1 - {{ localI18n.t('reporte.autoconsumo.flujo_de_la_energia') }}</span>
        </div>


        <!-- Footer -->
        <reporte-footer
          :localI18n="localI18n"
          :fecha_reporte="reporte.fecha_reporte"
          :nombre_visible="planta.nombre_visible"
          :portafolio="planta.portafolio"
          :sigla="planta.sigla"
        />
      </div>
    </div>

    <!-- Resumen historico -->
    <div class="page">
      <div class="subpage">
        <reporte-header :codigo="codigo" :portafolio="planta.portafolio" />

        <div class="row section-title">
          <h3>{{seccion.historico}}. {{ localI18n.t('reporte.historico.titulo') }}</h3>
        </div>

        <div class="row section-table center">
          <table v-if="reporte.historico.diff_disp" class="highlight centered text-center report-bordered-historic-table">
            <caption>{{ localI18n.t('reporte.tabla') }} {{seccion.historico}}.1 - {{ localI18n.t('reporte.historico.acumulada') }} {{formatDate(reporte.fecha_reporte, 'YYYY', localI18n)}}</caption>
            <thead>
              <tr>
                <th colspan="3" class="border-right-table">{{ localI18n.t('reporte.irradiacion') }}</th>
                <th colspan="3" class="border-right-table">{{ localI18n.t('reporte.generacion') }}</th>
                <th colspan="3" class="border-right-table">{{ localI18n.t('reporte.pr_om_full') }}</th>
                <th colspan="3">{{ localI18n.t('reporte.disp_om_full') }}</th>
              </tr>
              <tr>
                <th>{{ localI18n.t('reporte.simulada') }}</th>
                <th>{{ localI18n.t('reporte.medida') }}</th>
                <th class="border-right-table">{{ localI18n.t('reporte.dif') }}</th>

                <th>{{ localI18n.t('reporte.simulada') }}</th>
                <th>{{ localI18n.t('reporte.medida') }}</th>
                <th class="border-right-table">{{ localI18n.t('reporte.dif') }}</th>

                <th>{{ localI18n.t('reporte.simulado') }}</th>
                <th>{{ localI18n.t('reporte.medido') }}</th>
                <th class="border-right-table">{{ localI18n.t('reporte.dif') }}</th>

                <th>{{ localI18n.t('reporte.garantizada') }}</th>
                <th>{{ localI18n.t('reporte.medida') }}</th>
                <th>{{ localI18n.t('reporte.dif') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{formatNumber(reporte.historico.irr_sim ,'0,0', localI18n.locale)}}</td>
                <td>{{formatNumber(reporte.historico.irr_real ,'0,0', localI18n.locale)}}</td>
                <td class="border-right-table">{{formatNumber(reporte.historico.diff_irr ,'0,0.0', localI18n.locale)}}</td>

                <td>{{formatNumber(reporte.historico.prod_sim ,'0,0', localI18n.locale)}}</td>
                <td>{{formatNumber(reporte.historico.prod_real ,'0,0', localI18n.locale)}}</td>
                <td class="border-right-table">{{formatNumber(reporte.historico.diff_prod, '0,0.0', localI18n.locale)}}</td>

                <td>{{formatNumber(reporte.historico.pr_sim ,'0,0.0', localI18n.locale)}}</td>
                <td>{{formatNumber(reporte.historico.pr ,'0,0.0', localI18n.locale)}}</td>
                <td class="border-right-table">{{formatNumber(reporte.historico.diff_pr ,'0,0.0', localI18n.locale)}}</td>

                <td>{{formatNumber(reporte.historico.disp_sim ,'0,0.0', localI18n.locale)}}</td>
                <td>{{formatNumber( reporte.historico.disp,'0,0.0', localI18n.locale)}}</td>
                <td>{{formatNumber(reporte.historico.diff_disp ,'0,0.0', localI18n.locale)}}</td>
              </tr>
            </tbody>
          </table>
          <table v-else class="highlight centered text-center report-bordered-historic-table">
            <caption>{{ localI18n.t('reporte.tabla') }} {{seccion.historico}}.1 - {{ localI18n.t('reporte.historico.acumulada') }} {{formatDate(reporte.fecha_reporte, 'YYYY', localI18n)}}</caption>
            <thead>
              <tr>
                <th colspan="2" class="border-right-table">{{ localI18n.t('reporte.irradiacion') }}</th>
                <th colspan="2" class="border-right-table">{{ localI18n.t('reporte.generacion') }}</th>
                <th class="border-right-table">{{ localI18n.t('reporte.pr_om_full') }}</th>
                <th>{{ localI18n.t('reporte.disp_om_full') }}</th>
              </tr>
              <tr>
                <th>{{ localI18n.t('reporte.simulada') }}</th>
                <th class="border-right-table">{{ localI18n.t('reporte.medida') }}</th>
                <th>{{ localI18n.t('reporte.simulada') }}</th>
                <th class="border-right-table">{{ localI18n.t('reporte.medida') }}</th>
                <th class="border-right-table">[%]</th>
                <th>[%]</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{formatNumber(reporte.historico.irr_sim ,'0,0', localI18n.locale)}}</td>
                <td class="border-right-table">{{formatNumber(reporte.historico.irr_real ,'0,0', localI18n.locale)}}</td>
                <td>{{formatNumber(reporte.historico.prod_sim ,'0,0', localI18n.locale)}}</td>
                <td class="border-right-table">{{formatNumber(reporte.historico.prod_real ,'0,0', localI18n.locale)}}</td>
                <td class="border-right-table">{{formatNumber(reporte.historico.pr, '0,0.00', localI18n.locale)}}</td>
                <td>{{formatNumber(reporte.historico.disp, '0,0.00', localI18n.locale)}}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row section-graph">
          <img :src="grafico_historico" alt="">
          <span>{{ localI18n.t('reporte.figura') }} {{seccion.historico}}.1 - {{ localI18n.t('reporte.historico.generacion_historica') }} {{formatDate(reporte.fecha_reporte, 'YYYY', localI18n)}}</span>
        </div>

        <!-- Footer -->
        <reporte-footer
          :localI18n="localI18n"
          :fecha_reporte="reporte.fecha_reporte"
          :nombre_visible="planta.nombre_visible"
          :portafolio="planta.portafolio"
          :sigla="planta.sigla"
        />
      </div>
    </div>

    <div class="page" v-if="grafico_historico_rad">
      <div class="subpage">
        <reporte-header :codigo="codigo" :portafolio="planta.portafolio" />


        <div class="row section-graph">
          <img :src="grafico_historico_rad" alt="">
          <span>{{ localI18n.t('reporte.figura') }} {{seccion.historico}}.2 - {{ localI18n.t('reporte.historico.radiacion_historica') }} {{formatDate(reporte.fecha_reporte, 'YYYY', localI18n)}}</span>
        </div>


        <!-- Footer -->
        <reporte-footer
          :localI18n="localI18n"
          :fecha_reporte="reporte.fecha_reporte"
          :nombre_visible="planta.nombre_visible"
          :portafolio="planta.portafolio"
          :sigla="planta.sigla"
        />
      </div>
    </div>

    <!-- Mantenimiento -->
    <div class="page">
      <div class="subpage">
        <reporte-header :codigo="codigo" :portafolio="planta.portafolio" />

        <div class="row section-title">
          <h3>{{seccion.correctivo}}. {{ localI18n.t('reporte.mantenimiento.titulo') }} {{formatDate(reporte.fecha_reporte, 'MMMM YYYY', localI18n)}}</h3>
        </div>

        <div class="row section-table text-center">
          <table class="report-bordered-table">
            <thead>
              <tr>
                <th>N°</th>
                <th>{{ localI18n.t('reporte.mantenimiento.descripcion') }}</th>
                <th>{{ localI18n.t('reporte.mantenimiento.fecha_incidencia') }}</th>
                <th>{{ localI18n.t('reporte.mantenimiento.fecha_resolucion') }}</th>
                <th>{{ localI18n.t('reporte.mantenimiento.horas_incidencia') }}</th>
                <th>{{ localI18n.t('reporte.mantenimiento.comentarios') }}</th>
              </tr>
            </thead>
            <tbody 
              v-if="'mantenimiento_correctivo' in reporte && reporte.mantenimiento_correctivo.length > 0"
            >
              <tr v-for="dato in reporte.mantenimiento_correctivo" :key="'correctivo'+dato.n">
                <td>{{dato.n}}</td>
                <td>{{dato.descripcion}}</td>
                <td class="td-fecha">{{dato.fecha_incidencia}}</td>
                <td class="td-fecha">{{dato.fecha_resolucion}}</td>
                <td>{{dato.horas_indisponibilidad}}</td>
                <td>{{dato.observaciones}}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Footer -->
        <reporte-footer
          :localI18n="localI18n"
          :fecha_reporte="reporte.fecha_reporte"
          :nombre_visible="planta.nombre_visible"
          :portafolio="planta.portafolio"
          :sigla="planta.sigla"
        />
      </div>
    </div>

    <!-- Mantenimiento -->
    <div class="page">
      <div class="subpage">
        <reporte-header :codigo="codigo" :portafolio="planta.portafolio" />

        <div class="row section-title">
          <h3>{{seccion.preventivo}}. {{ localI18n.t('reporte.mantenimiento.titulo_preventivo') }}</h3>
        </div>

        <div class="row section-title">
          <h4>
            {{seccion.preventivo}}.1 {{ localI18n.t('reporte.mantenimiento.ejecutado') }} {{formatDate(reporte.fecha_reporte, 'MMMM YYYY', localI18n)}}
          </h4>
        </div>

        <div class="row section-table text-center">
          <table class="report-bordered-table">
            <thead>
              <tr>
                <th>N°</th>
                <th>{{ localI18n.t('reporte.mantenimiento.descripcion') }}</th>
                <th>{{ localI18n.t('reporte.mantenimiento.fecha_inicio') }}</th>
                <th>{{ localI18n.t('reporte.mantenimiento.fecha_termino') }}</th>
                <th>{{ localI18n.t('reporte.mantenimiento.estado') }}</th>
                <th>{{ localI18n.t('reporte.mantenimiento.comentarios') }}</th>
              </tr>
            </thead>
            <tbody v-if="'mantenimiento_ejecutado' in reporte && reporte.mantenimiento_ejecutado.length > 0">
              <tr v-for="dato in reporte.mantenimiento_ejecutado" :key="'ejecutado'+dato.n">
                <td>{{dato.n}}</td>
                <td>{{dato.descripcion}}</td>
                <td class="td-fecha">{{dato.fecha_inicio}}</td>
                <td class="td-fecha">{{dato.fecha_termino}}</td>
                <td>{{dato.estado}}</td>
                <td>{{dato.comentarios}}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Footer -->
        <reporte-footer
          :localI18n="localI18n"
          :fecha_reporte="reporte.fecha_reporte"
          :nombre_visible="planta.nombre_visible"
          :portafolio="planta.portafolio"
          :sigla="planta.sigla"
        />
      </div>
    </div>

    <div class="page">
      <div class="subpage">
        <reporte-header :codigo="codigo" :portafolio="planta.portafolio" />

        <div class="row section-title">
          <h4>
            {{seccion.preventivo}}.2 {{ localI18n.t('reporte.mantenimiento.planificado') }} {{formatDate(reporte.fecha_reporte.add(1, 'month'), 'MMMM YYYY', localI18n)}}
          </h4>
        </div>

        <div class="row section-table text-center">
          <table class="report-bordered-table">
            <thead>
              <tr>
                <th>N°</th>
                <th>{{ localI18n.t('reporte.mantenimiento.descripcion') }}</th>
                <th class="td-fecha">{{ localI18n.t('reporte.mantenimiento.fecha_inicio') }}</th>
                <th class="td-fecha">{{ localI18n.t('reporte.mantenimiento.fecha_termino') }}</th>
                <th>{{ localI18n.t('reporte.mantenimiento.estado') }}</th>
                <th>{{ localI18n.t('reporte.mantenimiento.comentarios') }}</th>
              </tr>
            </thead>
            <tbody v-if="'mantenimiento_planificado' in reporte && reporte.mantenimiento_planificado.length > 0">
              <tr v-for="dato in reporte.mantenimiento_planificado" :key="'planificado'+dato.n">
                <td>{{dato.n}}</td>
                <td>{{dato.descripcion}}</td>
                <td class="td-fecha">{{dato.fecha_inicio}}</td>
                <td class="td-fecha">{{dato.fecha_termino}}</td>
                <td>{{dato.estado}}</td>
                <td>{{dato.comentarios}}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
                <td>--</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Footer -->
        <reporte-footer
          :localI18n="localI18n"
          :fecha_reporte="reporte.fecha_reporte"
          :nombre_visible="planta.nombre_visible"
          :portafolio="planta.portafolio"
          :sigla="planta.sigla"
        />
      </div>
    </div>

    <!-- Anexo -->
    <div 
      v-for="(grupo_inversores, idx_grupo) in grupos_inversores_anexo" 
      :key="`anexo_${idx_grupo}`"
      class="page" 
    >
      <div class="subpage">
        <reporte-header :codigo="codigo" :portafolio="planta.portafolio" />

        <div
          v-if="idx_grupo === 0"
          class="row section-title"
        >
          <h3>{{ localI18n.t('reporte.anexo.titulo') }}</h3>
        </div>

        <div class="row section-table center">
          <table class="highlight text-center centered report-bordered-table appendix-table">
            <caption>
              <b>{{ localI18n.t('reporte.tabla') }} A.1:</b> {{ localI18n.t('reporte.generacion') }}
            </caption>
            <thead>
              <tr>
                <th>{{ localI18n.t('reporte.inversor') }}</th>
                <th v-for="inversor in grupo_inversores" :key="'th_'+inversor.codigo">
                  <template v-if="usa_codigo_inversor">
                    {{ inversor.codigo }}
                  </template>
                  <template v-else>
                    {{ inversor.deviceName }}
                  </template>
                </th>
              </tr>
              <tr>
                <th>
                  {{ localI18n.t('reporte.dia') }}
                </th>
                <th v-for="inversor in grupo_inversores" :key="'th_2_'+inversor.codigo">
                  kWh
                </th>
              </tr>
            </thead>
            <tbody v-if="tablas_anexo.length > 0">
              <tr v-for="dia in tablas_anexo[idx_grupo]" :key="`${idx_grupo}_${dia.dia}`">
                <td>{{formatDate(dia.Fecha, 'DD', localI18n) }}</td>
                <td v-for="inversor in grupo_inversores" :key="'td_'+inversor.codigo">
                  {{ formatNumber(dia[inversor.codigo], '0,0.0', localI18n.locale) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Footer -->
        <reporte-footer
          :localI18n="localI18n"
          :fecha_reporte="reporte.fecha_reporte"
          :nombre_visible="planta.nombre_visible"
          :portafolio="planta.portafolio"
          :sigla="planta.sigla"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
// import vueNumeralFilterInstaller from 'vue-numeral-filter';
import {VueMathjax} from 'vue-mathjax';
import ReporteCover from './ReporteCover.vue';
import ReporteHeader from './ReporteHeader.vue';
import ReporteFooter from './ReporteFooter.vue';
import { formatNumber } from '@/utils/functions';

// Vue.use(vueNumeralFilterInstaller, { locale: 'es-cl' });

import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
var customParseFormat = require('dayjs/plugin/customParseFormat')
var localeData = require('dayjs/plugin/localeData');
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(utc);
dayjs.extend(localeData);
require('dayjs/locale/es');
dayjs.locale('es');

// var backend_db_instance = axios.create({
//   baseURL: process.env.VUE_APP_BACKEND_DB_URL,
// });

export default {
  name: 'sin-reporte-financiero',
  components: {
    ReporteCover,
    ReporteHeader,
    ReporteFooter,
    'vue-mathjax': VueMathjax,
  },
  props: {
    localI18n: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      reporte: null,
      planta: null,
      usa_codigo_inversor: true,
      n_inversores: 1,
      codigo: '--',
      seccion: {
        financiero: 0,
        tecnico: 1,
        rendimiento: 2,
        disponibilidad: 3,
        indisponibilidad: 4,
        historico: 5,
        correctivo: 6,
        preventivo: 7,
        anexo: 'A',
      },
      portafolios : {
        'SINVEST1': 1,
        'DAGDA': 2,
        '4TC': 3,
        'ENLASA': 4,
        'IMELSA': 5,
        'SINVEST2': 6,
        'VANCOR': 7,
        'SINVEST3': 8,
        'ENELX': 9,
        'ECO': 10,
        'SOLCOR': 0,
        'SOM': 101,
        // PMGD
        'ERS': 'A',
        'BEE': 'B',
        'TRN': 'C',
        'ENL': 'D',
        'CRD': 'E',
        'SOMPMGD': 'Z',
      }
    }
  },
  computed: {
    // tablas de la seccion rendimiento separadas en grupos
    // de 25 filas
    tablas_rendimiento(){
      if(!this.reporte || !this.reporte.rendimiento){
        return [];
      }

      const tabla_rendimiento = this.reporte.rendimiento.tabla_rendimiento;
      const grupos = [];
      const group_size = 25;

      for(let i=0; i<tabla_rendimiento.length; i+=group_size){
        grupos.push(tabla_rendimiento.slice(i, i+group_size));
      }

      return grupos;
    },

    tabla_rendimiento_1(){
      const tabla_rendimiento = this.reporte.rendimiento.tabla_rendimiento;
      if(tabla_rendimiento.length > 26){
        return tabla_rendimiento.slice(0, 26)
      }

      return tabla_rendimiento;
    },

    tabla_rendimiento_2(){
      const tabla_rendimiento = this.reporte.rendimiento.tabla_rendimiento;
      if(tabla_rendimiento.length > 26){
        return tabla_rendimiento.slice(26)
      }

      return null
    },

    grupos_inversores_anexo(){
      if(!this.reporte || !this.reporte.generacion){
        return [];
      }

      const inversores = this.planta.inversores.map(inv => {
        return {
          codigo: inv.codigo,
          deviceName: inv.deviceName
        }
      });
      const n_inversores = this.planta.inversores.length;
      const group_size = 10;

      // separar inversores en grupos
      let grupos = [];
      for(let i=0; i<n_inversores; i+=group_size){
        grupos.push(inversores.slice(i, i+group_size));
      }

      return grupos;
    },

    // separar la tabla de anexo en varias partes si
    // la cantidad de inversores supera los 20
    tablas_anexo(){
      if(!this.reporte || !this.reporte.generacion){
        return [];
      }

      const tabla_generacion = this.reporte.generacion.tabla_generacion;
      const grupos = this.grupos_inversores_anexo;

      // por cada grupo de inversores, crear una tabla
      let tablas = [];
      for(let grupo of grupos){
        let tabla = tabla_generacion.map(dia => {
          
          let fila = {
            Fecha: dia.Fecha,
            dia: dia.Fecha.format('DD')
          };

          grupo.forEach(inv => {
            fila[inv.codigo] = dia[inv.codigo];
          });

          return fila;
        });

        tablas.push(tabla);
      }
      
      return tablas
    },

    // urls de imagenes
    grafico_pr(){
      const locale = this.localI18n.locale;

      if(locale === 'es'){
        return this.reporte.tecnico.grafico_pr;
      }
      else {
        return this.reporte.tecnico.grafico_pr_en || this.reporte.tecnico.grafico_pr;
      }
    },

    grafico_dias(){
      const locale = this.localI18n.locale;

      if(locale === 'es'){
        return this.reporte.tecnico.grafico_dias;
      }
      else {
        return this.reporte.tecnico.grafico_dias_en || this.reporte.tecnico.grafico_dias;
      }
    },

    grafico_disp(){
      const locale = this.localI18n.locale;

      if(locale === 'es'){
        return this.reporte.disponibilidad.grafico_disp;
      }
      else {
        return this.reporte.disponibilidad.grafico_disp_en || this.reporte.disponibilidad.grafico_disp;
      }
    },

    grafico_autoconsumo(){
      const locale = this.localI18n.locale;

      if(locale === 'es'){
        return this.reporte.smart_data.grafico_autoconsumo;
      }
      else {
        return this.reporte.smart_data.grafico_autoconsumo_en || this.reporte.smart_data.grafico_autoconsumo;
      }
    },

    grafico_historico(){
      const locale = this.localI18n.locale;

      if(locale === 'es'){
        return this.reporte.historico.grafico_historico;
      }
      else {
        return this.reporte.historico.grafico_historico_en || this.reporte.historico.grafico_historico;
      }
    },

    grafico_historico_rad(){
      const locale = this.localI18n.locale;

      if(locale === 'es'){
        return this.reporte.historico.grafico_historico_rad;
      }
      else {
        return this.reporte.historico.grafico_historico_rad_en || this.reporte.historico.grafico_historico_rad;
      }
    }


  },
  methods: {
    formatNumber,

    tienePrReal(item){
      return Object.keys(item).includes('pr_real')
    },

    tieneDisponibilidadReal(item){
      return Object.keys(item).includes('disponibilidad_real')
    },

    formatDate(date, formato, localI18n){
      return date.locale(localI18n.locale).format(formato);
    }
  },

  async mounted(){
    let id_planta = this.$route.params.id;
    let id_reporte = this.$route.params.id_reporte;
    let codigo = this.$route.params.codigo;

    try {
      let res_reporte;
      if(id_planta){
        let res = await this.$http({
          method: 'GET',
          url: '/api/plantas/'+id_planta+'/min'
        });

        this.planta = res.data;
        this.usa_codigo_inversor = this.planta.usa_codigo_inversor;

        res_reporte = await this.$http({
          method: 'GET',
          url: 'api/plantas/'+id_planta+'/reportes/'+id_reporte+'/pdf',
        });

        // this.codigo = res_reporte.data.codigo;
        this.reporte = res_reporte.data.datos_reporte;
      }
      else {
        let res = await this.$http({
          method: 'GET',
          url: 'api/reportes/'+codigo,
        });
        this.planta = res.data.planta;
        this.reporte = res.data.reporte.datos_reporte;
      }

      // Establecer numeros de secciones
      if('smart_data' in this.reporte){
        console.log('Tiene autoconsumo');
        this.seccion = {
          tecnico: 1,
          rendimiento: 2,
          disponibilidad: 3,
          indisponibilidad: 4,
          autoconsumo: 5,
          historico: 6,
          correctivo: 7,
          preventivo: 8,
          anexo: 'A',
        };
      }
      else {
        console.log('No tiene autoconsumo');
        this.seccion = {
          tecnico: 1,
          rendimiento: 2,
          disponibilidad: 3,
          indisponibilidad: 4,
          historico: 5,
          correctivo: 6,
          preventivo: 7,
          anexo: 'A',
        };
      }

      this.reporte.fecha_reporte = dayjs(this.reporte.fecha_reporte).add(12, 'hour');
      this.reporte.fecha_creacion = dayjs(this.reporte.fecha_creacion)

      // crear codigo de reporte
      let zeroPad = (num) => String(num).padStart(2, '0');
      let reporte_ano = this.reporte.fecha_reporte.year();
      let reporte_mes = this.reporte.fecha_reporte.month()+1;
      let n_portafolio = this.portafolios[this.planta.portafolio];
      let pos_portafolio = zeroPad(this.planta.pos_portafolio);

      this.codigo = n_portafolio+'-'+pos_portafolio+'-REP-GEN-'+reporte_ano+'.'+reporte_mes;

      this.reporte.tecnico.mayor_produccion_fecha = dayjs(this.reporte.tecnico.mayor_produccion_fecha);
      this.reporte.tecnico.menor_produccion_fecha = dayjs(this.reporte.tecnico.menor_produccion_fecha);
      this.reporte.tecnico.mediana_fecha = dayjs(this.reporte.tecnico.mediana_fecha);

      this.reporte.generacion.tabla_generacion = this.reporte.generacion.tabla_generacion.map(fila => {
        // console.log(fila.Fecha);
        fila.Fecha = dayjs(fila.Fecha).utc(false);
        return fila;
      })

      this.n_inversores = this.planta.inversores.length
      // this.notifyVue('Datos de Reporte obtenidos' ,'top', 'center', 'success');

    } catch (error) {
      // this.notifyVue('Hubo un problema al obtener los datos' ,'top', 'center', 'danger');
      console.log(error);
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Lusitana&display=swap');

body {
  // font-family: 'Lusitana', serif;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.page {
  font-family: 'Lusitana', serif;
  // font-family: Georgia, 'Times New Roman', Times, serif;
}

/**************/
/** Reporte **/
/************/

.cleaning-comments {
  position: absolute;
  bottom:60px
}

.cleaning-photos {
  border-bottom: 5px;
}

.cleaning-table-values {
  border-collapse: separate;
  width: 450px;
  margin: 0 auto;
  border-top: solid 1px #333;
  /* border-bottom: solid 1px #333; */
}

.cleaning-table-values tr:last-child td{
  border-top: solid 1px #333; 
  border-radius: 0px;
}

.cleaning-table-values td {
  padding: 15px 21px;
}

.cleaning-table {
  border: solid 1px #333;
  border-collapse: separate;
}

.cleaning-table td:first-child {
  border-right: solid 1px #333;
  border-radius: 0px;
}

.appendix-table {
  & tbody tr td {
    padding: 2px 4px;
    font-size: 11px;
  }

  &-horizontal {
    transform: rotate(270deg) scale(0.9);
    position: absolute;
    top: 195px;
    right:7px;
    font-size: 10px !important;
  }
}


.appendix-table.horizontal-table {
  transform: rotate(270deg);
  position: absolute;
  top: 300px;
  right:7px;
  font-size: 13px;
}

.appendix-table.horizontal-big-table {
  transform: rotate(270deg) scale(0.85);
  position: relative;
  font-size: 11px;
}

.appendix-table.horizontal-giant-table {
  transform: rotate(270deg) scale(0.7);
  position: relative;
  font-size: 9px;
}

.tb-15 {
  top: 80px;
  right: 70px;
}
.tb-16 {
  top: 50px;
  right: 90px;
}
.tb-17 {
  top: 50px;
  right: 110px;
}
.tb-18 {
  top: 50px;
  right: 130px;
}
.tb-19 {
  top: 50px;
  right: 150px;
}
.tb-20 {
  top: 50px;
  right: 170px;
}
.tb-21 {
  top: 50px;
  right: 190px;
}
.tb-22 {
  top: 50px;
  right: 210px;
}
.tb-23 {
  top: 50px;
  right: 230px;
}
.tb-24 {
  top: 50px;
  right: 250px;
}
.tb-25 {
  top: 70px;
  right: 320px;
}
.tb-26 {
  top: 60px;
  right: 340px;
  transform: rotate(270deg) scale(0.68) !important;
}
.tb-27 {
  top: 60px;
  right: 360px;
  transform: rotate(270deg) scale(0.66) !important;
}
.tb-28 {
  top: 60px;
  right: 380px;
  transform: rotate(270deg) scale(0.64) !important;
}
.tb-29 {
  top: 60px;
  right: 400px;
  transform: rotate(270deg) scale(0.62) !important;
}
.tb-30 {
  top: 60px;
  right: 420px;
  transform: rotate(270deg) scale(0.6) !important;
}


.appendix-table.horizontal-table tbody tr td {
  padding: 9px 5px;
  font-size: 13px;
}

#smart-container {
  width: 100%;
  height: 400px;
  position: relative;
  text-align: center;
}

#smart-container img {
  width: 100%;
  height: 100%;
}

#en-prod-text {
  position: absolute; 
  top: 49%;
  left: 33%;
  line-height: 15px;
  max-width: 70px;
}

#con-dir-text {
  position: absolute; 
  top: 14%;
  left: 61%;
  line-height: 15px;
  max-width: 70px;
}

#inj-gr-text {
  position: absolute; 
  top: 88%;
  left: 66%;
  line-height: 15px;
  max-width: 70px;
}

#en-prod-val {
  position: absolute; 
  top: 44%;
  left: 34%;
}

#con-dir-val {
  position: absolute; 
  top: 24%;
  left: 63%;
}

#inj-gr-val {
  position: absolute; 
  top: 77%;
  left: 68%;
}


div.row {
  margin-bottom: 20px;
  /* border: solid 1px blue; */
}

#cover-logo {
  text-align: right;
  margin-bottom: 50px;
}

.cover-contact-info {
  flex-direction: column;
}

#cover-info {
  margin-bottom: 75px;
}

.contact-name {
  font-weight: bold;
  font-size: 20px;
}

.section-header {
  margin-bottom: 1px;
  padding-bottom: 0.5cm;
  border-bottom: solid 1px;

  > div.left {
    font-size: 15px;
    font-weight: 350;
  }

  > hr {
    margin: 25px auto 1px auto;
    border: none;
    border-top: solid 1px #333;
    height: 1px;
    background-color: transparent;
  }
}


.cover-header {
  margin-bottom: 2cm !important;
  padding-bottom: 0.5cm;
  // border-bottom: solid 1px;

  > .left {
    font-size: 15px;
    font-weight: bolder;
  }

  > .right {
    > .cover-logo {
      height: 70px;
      position: absolute;
      top: 70px;
      right: 2cm;
      border-radius: 0;
    }
  }

  // > hr {
  //   margin: 25px auto 1px auto;
  //   border: none;
  //   border-top: solid 1px #333;
  //   height: 1px;
  //   background-color: transparent;
  // }
}


caption {
  margin-top: 10px;
  // caption-side: bottom;
  caption-side: top;
  text-align: center;
  font-size: 12px;
  font-style: italic;
  
  > b {
    font-size: 13px;
  }
}


.section-graph {
  margin-top: 30px;
  text-align: center;
  flex-direction: column;
  width: 100%;
  /* max-height: 300px; */
  /* overflow: hidden; */

  > img {
    // max-height: 270px;
    max-width: 650px;
    height: 325px ;
    /* margin-bottom: 15px; */
  }

  > span {
    // color: black;
    font-style: italic;
    margin-top: 0.5cm;
  }
}

// .section-graph > div > img {
//   max-height: 270px;
//   width: 100%;
//   /* margin-bottom: 15px; */
// }

.top-caption {
  caption-side: top;
}

/* .section-table {
  max-height: 250px;
} */

.sub-number {
  font-size: 80%;
}

.section-table > table {
  // line-height: 0px;
  font-size: 12px;
  width: 100%;
  color: black;

  tbody {
    font-family: Palatino,Palatino Linotype,Palatino LT STD,Book Antiqua,Georgia,serif; 
  }
}

.report-borderless-table {
  border-top: solid 1px black !important;
  border-bottom: solid 1px black;
  width: 100%;

  > thead {
    border-bottom: solid 1px black;
    text-align: center;
  }

  > tbody {
    > tr {
      font-size: 14px;
      line-height: 25px;

      &:not(:first-of-type){
        border-top: solid 1px #ccc;
      }

      > td {
        &:first-child {
          font-weight: bold;
        }

        &:not(:first-child) {
          text-align: center !important;
        }
      }
    }
  }
}

.report-borderless-table-days {
  border-top: solid 1px black;
  border-bottom: solid 1px black;
  width: 100%;

  > thead {
    // border-bottom: solid 1px black;
    text-align: center;
    font-size: 14px;
  }

  // > tbody > tr > td:first-child {
  //   font-weight: bold;
  // }

  // > tbody > tr > td:not(:first-child) {
  //   text-align: center !important;
  // }
  > tbody {
    font-size: 14px;

    > tr {
      border-top: 1px solid  #ccc;
      > td{
        line-height: 22px;

        &:first-child {
          font-weight: bold;
        }

        &:not(:first-child) {
          text-align: center !important;
        }
      }
    }
  }

  > tfoot > tr {
    line-height: 25px;
    font-size: 14px;
    font-weight: bold;
    border-top: solid 1px black;
  }
}

.report-inverter-table {
  // border-top: solid 1px black;
  // border-bottom: solid 1px black;
  width: 100%;

  > tbody {
    border-bottom: solid 1px black;
    border-top: solid 1px black;
  // border-bottom: solid 1px black;
    > tr > td:first-child {
      font-weight: bold;
      border-right: solid 1px black;
    }
  }

  > thead > tr > th:first-child {
    border-right: solid 1px black;
  }

  // > tbody > tr > td:first-child {
  //   font-weight: bold;
  //   border-right: solid 1px black;
  // }
  
  > tfoot > tr > td:first-child {
    font-weight: bold;
    border-right: solid 1px black;
  }
}


.report-bordered-historic-table {
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;

  // > tbody > tr > td {
  //   border: solid 1px black;
  // }

  > thead {
    border-bottom: solid 1px black;
  }

  .border-right-table {
    border-right: solid 1px black;
  }
}

.report-bordered-table {
  border-collapse: collapse;
  margin: 0 auto;
  
}

.report-bordered-table > tbody > tr > td, .report-bordered-table > thead > tr > th {
  border: solid 1px #333;
}


.table-footer {
  border-top: solid 2px #333;
  font-weight: bold;
}

.section-list {
  font-size: 14px;
}

.report-graph {
  height: 280px;
}

// .section-list > ul > li:nth-child(6) > ul > li {
//   padding: 2px 15px 2px 15px;
// }

// .section-list ul li, .section-list ul li ul li {
//   padding: 6px;
// }

.section-list > ul {
  width: 60%;
  // margin-right: 250px;
  padding-left: 0;

  > li {
    list-style-type: square;
    margin-left: 30px;
    display: flex;
    justify-content: space-between;

    > span:first-of-type {
      display: list-item;
    }
  }
}

.span-avlt {
  position: absolute;
  left: 400px;
}

/* .page:first-of-type {
  margin-top: 100px;
} */

.page {
  position: relative;
  width: 210mm;
  min-height: 297mm;
  padding: 0mm;
  margin: 10mm auto;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: #ccc;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */

}

.subpage {
  /* padding: 1cm; */
  padding: 1cm 2cm;
  /* border: 5px #ccc solid; */
  height: 297mm;
  background-color: white;
  /* outline: 2cm whitesmoke solid; */

  &.report-cover {
    padding-top: 2cm;

    &#cover-title {
      margin-bottom: 0.25cm;
    }

    &#cover-title-2 {
      margin-bottom: 1cm;
      font-weight: bolder;

      > h1 {
        line-height: 1.1cm;
      }
    }
  }
}

// .subpage.report-cover {
//   padding-top: 2cm;
//   padding-left: 2.5cm;
//   padding-right: 2.5cm;
// }

.row.section-title {
  margin-bottom: 10px;
}


.mjx-chtml.MJXc-display {
  font-size: 25px;
}

.mjx-sup {
  font-size: 80%;
}

.td-fecha {
  min-width: 60px;
}

@media print {
  @page {
    size: A4;
    margin: 0;
  }

  html, body {
    width: 210mm;
    height: 297mm;
  }

  /* body {
    background-color: rgb(166, 213, 252);
  } */

  header {
    display: none !important;
  }
  
  .no-print {
    display: none;
  }

  .sidebar {
    display: none !important;
  }

  .page:first-of-type {
    margin-top: 0;
  }

  .page {
    margin: 0;
    border: initial;
    // border: solid 1px red;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }

  .mjx-chtml.MJXc-display {
    display: inline;
  }

  .main-panel {
    border-top: 0;
  }

  footer.footer {
    display: none;
  }
}


</style>