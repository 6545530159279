import axios from 'axios';
var instance = axios.create();
delete instance.defaults.headers.common['Authorization'];

import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
dayjs.extend(utc);
require('dayjs/locale/es');
dayjs.locale('es');


var axios_backend = axios.create({
  baseURL: 'http://127.0.0.1:5000/',
});


const state = {
  plantas: [
    {
      id: 1,
      nombre: 'CCU Ovalle',
      PvSystemId: '151adf13-930f-42ad-b7c6-85fca02c5898',
      irr_default_id: '4d136508-c0a3-425d-bdc6-a431ca027f0b',
      reportes: [],
      config: {
        n_paneles: 1000,
        p_nom: 25000,
        p_peak: 335,
        sobredimension: 12,
        has_inverter: true,
      },
      inversores: [
        {
          codigo: 'A',
          modelo: 'Fronius Eco 25-03',
          n_paneles: 100,
          p_nom: 25000,
          p_peak: 33500,
          sobredimension: 12,
          efic_area_total: 32.626,
          strings: [],
          deviceId: "2ef00afa-92f6-4203-8093-ab6800f4da37",
          deviceName: "FRONIUS Eco 25.0-3-S (1)",
        },
        {
          codigo: 'B',
          modelo: 'Fronius Eco 25-03',
          n_paneles: 100,
          p_nom: 25000,
          p_peak: 33500,
          sobredimension: 12,
          efic_area_total: 32.626,
          strings: [],
          deviceId: "c03892ac-1ce7-4c18-b47d-ab6800f4da3c",
          deviceName: "FRONIUS Eco 25.0-3-S (2)",
        },
        {
          codigo: 'C',
          modelo: 'Fronius Eco 25-03',
          n_paneles: 100,
          p_nom: 25000,
          p_peak: 26800,
          sobredimension: 12,
          efic_area_total: 26.101,
          strings: [],
          deviceId: "a8eb2a9d-4bfe-469e-9e0d-ab6800f4da41",
          deviceName: "FRONIUS Eco 25.0-3-S (3)",
        },
        {
          codigo: 'D',
          modelo: 'Fronius Eco 25-03',
          n_paneles: 100,
          p_nom: 25000,
          p_peak: 26800,
          sobredimension: 12,
          efic_area_total: 26.101,
          strings: [],
          deviceId: "4904f530-23ea-4c88-8df2-ab6800f4da45",
          deviceName: "FRONIUS Eco 25.0-3-S (4)",
        },
        {
          codigo: 'E',
          modelo: 'Fronius Eco 25-03',
          n_paneles: 100,
          p_nom: 25000,
          p_peak: 26800,
          sobredimension: 12,
          efic_area_total: 26.101,
          strings: [],
          deviceId: "b4549b5f-577c-40b1-83da-ab6800f4da4a",
          deviceName: "FRONIUS Eco 25.0-3-S (5)",
        },
        {
          codigo: 'F',
          modelo: 'Fronius Eco 25-03',
          n_paneles: 100,
          p_nom: 25000,
          p_peak: 26800,
          sobredimension: 12,
          efic_area_total: 26.101,
          strings: [],
          deviceId: "cffc5576-940e-4e98-b008-ab6800f4da4f",
          deviceName: "FRONIUS Eco 25.0-3-S (6)",
        },
        {
          codigo: 'G',
          modelo: 'Fronius Eco 25-03',
          n_paneles: 100,
          p_nom: 25000,
          p_peak: 26800,
          sobredimension: 12,
          efic_area_total: 26.101,
          strings: [],
          deviceId: "49b1b09c-b46c-40b4-903c-ab6800f4da54",
          deviceName: "FRONIUS Eco 25.0-3-S (7)",
        },
        {
          codigo: 'H',
          modelo: 'Fronius Eco 25-03',
          n_paneles: 100,
          p_nom: 25000,
          p_peak: 26800,
          sobredimension: 12,
          efic_area_total: 26.101,
          strings: [],
          deviceId: "7d4a9583-7cfa-46c2-8faf-ab6800f4da54",
          deviceName: "FRONIUS Eco 25.0-3-S (8)",
        },
        {
          codigo: 'I',
          modelo: 'Fronius Eco 25-03',
          n_paneles: 100,
          p_nom: 25000,
          p_peak: 26800,
          sobredimension: 12,
          efic_area_total: 26.101,
          strings: [],
          deviceId: "c35d6f1c-bfc4-4cfc-8fdb-ab6800f4da58",
          deviceName: "FRONIUS Eco 25.0-3-S (9)",
        },
        {
          codigo: 'J',
          modelo: 'Fronius Eco 25-03',
          n_paneles: 100,
          p_nom: 25000,
          p_peak: 26800,
          sobredimension: 12,
          efic_area_total: 26.101,
          strings: [],
          deviceId: "67242316-37e5-4349-8de3-ab6800f4da5d",
          deviceName: "FRONIUS Eco 25.0-3-S (10)",
        },
        {
          codigo: 'K',
          modelo: 'Fronius Eco 25-03',
          n_paneles: 100,
          p_nom: 25000,
          p_peak: 26800,
          sobredimension: 12,
          efic_area_total: 26.101,
          strings: [],
          deviceId: "7b265783-7932-49be-8bea-ab6800f4da62",
          deviceName: "FRONIUS Eco 25.0-3-S (11)",
        },
        {
          codigo: 'L',
          modelo: 'Fronius Eco 25-03',
          n_paneles: 100,
          p_nom: 25000,
          p_peak: 26800,
          sobredimension: 12,
          efic_area_total: 26.101,
          strings: [],
          deviceId: "9241a311-2f99-4004-9cda-ab6800f4da66",
          deviceName: "FRONIUS Eco 25.0-3-S (12)",
        },
      ],
      poa: [
        {mes: 1, A: 1, B: 1, C: 1, D: 1, E: 1, F: 1, G: 1, H: 1, I: 1, J: 1, K: 1, L: 1},
        {mes: 2, A: 1, B: 1, C: 1, D: 1, E: 1, F: 1, G: 1, H: 1, I: 1, J: 1, K: 1, L: 1},
        {mes: 3, A: 1, B: 1, C: 1, D: 1, E: 1, F: 1, G: 1, H: 1, I: 1, J: 1, K: 1, L: 1},
        {mes: 4, A: 1, B: 1, C: 1, D: 1, E: 1, F: 1, G: 1, H: 1, I: 1, J: 1, K: 1, L: 1},
        {mes: 5, A: 1, B: 1, C: 1, D: 1, E: 1, F: 1, G: 1, H: 1, I: 1, J: 1, K: 1, L: 1},
        {mes: 6, A: 1, B: 1, C: 1, D: 1, E: 1, F: 1, G: 1, H: 1, I: 1, J: 1, K: 1, L: 1},
        {mes: 7, A: 1, B: 1, C: 1, D: 1, E: 1, F: 1, G: 1, H: 1, I: 1, J: 1, K: 1, L: 1},
        {mes: 8, A: 1, B: 1, C: 1, D: 1, E: 1, F: 1, G: 1, H: 1, I: 1, J: 1, K: 1, L: 1},
        {mes: 9, A: 1, B: 1, C: 1, D: 1, E: 1, F: 1, G: 1, H: 1, I: 1, J: 1, K: 1, L: 1},
        {mes: 10, A: 1, B: 1, C: 1, D: 1, E: 1, F: 1, G: 1, H: 1, I: 1, J: 1, K: 1, L: 1},
        {mes: 11, A: 1, B: 1, C: 1, D: 1, E: 1, F: 1, G: 1, H: 1, I: 1, J: 1, K: 1, L: 1},
        {mes: 12, A: 1, B: 1, C: 1, D: 1, E: 1, F: 1, G: 1, H: 1, I: 1, J: 1, K: 1, L: 1},
      ],
      historico: [
        {ano: 2020, mes: 10, dolar: 788.27, rad_est: 204, rad_real: 207, prod_est: 52645, prod_real: 53720, pr: 90, disponibilidad: 99},
        {ano: 2020, mes: 11, dolar: 762.88, rad_est: 204, rad_real: 207, prod_est: 52479, prod_real: 51476, pr: 90, disponibilidad: 99},
        {ano: 2020, mes: 12, dolar: 750, rad_est: 204, rad_real: 207, prod_est: 54924, prod_real: 53108, pr: 90, disponibilidad: 99},
        {ano: 2021, mes: 1, dolar: 750, rad_est: 204, rad_real: 207, prod_est: 54632, prod_real: 52700, pr: 90, disponibilidad: 99},
        {ano: 2021, mes: 2, dolar: 750, rad_est: 204, rad_real: 207, prod_est: 50121, prod_real: 44560, pr: 90, disponibilidad: 99},
        {ano: 2021, mes: 3, dolar: 750, rad_est: 204, rad_real: 207, prod_est: 53651, prod_real: 44310, pr: 90, disponibilidad: 99},
        {ano: 2021, mes: 4, dolar: 750, rad_est: 204, rad_real: 207, prod_est: 43483, prod_real: 36604, pr: 90, disponibilidad: 99},
        {ano: 2021, mes: 5, dolar: 750, rad_est: 204, rad_real: 207, prod_est: 36328, prod_real: 46143, pr: 90, disponibilidad: 99},
        {ano: 2021, mes: 6, dolar: 750, rad_est: 204, rad_real: 207, prod_est: 36328, prod_real: 46143, pr: 90, disponibilidad: 99},
        {ano: 2021, mes: 7, dolar: 750, rad_est: 204, rad_real: 207, prod_est: 36328, prod_real: 46143, pr: 90, disponibilidad: 99},
      ]
    },
  ],
  reportes: {
    1: [
      {id: 1, fecha: '2021-07-01'},
      {id: 2, fecha: '2021-06-01'},
      {id: 3, fecha: '2021-05-01'},
    ],
    2:[
      {id: 4, fecha: '2021-07-01'},
      {id: 5, fecha: '2021-06-01'},
      {id: 6, fecha: '2021-05-01'},
    ],
  },
  count: 0,
  swqapi_base_url: 'https://api.solarweb.com/swqapi/pvsystems/',
  deviceChannel: 'EnergyExported',
  generalChannel: 'EnergyProductionTotal',
  // Valores de la pagina actual
  planta: null,
  reportesActual: null,
  reporteActual: null,
  generationData: [],
  irradiationData: [],
  irradiationInc: [],
  irradiationMensualInc: [],
};

const mutations = {
  incrementM (state) {
    state.count++
  },

  SET_CURRENT_PLANT(state, planta){
    state.planta = planta;
  },

  SET_CURRENT_REPORTS(state, reportes){
    state.reportesActual = reportes;
  },

  SET_CURRENT_REPORT(state, reporte){
    state.reporteActual = reporte;
  },

  SET_GENERATION_DATA(state, payload){
    state.generationData = payload
  },

  SET_IRRADIATION_DATA(state, payload){
    state.irradiationData = payload
  },

  SET_IRRADIATION_INC(state, payload){
    state.irradiationInc = payload
  },

  SET_IRRADIATION_MENSUAL_INC(state, payload){
    state.irradiationMensualInc = payload
  }

};

const actions = {
  increment ({commit}) {
    commit('incrementM')
  },

  getPlantById({commit}, id){
    // setTimeout(function(){ alert("Hello"); }, 3000);
    commit('SET_CURRENT_PLANT', state.plantas[id-1])
  },

  getReportsByPlantId({commit}, id){
    commit('SET_CURRENT_REPORTS', state.reportes[id])
  },


  getReportById({commit}, id){
    let reporte = state.reportesActual.filter((item) => {
      if(item.id == id){
        return item;
      }
    })
    commit('SET_CURRENT_REPORT', reporte[0])
  },

  getGenerationData2({commit}, payload){
    var data = require('../data/generationData.json');
    console.log('Pidiendo datos de generacion');
    

    return setTimeout(() => {
      let genData = data.map((item) => {
        // for (let key in item) {
        //   if(key!='Fecha'){
        //     item[key] = item[key]/1000;
        //   }
        //   else{
        //     item['Fecha'] = dayjs(item['Fecha']);
        //   }
        // }
  
        item['Fecha'] = dayjs(item['Fecha']);
        return item;
      })
  
      console.log('Datos de generacion guardados');
      commit('SET_GENERATION_DATA', data);
    }, 5000);
    return;

    let PvSystemId = payload.PvSystemId;
    let inverters = payload.interters;
    let start = payload.start;
    let end = payload.end;
    let columnas = payload.columnas;

    // let url = process.env.VUE_APP_BACKEND_URL+'/generacion/'+PvSystemId;
    let url = 'http://127.0.0.1:5000/generacion';

    axios.get(url, {
      params: {
        fecha_inicio: start.format('YYYY-MM-DDTHH:mm:ss[Z]'),
        fecha_fin: end.format('YYYY-MM-DDTHH:mm:ss[Z]'),
        inversores: inverters,
        system_id: PvSystemId,
        columnas
      },
      headers: {}

    })
    .then(res => {
      let genData = res.data.map((item) => {
        item['Fecha'] = dayjs(item['Fecha']);
        return item
      });

      commit('SET_GENERATION_DATA', genData);

    })
    .catch(error => {
      console.log(error);
    });

  },

  getIrradiationData({commit}, payload){
    var data = require('../data/irradiationData.json');
    console.log('Pidiendo datos de irradiacion');

    return setTimeout(() => {
      let irrData = data.map((item) => {
        for (let key in item) {
          if(key!='Fecha'){
            item[key] = item[key]/10000;
          }
          else{
            item['Fecha'] = dayjs(item['Fecha']);
          }
        }
        // item['Fecha'] = dayjs(item['Fecha']);
        // return item
      })
  
      console.log('Datos de irradiacion guardados');
      commit('SET_IRRADIATION_DATA', data);
    }, 5000);
    
    return;

    let PvSystemId = payload.PvSystemId;
    let inversor = payload.inversor;
    let start = payload.start;
    let end = payload.end;
    let columnas = payload.columnas;

    // let url = process.env.VUE_APP_BACKEND_URL+'/generacion/'+PvSystemId;
    let url = 'http://127.0.0.1:5000/irradiacion/';

    instance.get(url, {
      params: {
        fecha_inicio: start.format('YYYY-MM-DDTHH:mm:ss[Z]'),
        fecha_fin: end.format('YYYY-MM-DDTHH:mm:ss[Z]'),
        inversor,
        system_id: PvSystemId,
        columnas
      },
      headers: {}

    })
    .then(res => {
      let genData = res.data.map((item) => {
        item['Fecha'] = dayjs(item['Fecha']);
        return item
      });

      commit('SET_IRRADIATION_DATA', genData);

    })
    .catch(error => {
      console.log(error);
    });

  },

  getIrradiationInc({commit}){
    let efic_total = state.planta.inversores.reduce((acc, curr) => {
      acc[curr.codigo] = curr.efic_area_total;
      return acc;
    }, {});

    axios_backend({
      method: 'POST',
      url: 'irradiacion/inc',
      data: {
        data: state.irradiationData,
        eficiencia: efic_total,
      }
    })
    .then(res => {
      let data = res.data;
      // let data = res.data.map((item) => {
      //   item['Fecha'] = dayjs(item['Fecha']);
      //   return item
      // });

      commit('SET_IRRADIATION_INC', data);

    })
    .catch(error => {
      console.log(error);
    });
  },

  // getIrradiationMensualInc({commit}){
  //   axios_backend({
  //     method: 'POST',
  //     url: 'irradiacion/resumen',
  //     data: {
  //       data: state.irradiationInc,
  //     }
  //   })
  //   .then(res => {
  //     let data = res.data;
  //     commit('SET_IRRADIATION_MENSUAL_INC', data);
  //   })
  //   .catch(error => {
  //     console.log(error);
  //   });
  // },
};

const getters = {
  // getPlantById: (state) => (id) => {
  //   return state.plantas.find(planta => planta.id === id)
  // }
  // getPlantById: () => {
  //   return 1;
  // }
};

const plants = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default plants;